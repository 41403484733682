import {graphql} from 'react-relay';

import {createMutation} from '@stackworx/relay';

import {
  UserChangePinMutation_MutationResponse,
  UserChangePinMutation_MutationVariables,
} from './__generated__/UserChangePinMutation_Mutation.graphql';

const mutation = graphql`
  mutation UserChangePinMutation_Mutation($input: UserChangePinInput!) {
    userChangePin(input: $input) {
      clientMutationId
    }
  }
`;

export default createMutation<
  UserChangePinMutation_MutationVariables,
  UserChangePinMutation_MutationResponse
>(mutation);

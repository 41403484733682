/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type WeightTransactionGoldPercentageEditInput = {
    readonly id: string;
    readonly goldPercentage?: number | null;
    readonly clientMutationId?: string | null;
};
export type WeightTransactionEditGoldPercentageMutationVariables = {
    readonly input: WeightTransactionGoldPercentageEditInput;
};
export type WeightTransactionEditGoldPercentageMutationResponse = {
    readonly weightTransactionEditGoldPercentage: ({
        readonly clientMutationId: string | null;
        readonly id: string;
        readonly goldPercentage: number | null;
    }) | null;
};
export type WeightTransactionEditGoldPercentageMutation = {
    readonly response: WeightTransactionEditGoldPercentageMutationResponse;
    readonly variables: WeightTransactionEditGoldPercentageMutationVariables;
};



/*
mutation WeightTransactionEditGoldPercentageMutation(
  $input: WeightTransactionGoldPercentageEditInput!
) {
  weightTransactionEditGoldPercentage(input: $input) {
    clientMutationId
    id
    goldPercentage
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "WeightTransactionGoldPercentageEditInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "weightTransactionEditGoldPercentage",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "WeightTransactionGoldPercentageEditInput!"
      }
    ],
    "concreteType": "WeightTransactionGoldPercentageEditPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "goldPercentage",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "WeightTransactionEditGoldPercentageMutation",
  "id": null,
  "text": "mutation WeightTransactionEditGoldPercentageMutation(\n  $input: WeightTransactionGoldPercentageEditInput!\n) {\n  weightTransactionEditGoldPercentage(input: $input) {\n    clientMutationId\n    id\n    goldPercentage\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "WeightTransactionEditGoldPercentageMutation",
    "type": "Mutations",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "WeightTransactionEditGoldPercentageMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
(node as any).hash = '22228c96515f5544681d0236198c6e49';
export default node;

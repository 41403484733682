import {graphql} from 'react-relay';

import {createMutation} from '@stackworx/relay';

import {
  UserDeactivateMutation_MutationResponse,
  UserDeactivateMutation_MutationVariables,
} from './__generated__/UserDeactivateMutation_Mutation.graphql';

const mutation = graphql`
  mutation UserDeactivateMutation_Mutation($input: UserDeactivateInput!) {
    userDeactivate(input: $input) {
      clientMutationId
    }
  }
`;

export default createMutation<
  UserDeactivateMutation_MutationVariables,
  UserDeactivateMutation_MutationResponse
>(mutation);

import React from 'react';
import {withStyles, WithStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {Formik, Form} from 'formik';
import Typography from '@material-ui/core/Typography';
import styles from './ModalStyles';
import * as Yup from 'yup';
import moment from 'moment';
import Spinner from 'react-spinkit';

import {EnvironmentProp} from '../../RelayRenderer';
import CompanyDeactivateMutation from './mutations/CompanyDeactivateMutation';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

interface Props extends WithStyles<typeof styles>, EnvironmentProp {
  supplierId?: string;
  supplierName?: string;
  onClose: () => void;
  refetch?: () => void;
}

const Clicked = (e: React.MouseEvent<HTMLElement>) => {
  e.stopPropagation();
};

const schema = Yup.object().shape({});

function DeclineTradeModal(props: Props) {
  const {classes} = props;
  return (
    <div
      className={classes.root}
      onClick={(e: React.MouseEvent<HTMLElement>) => Clicked(e)}
    >
      <Typography variant="h6" className={classes.title}>
        Delete Supplier
        <span
          className={classes.exit}
          onClick={() => props.onClose && props.onClose()}
        >
          +
        </span>
      </Typography>
      <Formik
        onSubmit={async (_values, {setSubmitting, setStatus}) => {
          setSubmitting(true);
          const now = moment().toISOString();

          if (!props.supplierId) {
            throw {
              message: `Company must be selected, got ${props.supplierId}`,
            };
          }

          try {
            await CompanyDeactivateMutation(props.environment, {
              input: {
                companyId: props.supplierId,
                endDate: now,
              },
            });
            if (props.refetch) {
              props.refetch();
            }
            setSubmitting(false);

            if (props.onClose) {
              props.onClose();
            }
          } catch (ex) {
            setStatus(ex.message || 'Submit Failed');
            setSubmitting(false);
          }
        }}
        validationSchema={schema}
        initialValues={{reason: ''}}
        render={({handleSubmit, status, isSubmitting}) => (
          <Form>
            <div>
              Are you sure you want to delete{' '}
              {props.supplierName ? props.supplierName : 'this company'} and all
              its listed traders?
              <br />
              This action cannot be undone.
            </div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{width: '100%', margin: '60px 0 10px'}}
              className={classes.button}
              onClick={() => handleSubmit()}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Spinner
                  name="circle"
                  color="white"
                  // @ts-ignore
                  style={{margin: '0 auto'}}
                />
              ) : (
                'Delete'
              )}
              <FontAwesomeIcon
                icon={faChevronRight}
                style={{fontSize: '0.875rem', paddingLeft: '10px'}}
              />
            </Button>
            {status && (
              <Typography className={classes.error}>{status}</Typography>
            )}
          </Form>
        )}
      />
    </div>
  );
}

export default withStyles(styles)(DeclineTradeModal);

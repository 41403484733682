import React from 'react';
import {withStyles, WithStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import styles from './ModalStyles';
import {Formik} from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import {EnvironmentProp} from '../../RelayRenderer';
import CompanyCreateMutation from './mutations/CompanyCreateMutation';
import SupplierFields, {schema} from './SupplierFields';

interface Props extends WithStyles<typeof styles>, EnvironmentProp {
  onClose: () => void;
  refetch?: () => void;
}

interface Values {
  company: string;
  regNumber: string;
  vatNumber: string;
  email: string;
  contact: string;
  primaryLicenseType?: string;
  primaryLicenseNumber?: string;
  primaryLicenseExpires?: string;
  otherLicenseType?: string;
  otherLicenseNumber?: string;
  otherLicenseExpires?: string;
  tradeLimit: number;
  sharepointIdentifier?: string;
}

const Clicked = (e: React.MouseEvent<HTMLElement>) => {
  e.stopPropagation();
};

class AddSupplierModal extends React.Component<Props> {
  public render() {
    const {classes} = this.props;
    return (
      <div
        className={classes.root}
        onClick={(e: React.MouseEvent<HTMLElement>) => Clicked(e)}
      >
        <Typography variant="h6" className={classes.title}>
          Add Supplier
          <span
            className={classes.exit}
            onClick={() => this.props.onClose && this.props.onClose()}
          >
            +
          </span>
        </Typography>
        <Formik
          onSubmit={async (values: Values, {setSubmitting, setStatus}) => {
            setSubmitting(true);

            try {
              await CompanyCreateMutation(this.props.environment, {
                input: {
                  name: values.company,
                  registrationNumber: values.regNumber,
                  vatNumber: values.vatNumber,
                  emailAddress: values.email,
                  contactNumber: values.contact,
                  licenseType:
                    values.primaryLicenseType === 'None'
                      ? undefined
                      : values.primaryLicenseType,
                  licenseNumber:
                    values.primaryLicenseType === 'None'
                      ? undefined
                      : values.primaryLicenseNumber,
                  licenseExpiry:
                    values.primaryLicenseType === 'None'
                      ? undefined
                      : moment(values.primaryLicenseExpires)
                          .add(7, 'hours')
                          .toISOString(),
                  secondaryLicenseType:
                    values.otherLicenseType === 'None'
                      ? undefined
                      : values.otherLicenseType,
                  secondaryLicenseNumber:
                    values.otherLicenseType === 'None'
                      ? undefined
                      : values.otherLicenseNumber,
                  secondaryLicenseExpiry:
                    values.otherLicenseType === 'None'
                      ? undefined
                      : values.otherLicenseType &&
                        values.otherLicenseType !== ''
                      ? moment(values.otherLicenseExpires)
                          .add(7, 'hours')
                          .toISOString()
                      : null,
                  tradeLimit: values.tradeLimit,
                  sharepointIdentifier: values.sharepointIdentifier,
                },
              });
              if (this.props.refetch) {
                this.props.refetch();
              }
              setSubmitting(false);
              if (this.props.onClose) {
                this.props.onClose();
              }
            } catch (ex) {
              setStatus(ex.message);
              setSubmitting(false);
              if (ex.message === 'Email Address Already Exists') {
                setStatus(`${values.email} Already exists.`);
              }
            }
          }}
          validationSchema={schema}
          initialValues={{
            company: '',
            regNumber: '',
            vatNumber: '',
            email: '',
            contact: '',
            primaryLicenseType: 'None',
            primaryLicenseNumber: '',
            primaryLicenseExpires: '',
            otherLicenseType: 'None',
            otherLicenseNumber: '',
            otherLicenseExpires: '',
            tradeLimit: 0,
            sharepointIdentifier: undefined,
          }}
          render={(formikProps) => (
            <SupplierFields buttonTitle={'Add Supplier'} {...formikProps} />
          )}
        />
      </div>
    );
  }
}

export default withStyles(styles)(AddSupplierModal);

import * as React from 'react';
import {Theme} from '@material-ui/core';
import {createStyles, WithStyles, withStyles} from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import {faChevronDown} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {SupplierFilterQueryResponse} from './__generated__/SupplierFilterQuery.graphql';

import {
  createRelayRenderer,
  EnvironmentProp,
} from '../../components/RelayRenderer';
import appTheme from '../../../styles/theme';

import {graphql} from 'react-relay';

interface Props
  extends WithStyles<typeof styles>,
    EnvironmentProp,
    SupplierFilterQueryResponse {
  value: string[];
  onChange: (company?: string[]) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      float: 'right',
      padding: '10px',
      paddingRight: '0px',
      height: '18px',
      fontSize: '0.875rem',
      '& label': {
        paddingRight: '5px',
        lineHeight: '39px',
        display: 'block',
        float: 'left',
      },
    },
    option: {
      color: '#5c5c5c',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    input: {
      color: theme.palette.secondary.main,
      minWidth: '200px',
      fontSize: '0.875rem',
      display: 'block',
      float: 'right',
      backgroundColor: theme.palette.primary.light,
      lineHeight: '28px',
      padding: '0 10px 0 10px',
      maxWidth: '200px',
      '&:before': {
        display: 'none',
      },
      '& svg': {
        marginTop: 5,
      },
      '& filled': {
        left: '0 !Important',
        width: '214px !important',
      },
    },
    dropdownStyle: {
      borderRadius: '0',
      width: '220px',
      marginLeft: '-10px',
      marginTop: '33px',
      '& *:focus': {
        outline: 'none',
      },
      '& ul': {
        padding: '0',
        '& option': {
          padding: '0 10px',
        },
      },
    },
  });

class SupplierFilter extends React.Component<Props> {
  public render() {
    const {
      classes,
      viewer: {companies},
    } = this.props;
    const {value} = this.props;

    const comapanyNameById: {[key: string]: string} = companies
      ? companies.edges.reduce((acc: {[key: string]: string}, edge) => {
          if (edge && edge.node) {
            acc[edge.node.id] = edge.node.info.name;
          }

          return acc;
        }, {})
      : {};

    return (
      <div className={classes.root}>
        <label>Filter by Supplier</label>

        <Select
          className={classes.input}
          IconComponent={(props) => (
            <FontAwesomeIcon icon={faChevronDown} className={props.className} />
          )}
          onChange={(event: React.ChangeEvent<{value: unknown}>) => {
            this.props.onChange(event.target.value as string[]);
          }}
          value={value}
          disabled={!companies}
          displayEmpty
          multiple={true}
          renderValue={(selected) => {
            if ((selected as string[]).length === 0) {
              return <em>Search for supplier</em>;
            }

            // TODO: maybe disable and render loading like this too?

            return (selected as string[])
              .map((id) => comapanyNameById[id] || 'Unknown')
              .join(', ');
          }}
          MenuProps={{classes: {paper: classes.dropdownStyle}}}
        >
          <MenuItem disabled={true} value="" className={classes.option}>
            Search for supplier
          </MenuItem>
          {companies
            ? companies.edges.map((company) => (
                <MenuItem
                  key={company.node.id}
                  value={company.node.id}
                  className={classes.option}
                  style={
                    value.includes(company.node.id)
                      ? {backgroundColor: '#dcdcdf'}
                      : {}
                  }
                >
                  {company.node.info.name}
                </MenuItem>
              ))
            : 'Loading...'}
        </Select>
      </div>
    );
  }
}

export default createRelayRenderer({
  query: graphql`
    query SupplierFilterQuery {
      viewer {
        companies(active: true) {
          edges {
            node {
              id
              info {
                name
              }
            }
          }
        }
      }
    }
  `,
  container: withStyles(styles)(SupplierFilter),
});

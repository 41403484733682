import * as React from 'react';

type Reload = (done: () => void) => void;

interface ITradeReload {
  loading: boolean;

  doReload(): void;

  addListener(listen: () => void): void;
}

export class TradeReload implements ITradeReload {
  public loading: boolean;
  private listeners: Reload[];

  constructor() {
    this.loading = false;
    this.listeners = [];
  }

  public doReload = () => {
    this.listeners.map((l) => l(() => null));
  };

  public addListener(listener: () => void) {
    this.listeners.push(listener);

    return () => {
      this.listeners = this.listeners.filter((l) => l !== listener);
    };
  }
}

export default React.createContext<ITradeReload>(new TradeReload());

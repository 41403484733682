import * as React from 'react';
import SupplierFilter from './SupplierFilter';
import {Theme} from '@material-ui/core';
import {createStyles, WithStyles, withStyles} from '@material-ui/core/styles';
import moment from 'moment';
import chevronDown from '../../../assets/chevrondown.svg';
import TraderFilter from './TraderFilter';

interface Props extends WithStyles<typeof styles> {
  supplierIds: string[];
  traderIds: string[];
  startDate?: string;
  endDate?: string;
  onStartDateChange: (startDate?: string) => void;
  onEndDateChange: (endDate?: string) => void;
  onSupplierIdsChange: (company?: string[]) => void;
  onTraderIdsChange: (traders?: string[]) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      float: 'right',
      padding: '10px 0px 10px 10px',
      height: '18px',
      fontSize: '0.875rem',
      '& label': {
        paddingRight: '10px',
        lineHeight: '39px',
        display: 'block',
        float: 'left',
      },
    },
    filterRow: {
      borderBottom: `solid 1px ${theme.palette.primary.dark}`,
      paddingBottom: 10,
    },
    option: {
      color: '#5c5c5c',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    input: {
      color: theme.palette.secondary.main,
      fontSize: '0.875rem',
      display: 'block',
      float: 'right',
      backgroundColor: theme.palette.primary.light,
      border: 'none',
      lineHeight: '39px',
      padding: '0 10px 0 10px',
      maxWidth: '200px',
      '&::-webkit-inner-spin-button': {
        display: 'none',
      },
      '&::-webkit-calendar-picker-indicator': {
        color: 'rgba(0, 0, 0, 0)',
        opacity: 1,
        display: 'block',
        background: `url(${chevronDown}) no-repeat`,
        width: '10px',
        height: '10px',
        borderWidth: 'thin',
      },
      '&:before': {
        display: 'none',
      },
      '& svg': {
        marginTop: 5,
      },
      '& filled': {
        left: '0 !Important',
        width: '214px !important',
      },
    },
    dropdownStyle: {
      borderRadius: '0',
      width: '220px',
      marginLeft: '-10px',
      marginTop: '33px',
      '& *:focus': {
        outline: 'none',
      },
      '& ul': {
        padding: '0',
        '& option': {
          padding: '0 10px',
        },
      },
    },
  });
class TradeFilter extends React.Component<Props> {
  public render() {
    const {
      classes,
      onStartDateChange,
      onEndDateChange,
      onSupplierIdsChange,
      onTraderIdsChange,
      supplierIds,
      traderIds,
      startDate = '',
      endDate = '',
    } = this.props;

    return (
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <div className={classes.root}>
          <label>Filter by date</label>

          <input
            className={classes.input}
            type="date"
            name="startDate"
            value={startDate}
            min={moment()
              .format('YYYY MM DD')
              .toString()}
            max={moment()
              .format('YYYY MM DD')
              .toString()}
            onChange={(e) =>
              onStartDateChange(
                e.target.value === '' ? undefined : e.target.value
              )
            }
          />
        </div>

        <div className={classes.root}>
          <label>to </label>
          <input
            className={classes.input}
            type="date"
            value={endDate}
            name="endDate"
            max="2025-12-31"
            onChange={(e) =>
              onEndDateChange(
                e.target.value === '' ? undefined : e.target.value
              )
            }
          />
        </div>
        <SupplierFilter value={supplierIds} onChange={onSupplierIdsChange} />
        <TraderFilter
          companyIds={supplierIds}
          value={traderIds}
          onChange={onTraderIdsChange}
        />
      </div>
    );
  }
}

export default withStyles(styles)(TradeFilter);

import * as React from 'react';
import classNames from 'classnames';
import {withStyles, createStyles, WithStyles} from '@material-ui/core/styles';
import {Theme, Button} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import {Suppliers_viewer} from './__generated__/Suppliers_viewer.graphql';

import Modal from '../components/modals/Modal';
import CompanyList from './components/CompanyList';
import {graphql, createRefetchContainer, RelayRefetchProp} from 'react-relay';
import {
  createRelayRenderer,
  EnvironmentProp,
} from '../components/RelayRenderer';

import Loading from '../components/Loading';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      boxShadow: '0 0 0',
    },
    blur: {
      filter: 'blur(1.5px)',
      oFilter: 'blur(1.5px)',
      msFilter: 'blur(1.5px)',
      mozFilter: 'blur(1.5px)',
      webkitFilter: 'blur(1.5px)',
    },
    spacer: {
      borderBottom: `solid 1px ${theme.palette.primary.dark}`,
      paddingBottom: 10,
      height: 59,
      marginBottom: 20,
    },
    addButton: {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.secondary.light,
      border: `solid 1px ${theme.palette.primary.contrastText}`,
      borderRadius: 0,
      float: 'right',
      marginTop: 10,
      lineHeight: 1.45,
      textTransform: 'unset',
      '&:hover': {
        backgroundColor: theme.palette.secondary.contrastText,
        color: theme.palette.primary.light,
        border: `solid 1px ${theme.palette.secondary.contrastText}`,
      },
    },
  });

interface Props extends WithStyles<typeof styles>, EnvironmentProp {
  relay: RelayRefetchProp;
  viewer: Suppliers_viewer;
}

type Tables = 'PendingTrades' | 'Receipts' | 'Completed' | 'Declined';

type Modal =
  | null
  | 'newTransaction'
  | 'declineTrade'
  | 'deliverTrade'
  | 'addSupplier'
  | 'editSupplier'
  | 'addTrader'
  | 'editTrader'
  | 'deleteSupplier'
  | 'deleteTrader';

interface State {
  selectedTable: Tables;
  modalOpen: Modal;
  modalProps: any;
  isLoading: boolean;
}

class Suppliers extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedTable: 'PendingTrades',
      modalOpen: null,
      modalProps: {},
      isLoading: false,
    };
  }

  public openModal = (modal: Modal, modalProps: {} = {}) => {
    this.setState({modalOpen: modal, modalProps});
  };

  public Refetch = () => {
    this.setState({isLoading: true});
    this.props.relay.refetch(
      {}, // Refetch no variables
      {}, // Render no variables
      () => this.setState({isLoading: false})
    );
  };

  public render() {
    const {classes, viewer} = this.props;

    const {modalOpen, modalProps, isLoading} = this.state;
    const {root, blur, addButton, spacer} = classes;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        {modalOpen !== null && (
          <Modal
            modal={modalOpen}
            modalProps={{refetch: this.Refetch, ...modalProps}}
            openModal={this.openModal}
          />
        )}
        <div className={spacer}>
          <Button
            onClick={() => this.openModal('addSupplier')}
            className={
              modalOpen !== null ? classNames(addButton, blur) : addButton
            }
          >
            Add Supplier +
          </Button>
        </div>
        <Paper className={modalOpen !== null ? classNames(root, blur) : root}>
          <CompanyList
            openModal={this.openModal}
            viewer={viewer as any}
            environment={this.props.environment}
            refetch={this.Refetch}
          />
        </Paper>
      </div>
    );
  }
}

const query = graphql`
  query SuppliersQuery {
    viewer {
      ...Suppliers_viewer
    }
  }
`;

const refetchableSuppliersList = createRefetchContainer(
  Suppliers,
  {
    viewer: graphql`
      fragment Suppliers_viewer on Viewer {
        companies(active: true) {
          edges {
            node {
              id
              info {
                name
                vatNumber
                registrationNumber
                contactNumber
                emailAddress
                sharepointIdentifier
              }
              primaryLicense {
                type
                number
                expiry
              }
              secondaryLicense {
                type
                number
                expiry
              }
              tradeLimit {
                limit
              }
              tradeBalance {
                available
                pending
              }
              blocked
              users(active: true) {
                edges {
                  node {
                    id
                    username
                    profile {
                      firstname
                      lastname
                      email
                      cellphone
                    }
                    blocked
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
  query
);

export default withStyles(styles)(
  createRelayRenderer({
    container: refetchableSuppliersList,
    query,
  })
);

import * as React from 'react';
import {Disposable} from 'relay-runtime';
import {requestSubscription, graphql} from 'react-relay';
import {RelayContext} from './contexts/RelayContext';

const subscription = graphql`
  subscription TradePriceSubscription {
    currentTradePrice {
      id
      zarPerTroyOunce
      zarToUsd
      zarPerGram
      usdPerTroyOunce
      lastUpdated
    }
  }
`;

interface State {
  subscription?: Disposable;
}

export class TradePriceSubscription extends React.Component<{}, State> {
  public static contextType = RelayContext;
  public context: any;

  public constructor(props: {}) {
    super(props);
    this.state = {};
  }

  public componentDidMount() {
    this.subscribe();
  }

  public componentWillUnmount() {
    if (this.state.subscription) {
      this.state.subscription.dispose();
    }
  }

  private subscribe() {
    if (this.state.subscription) {
      this.state.subscription.dispose();
    }

    if (this.context) {
      const disposable = requestSubscription(this.context, {
        subscription,
        variables: {},
      });
      this.setState({subscription: disposable});
    }
  }

  public render() {
    return null;
  }
}

import {graphql} from 'react-relay';

import {createMutation} from '@stackworx/relay';

import {
  CompanyEditMutation_MutationResponse,
  CompanyEditMutation_MutationVariables,
} from './__generated__/CompanyEditMutation_Mutation.graphql';

const mutation = graphql`
  mutation CompanyEditMutation_Mutation($input: CompanyInfoUpdateInput!) {
    companyInfoUpdate(input: $input) {
      clientMutationId
    }
  }
`;

export default createMutation<
  CompanyEditMutation_MutationVariables,
  CompanyEditMutation_MutationResponse
>(mutation);

import * as React from 'react';
import {createStyles, WithStyles, withStyles} from '@material-ui/core/styles';
import {Theme} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const styles = (theme: Theme) =>
  createStyles({
    heading: {
      backgroundColor: theme.palette.primary.main,
      padding: '0 5%',
      '& h5': {
        color: theme.palette.secondary.light,
        borderTop: `solid 2px #525252`,
        padding: '10px 0 15px',
      },
    },
    metal: {
      color: theme.palette.secondary.light,
      backgroundColor: theme.palette.primary.contrastText,
      fontSize: 14,
      padding: '0 10px',
      marginLeft: 10,
      lineHeight: '32px',
      display: 'inline-block',
    },
  });

interface Props extends WithStyles<typeof styles> {
  title: string;
}

function Heading(props: Props) {
  const {classes, title} = props;
  return (
    <div className={classes.heading}>
      <Typography variant="h5">
        {title}
        <span className={classes.metal}>Au</span>
      </Typography>
    </div>
  );
}

export default withStyles(styles)(Heading);

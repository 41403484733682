import React from 'react';
import {withStyles, WithStyles, createStyles} from '@material-ui/core/styles';
import {Theme} from '@material-ui/core';

const styles = (_theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: 16,
    },
    label: {
      display: 'block',
      lineHeight: '19px',
      paddingTop: 6,
      paddingBottom: 7,
      fontSize: '0.875rem',
    },
    textArea: {
      padding: 5,
      outlineStyle: 'none',
      resize: 'none',
      border: 'solid 1px rgb(141, 141, 141)',
      boxSizing: 'border-box',
      width: '100%',
      fontSize: 16,
    },
  });

interface Props extends WithStyles<typeof styles> {
  name: string;
  label: string;
  type: string;
  hideLabel?: boolean;
  field: any;
  form: any;
}

function TextArea(props: Props) {
  const {
    classes,
    hideLabel,
    field,
    form: {touched, errors},
    ...atts
  } = props;
  return (
    <div className={classes.root}>
      {!hideLabel && (
        <label htmlFor={props.name} className={classes.label}>
          {props.label}
        </label>
      )}
      <textarea
        className={classes.textArea}
        rows={5}
        {...atts}
        {...field}
        name={props.field.name}
      />
    </div>
  );
}

export default withStyles(styles)(TextArea);

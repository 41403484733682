import * as React from 'react';
import {createStyles, WithStyles, withStyles} from '@material-ui/core/styles';
import {graphql} from 'react-relay';

import {
  createRelayRenderer,
  EnvironmentProp,
} from '../components/RelayRenderer';

const styles = () =>
  createStyles({
    list: {
      listStyle: 'none',
      display: 'flex',
      gap: '24px',
      padding: '0 12px',
    },
  });

interface Props extends EnvironmentProp, WithStyles<typeof styles> {
  viewer?: any;
}

const GoldPrice = ({viewer, classes}: Props) => {
  if (viewer.currentTradePrice) {
    return (
      <ul className={classes.list}>
        <li>{viewer.currentTradePrice.usdPerTroyOunce.toFixed(2)} USD/øz</li>
        <li>R{viewer.currentTradePrice.zarPerGram.toFixed(2)} /g</li>
        <li>{viewer.currentTradePrice.zarToUsd.toFixed(2)} ZAR/USD</li>
      </ul>
    );
  }

  return null;
};

const query = graphql`
  query GoldPriceQuery {
    viewer {
      currentTradePrice {
        id
        zarPerTroyOunce
        zarToUsd
        zarPerGram
        usdPerTroyOunce
        lastUpdated
      }
    }
  }
`;
export default createRelayRenderer({
  container: withStyles(styles)(GoldPrice),
  query,
});

import React from 'react';

export function NumberFormat(x: number | string | null) {
  if (typeof x === 'string' && x.replace(/,|[a-zA-Z]/gi, '') === 'NaN') {
    return 0;
  }

  if (
    x == null ||
    (typeof x === 'string' && x[x.length - 1] === '.') ||
    (typeof x === 'string' &&
      x[x.length - 1] === '0' &&
      x[x.length - 2] === '.')
  ) {
    return x;
  }

  // specifying latn locale breaks frontend
  return typeof x === 'string'
    ? parseFloat(x.replace(/,|[a-zA-Z]/gi, '')).toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
    : x.toLocaleString();
}

export function NumberClean(x: string | number) {
  if (typeof x === 'number') {
    return x;
  }
  return parseFloat(x.replace(',', ''));
}

/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
type DeclinedTradeRow_trade$ref = any;
type PendingTradeRow_trade$ref = any;
export type TradeStatus = "confirmed" | "delivered" | "pending" | "rejected" | "%future added value";
export type TradesSubscriptionsTransitionedSubscriptionVariables = {};
export type TradesSubscriptionsTransitionedSubscriptionResponse = {
    readonly tradeTransitioned: ({
        readonly trade: {
            readonly id: string;
            readonly tradeTimestamp: any;
            readonly status: TradeStatus;
            readonly company: {
                readonly id: string;
            };
            readonly " $fragmentRefs": PendingTradeRow_trade$ref & DeclinedTradeRow_trade$ref;
        };
        readonly previousStatus: TradeStatus | null;
    }) | null;
};
export type TradesSubscriptionsTransitionedSubscription = {
    readonly response: TradesSubscriptionsTransitionedSubscriptionResponse;
    readonly variables: TradesSubscriptionsTransitionedSubscriptionVariables;
};



/*
subscription TradesSubscriptionsTransitionedSubscription {
  tradeTransitioned {
    trade {
      id
      tradeTimestamp
      status
      company {
        id
      }
      ...PendingTradeRow_trade
      ...DeclinedTradeRow_trade
    }
    previousStatus
  }
}

fragment PendingTradeRow_trade on Trade {
  id
  refNumber
  tradeTimestamp
  company {
    id
    runningBalance
    tradeLimit {
      limit
    }
    info {
      name
    }
  }
  createdBy {
    id
    displayName
  }
  status
  weight
  usdPerTroyOunce
  zarToUsd
  zarPerGram
  requestedZarPerGram
  notes
}

fragment DeclinedTradeRow_trade on Trade {
  id
  tradeTimestamp
  status
  weight
  company {
    id
    info {
      name
    }
  }
  createdBy {
    id
    displayName
  }
  usdPerTroyOunce
  zarToUsd
  zarPerGram
  requestedZarPerGram
  rejection {
    eventTimestamp
    reason
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tradeTimestamp",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "previousStatus",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "subscription",
  "name": "TradesSubscriptionsTransitionedSubscription",
  "id": null,
  "text": "subscription TradesSubscriptionsTransitionedSubscription {\n  tradeTransitioned {\n    trade {\n      id\n      tradeTimestamp\n      status\n      company {\n        id\n      }\n      ...PendingTradeRow_trade\n      ...DeclinedTradeRow_trade\n    }\n    previousStatus\n  }\n}\n\nfragment PendingTradeRow_trade on Trade {\n  id\n  refNumber\n  tradeTimestamp\n  company {\n    id\n    runningBalance\n    tradeLimit {\n      limit\n    }\n    info {\n      name\n    }\n  }\n  createdBy {\n    id\n    displayName\n  }\n  status\n  weight\n  usdPerTroyOunce\n  zarToUsd\n  zarPerGram\n  requestedZarPerGram\n  notes\n}\n\nfragment DeclinedTradeRow_trade on Trade {\n  id\n  tradeTimestamp\n  status\n  weight\n  company {\n    id\n    info {\n      name\n    }\n  }\n  createdBy {\n    id\n    displayName\n  }\n  usdPerTroyOunce\n  zarToUsd\n  zarPerGram\n  requestedZarPerGram\n  rejection {\n    eventTimestamp\n    reason\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TradesSubscriptionsTransitionedSubscription",
    "type": "Subscription",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "tradeTransitioned",
        "storageKey": null,
        "args": null,
        "concreteType": "TradeTransitioned",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "trade",
            "storageKey": null,
            "args": null,
            "concreteType": "Trade",
            "plural": false,
            "selections": [
              v0,
              v1,
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "company",
                "storageKey": null,
                "args": null,
                "concreteType": "Company",
                "plural": false,
                "selections": [
                  v0
                ]
              },
              {
                "kind": "FragmentSpread",
                "name": "PendingTradeRow_trade",
                "args": null
              },
              {
                "kind": "FragmentSpread",
                "name": "DeclinedTradeRow_trade",
                "args": null
              }
            ]
          },
          v3
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TradesSubscriptionsTransitionedSubscription",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "tradeTransitioned",
        "storageKey": null,
        "args": null,
        "concreteType": "TradeTransitioned",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "trade",
            "storageKey": null,
            "args": null,
            "concreteType": "Trade",
            "plural": false,
            "selections": [
              v0,
              v1,
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "company",
                "storageKey": null,
                "args": null,
                "concreteType": "Company",
                "plural": false,
                "selections": [
                  v0,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "runningBalance",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "tradeLimit",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CompanyTradeLimit",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "limit",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "info",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CompanyInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "name",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "refNumber",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "createdBy",
                "storageKey": null,
                "args": null,
                "concreteType": "AuditUser",
                "plural": false,
                "selections": [
                  v0,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "displayName",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "weight",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "usdPerTroyOunce",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "zarToUsd",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "zarPerGram",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "requestedZarPerGram",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "notes",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "rejection",
                "storageKey": null,
                "args": null,
                "concreteType": "LatestTradeRejection",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "eventTimestamp",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "reason",
                    "args": null,
                    "storageKey": null
                  },
                  v0
                ]
              }
            ]
          },
          v3
        ]
      }
    ]
  }
};
})();
(node as any).hash = 'd5e533a42e65d6c5a11b54649db35fd3';
export default node;

import React from 'react';
import {withStyles, WithStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import styles from './ModalStyles';
import Button from '@material-ui/core/Button';
import {TextField} from 'formik-material-ui';
import {Formik, Form, Field} from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import Spinner from 'react-spinkit';

import {EnvironmentProp} from '../../RelayRenderer';
import UserCreateMutation from './mutations/UserCreateMutation';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

interface Props extends WithStyles<typeof styles>, EnvironmentProp {
  onClose: () => void;
  companyId?: string;
  companyName?: string;
  refetch?: () => void;
}

const schema = Yup.object().shape({
  firstName: Yup.string()
    .required('Required')
    .max(100, 'Value is too long'),
  lastName: Yup.string()
    .required('Required')
    .max(100, 'Value is too long'),
  email: Yup.string()
    .email()
    .required('Required')
    .max(100, 'Value is too long'),
  mobileNumber: Yup.string()
    .required('Required')
    .matches(/^0{1}(\d[\s]*){9}$/, 'Invalid phone number format'),
  pin: Yup.string()
    .required('Required')
    .max(4, 'Pin must consist of exactly 4 digits')
    .min(4, 'Pin must consist of exactly 4 digits'),
  password: Yup.string()
    .required('Required')
    .max(60, 'Value is too long'),
});

interface Values {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  pin: string;
  password: string;
}

const Clicked = (e: React.MouseEvent<HTMLElement>) => {
  e.stopPropagation();
};

function AddSupplierModal(props: Props) {
  const {classes, companyName} = props;
  return (
    <div
      className={classes.root}
      onClick={(e: React.MouseEvent<HTMLElement>) => Clicked(e)}
    >
      <Typography variant="h6" className={classes.title}>
        Add Trader
        <small> to {companyName}</small>
        <span
          className={classes.exit}
          onClick={() => props.onClose && props.onClose()}
        >
          +
        </span>
      </Typography>
      <Formik
        onSubmit={async (values: Values, {setSubmitting, setStatus}) => {
          setSubmitting(true);

          try {
            // await props.handleSubmit(values);
            if (!props.companyId) {
              throw {message: 'Company must be selected'};
            }

            const output = await UserCreateMutation(props.environment, {
              input: {
                firstname: values.firstName,
                lastname: values.lastName,
                email: values.email,
                cellphone: values.mobileNumber,
                pin: values.pin,
                password: values.password,
                companyId: props.companyId,
              },
            });
            if (props.refetch) {
              props.refetch();
            }
            setSubmitting(false);
            if (props.refetch) {
              props.refetch();
            }

            if (props.onClose) {
              props.onClose();
            }
          } catch (ex) {
            setStatus(ex.message);
            setSubmitting(false);
            if (ex.message === 'Email Address Already Exists') {
              setStatus(`${values.email} Already exists.`);
            }
          }
        }}
        validationSchema={schema}
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          mobileNumber: '',
          pin: '',
          password: '',
        }}
        render={({handleSubmit, isSubmitting, status}) => (
          <Form>
            <Field
              InputLabelProps={{shrink: true}}
              name="firstName"
              label="First Name"
              type="text"
              component={TextField}
              className={classNames(classes.textHalf, classes.first)}
            />
            <Field
              InputLabelProps={{shrink: true}}
              name="lastName"
              label="Last Name"
              type="text"
              component={TextField}
              className={classes.textHalf}
            />
            <br />
            <Field
              InputLabelProps={{shrink: true}}
              name="email"
              label="Email Address"
              type="text"
              component={TextField}
              style={{width: '100%', margin: '10px 0 0'}}
            />
            <br />
            <Field
              InputLabelProps={{shrink: true}}
              name="mobileNumber"
              label="Mobile Number"
              type="text"
              component={TextField}
              style={{width: '100%', margin: '10px 0 0'}}
            />
            <br />
            <Field
              InputLabelProps={{shrink: true}}
              name="pin"
              label="PIN"
              type="text"
              component={TextField}
              style={{width: '100%', margin: '10px 0 0'}}
            />
            <Field
              InputLabelProps={{shrink: true}}
              name="password"
              label="Password"
              type="password"
              component={TextField}
              style={{width: '100%', margin: '10px 0 0'}}
            />
            <br />
            <Button
              variant="contained"
              color="primary"
              style={{width: '100%', margin: '30px 0 10px'}}
              className={classes.button}
              onClick={() => handleSubmit()}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Spinner
                  name="circle"
                  color="white"
                  // @ts-ignore
                  style={{margin: '0 auto'}}
                />
              ) : (
                'Add Trader'
              )}
              <FontAwesomeIcon
                icon={faChevronRight}
                style={{fontSize: '0.875rem', paddingLeft: '10px'}}
              />
            </Button>
            {status && (
              <Typography className={classes.error}>{status}</Typography>
            )}
          </Form>
        )}
      />
    </div>
  );
}

export default withStyles(styles)(AddSupplierModal);

import * as React from 'react';
import {ConnectionHandler} from 'relay-runtime';
import {requestSubscription} from 'react-relay';
import isEqual from 'react-fast-compare';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import {WithStyles, withStyles} from '@material-ui/core/styles';
import {
  graphql,
  createPaginationContainer,
  RelayPaginationProp,
} from 'react-relay';
import {Disposable, RecordSourceSelectorProxy} from 'relay-runtime';

import {
  createRelayRenderer,
  EnvironmentProp,
} from '../../components/RelayRenderer';
import Loading from '../../components/Loading';
import {PendingTradesTable_viewer} from './__generated__/PendingTradesTable_viewer.graphql';
import TradeReloadContext from '../contexts/TradeReloadContext';
import styles from '../../components/TableStyles';
import PendingTradeRow from './PendingTradeRow';
import {Filters} from '../types';
import {PendingTradesTableTradeCreatedSubscriptionResponse} from './__generated__/PendingTradesTableTradeCreatedSubscription.graphql';
import {isInFilter} from './TradesSubscriptions';
import Error from '../../components/Error';

interface Props extends WithStyles<typeof styles>, EnvironmentProp {
  openModal: (
    modal: null | 'declineTrade' | 'confirmTrade',
    modalprops?: {}
  ) => void;
  viewer: PendingTradesTable_viewer;
  relay: RelayPaginationProp;
  filters: Filters;
}

const TOTAL = 20;

interface State {
  isLoading: boolean;
  error?: Error;
}

class PendingTradesTable extends React.Component<Props, State> {
  public static contextType = TradeReloadContext;
  private handlerRef: any;

  private tradeCreatedSubscription?: Disposable;

  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  private loadMore() {
    if (!this.props.relay.hasMore() || this.props.relay.isLoading()) {
      return;
    }

    this.props.relay.loadMore(
      10, // Fetch the next 10 feed items
      (error) => {
        this.setState({error});
      }
    );
  }

  public componentDidMount() {
    const {relay} = this.props;
    this.handlerRef = this.context.addListener(() => this.refetchConnection());

    this.tradeCreatedSubscription = requestSubscription(relay.environment, {
      subscription: graphql`
        subscription PendingTradesTableTradeCreatedSubscription {
          tradeCreated {
            id
            status
            tradeTimestamp
            company {
              id
            }
            ...PendingTradeRow_trade
          }
        }
      `,
      variables: {},
      // @ts-ignore
      updater: (
        store: RecordSourceSelectorProxy,
        data: PendingTradesTableTradeCreatedSubscriptionResponse
      ) => {
        const {supplierIds} = this.props.filters;

        const viewer = store.get('client:root:viewer');
        const trades = ConnectionHandler.getConnection(
          viewer,
          'ViewerPending_trades',
          {supplierIds}
        );

        const trade = store.getRootField('tradeCreated');

        if (
          data.tradeCreated.status === 'pending' &&
          isInFilter(
            data.tradeCreated.tradeTimestamp,
            data.tradeCreated.company.id,
            this.props.filters
          )
        ) {
          const edge = ConnectionHandler.createEdge(
            store,
            trades,
            trade,
            'ViewerTradesEdge'
          );
          ConnectionHandler.insertEdgeBefore(trades, edge);
        }
      },
    });
  }

  public componentWillUnmount() {
    if (this.handlerRef) {
      this.handlerRef();
    }

    if (this.tradeCreatedSubscription) {
      this.tradeCreatedSubscription.dispose();
    }
  }

  public componentWillReceiveProps(nextProps: Props) {
    if (!isEqual(nextProps.filters, this.props.filters)) {
      this.setState({isLoading: true, error: undefined});
      this.props.relay.refetchConnection(
        TOTAL,
        (error) => {
          this.setState({error, isLoading: false});
        },
        nextProps.filters
      );
    }
  }

  public refetchConnection() {
    this.setState({isLoading: true, error: undefined});
    this.props.relay.refetchConnection(
      TOTAL,
      (error) => {
        this.setState({error, isLoading: false});
      },
      this.props.filters
    );
  }

  public render() {
    const {classes, viewer, openModal, relay} = this.props;
    const {isLoading, error} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <>
        {error && <Error error={error} />}
        <Table className={classes.table}>
          <TableHead className={classes.header}>
            <TableRow className={classes.headerRow}>
              <TableCell className={classes.headerCell}>Requested</TableCell>
              <TableCell className={classes.headerCell}>Supplier</TableCell>
              <TableCell className={classes.headerCell}>Trader</TableCell>
              <TableCell className={classes.headerCell}>
                g | oz Requested
              </TableCell>
              <TableCell className={classes.headerCell}>
                Requested Spot Price (ZAR/g)
              </TableCell>
              <TableCell className={classes.headerCell}>
                Metal Price (USD/oz)
              </TableCell>
              <TableCell className={classes.headerCell}>
                Exchange Rate (ZAR/USD)
              </TableCell>
              <TableCell className={classes.headerCell}>
                Spot Price (ZAR/g)
              </TableCell>
              <TableCell className={classes.headerCell}>Ref Nr</TableCell>
              <TableCell className={classes.headerCell}>
                Time Since Request
              </TableCell>
              <TableCell className={classes.headerCell}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {viewer.trades &&
              viewer.trades.edges.map(({node}) => {
                return (
                  <PendingTradeRow
                    trade={node as any}
                    openModal={openModal}
                    key={node.id}
                    environment={this.props.environment}
                  />
                );
              })}
            {viewer.trades && viewer.trades.edges.length === 0 && (
              <TableRow>
                <TableCell colSpan={13}>No results found.</TableCell>
              </TableRow>
            )}
          </TableBody>
          {relay.hasMore() && (
            <Button onClick={() => this.loadMore()} className={classes.button}>
              Load More
            </Button>
          )}
        </Table>
      </>
    );
  }
}

const query = graphql`
  query PendingTradesTableQuery(
    $count: Int!
    $cursor: String
    $supplierIds: [ID!]
    $traderIds: [ID!]
    $startDate: DateTime
    $endDate: DateTime
  ) {
    viewer {
      ...PendingTradesTable_viewer
        @arguments(
          count: $count
          cursor: $cursor
          supplierIds: $supplierIds
          traderIds: $traderIds
          startDate: $startDate
          endDate: $endDate
        )
    }
  }
`;

const PendingTradesTablePagination = createPaginationContainer(
  PendingTradesTable,
  {
    viewer: graphql`
      fragment PendingTradesTable_viewer on Viewer
        @argumentDefinitions(
          count: {type: "Int", defaultValue: 10}
          cursor: {type: "String"}
          supplierIds: {type: "[ID!]", list: true}
          traderIds: {type: "[ID!]", list: true}
          startDate: {type: "DateTime"}
          endDate: {type: "DateTime"}
        ) {
        trades(
          first: $count
          after: $cursor
          status: pending
          supplierIds: $supplierIds
          traderIds: $traderIds
          from: $startDate
          to: $endDate
        ) @connection(key: "ViewerPending_trades", filters: ["supplierIds"]) {
          total
          edges {
            node {
              id
              ...PendingTradeRow_trade
            }
          }
        }
      }
    `,
  },
  {
    getFragmentVariables(previousVariables, totalCount) {
      return {
        ...previousVariables,
        count: totalCount,
      };
    },
    getVariables(_props, {count, cursor}, fragmentVariables) {
      return {
        count,
        cursor,
        ...fragmentVariables,
      };
    },
    query,
  }
);

export default withStyles(styles)(
  createRelayRenderer({
    container: PendingTradesTablePagination,
    query,
  })
);

/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type SupplierFilterQueryVariables = {};
export type SupplierFilterQueryResponse = {
    readonly viewer: {
        readonly companies: ({
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly info: {
                        readonly name: string;
                    };
                };
            }>;
        }) | null;
    };
};
export type SupplierFilterQuery = {
    readonly response: SupplierFilterQueryResponse;
    readonly variables: SupplierFilterQueryVariables;
};



/*
query SupplierFilterQuery {
  viewer {
    companies(active: true) {
      edges {
        node {
          id
          info {
            name
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "companies",
    "storageKey": "companies(active:true)",
    "args": [
      {
        "kind": "Literal",
        "name": "active",
        "value": true,
        "type": "Boolean"
      }
    ],
    "concreteType": "ViewerCompaniesConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "ViewerCompaniesEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Company",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "info",
                "storageKey": null,
                "args": null,
                "concreteType": "CompanyInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "SupplierFilterQuery",
  "id": null,
  "text": "query SupplierFilterQuery {\n  viewer {\n    companies(active: true) {\n      edges {\n        node {\n          id\n          info {\n            name\n          }\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SupplierFilterQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "viewer",
        "name": "__viewer_viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": v0
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SupplierFilterQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": v0
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "viewer",
        "args": null,
        "handle": "viewer",
        "key": "",
        "filters": null
      }
    ]
  }
};
})();
(node as any).hash = 'e1a0cb7daf01dba85b08730999ccbe1b';
export default node;

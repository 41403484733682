/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
type PendingTradeRow_trade$ref = any;
export type TradeStatus = "confirmed" | "delivered" | "pending" | "rejected" | "%future added value";
export type PendingTradesTableTradeCreatedSubscriptionVariables = {};
export type PendingTradesTableTradeCreatedSubscriptionResponse = {
    readonly tradeCreated: {
        readonly id: string;
        readonly status: TradeStatus;
        readonly tradeTimestamp: any;
        readonly company: {
            readonly id: string;
        };
        readonly " $fragmentRefs": PendingTradeRow_trade$ref;
    };
};
export type PendingTradesTableTradeCreatedSubscription = {
    readonly response: PendingTradesTableTradeCreatedSubscriptionResponse;
    readonly variables: PendingTradesTableTradeCreatedSubscriptionVariables;
};



/*
subscription PendingTradesTableTradeCreatedSubscription {
  tradeCreated {
    id
    status
    tradeTimestamp
    company {
      id
    }
    ...PendingTradeRow_trade
  }
}

fragment PendingTradeRow_trade on Trade {
  id
  refNumber
  tradeTimestamp
  company {
    id
    runningBalance
    tradeLimit {
      limit
    }
    info {
      name
    }
  }
  createdBy {
    id
    displayName
  }
  status
  weight
  usdPerTroyOunce
  zarToUsd
  zarPerGram
  requestedZarPerGram
  notes
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tradeTimestamp",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "subscription",
  "name": "PendingTradesTableTradeCreatedSubscription",
  "id": null,
  "text": "subscription PendingTradesTableTradeCreatedSubscription {\n  tradeCreated {\n    id\n    status\n    tradeTimestamp\n    company {\n      id\n    }\n    ...PendingTradeRow_trade\n  }\n}\n\nfragment PendingTradeRow_trade on Trade {\n  id\n  refNumber\n  tradeTimestamp\n  company {\n    id\n    runningBalance\n    tradeLimit {\n      limit\n    }\n    info {\n      name\n    }\n  }\n  createdBy {\n    id\n    displayName\n  }\n  status\n  weight\n  usdPerTroyOunce\n  zarToUsd\n  zarPerGram\n  requestedZarPerGram\n  notes\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "PendingTradesTableTradeCreatedSubscription",
    "type": "Subscription",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "tradeCreated",
        "storageKey": null,
        "args": null,
        "concreteType": "Trade",
        "plural": false,
        "selections": [
          v0,
          v1,
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "company",
            "storageKey": null,
            "args": null,
            "concreteType": "Company",
            "plural": false,
            "selections": [
              v0
            ]
          },
          {
            "kind": "FragmentSpread",
            "name": "PendingTradeRow_trade",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PendingTradesTableTradeCreatedSubscription",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "tradeCreated",
        "storageKey": null,
        "args": null,
        "concreteType": "Trade",
        "plural": false,
        "selections": [
          v0,
          v1,
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "company",
            "storageKey": null,
            "args": null,
            "concreteType": "Company",
            "plural": false,
            "selections": [
              v0,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "runningBalance",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "tradeLimit",
                "storageKey": null,
                "args": null,
                "concreteType": "CompanyTradeLimit",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "limit",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "info",
                "storageKey": null,
                "args": null,
                "concreteType": "CompanyInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "refNumber",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "createdBy",
            "storageKey": null,
            "args": null,
            "concreteType": "AuditUser",
            "plural": false,
            "selections": [
              v0,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "displayName",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "weight",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "usdPerTroyOunce",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "zarToUsd",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "zarPerGram",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "requestedZarPerGram",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "notes",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
(node as any).hash = '08dd48d1a71c6d6b43ba9625e0da6b93';
export default node;

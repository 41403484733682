import {Theme} from '@material-ui/core';
import {createStyles, withStyles, WithStyles} from '@material-ui/core/styles';
import React from 'react';
import {RelayContext} from '../../../contexts';
import AddSupplierModal from './components/AddSupplierModal';
import AddTraderModal from './components/AddTraderModal';
import ConfirmTradeModal from './components/ConfirmTradeModal';
import DeclineTradeModal from './components/DeclineTradeModal';
import DeleteSupplierModal from './components/DeleteSupplierModal';
import DeleteTraderModal from './components/DeleteTraderModal';
import EditNoteModal from './components/EditNoteModal';
import EditSupplierModal from './components/EditSupplierModal';
import EditTraderModal from './components/EditTraderModal';
import ErrorModal from './components/ErrorModal';
import NewTradeModal from './components/NewTradeModal';
import NewTransactionModal from './components/NewTransactionModal';
import ReverseTransactionModal from './components/ReverseTransactionModal';
import TextModal from './components/TextModal';

const styles = (_theme: Theme) =>
  createStyles({
    root: {
      width: '100vw',
      height: '100vh',
      position: 'fixed',
      zIndex: 1,
      background: '#fffa',
      top: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  });

interface Props extends WithStyles<typeof styles> {
  modal:
    | 'newTransaction'
    | 'newTrade'
    | 'declineTrade'
    | 'confirmTrade'
    | 'deliverTrade'
    | 'addSupplier'
    | 'editSupplier'
    | 'addTrader'
    | 'editTrader'
    | 'deleteSupplier'
    | 'deleteTrader'
    | 'error'
    | 'reverseTransaction'
    | 'text'
    | 'editNotes';
  modalProps: {};
  openModal: (modal: null) => void;
}

class Modal extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  // tslint:disable-next-line
  public static contextType = RelayContext;

  public render() {
    const {classes, modal, modalProps, openModal} = this.props;
    return (
      <div className={classes.root}>
        {modal === 'newTransaction' && (
          <NewTransactionModal
            {...modalProps}
            onClose={() => openModal(null)}
          />
        )}
        {modal === 'newTrade' && (
          <NewTradeModal {...modalProps} onClose={() => openModal(null)} />
        )}
        {modal === 'declineTrade' && (
          <DeclineTradeModal
            {...modalProps}
            onClose={() => openModal(null)}
            environment={this.context as any}
          />
        )}
        {modal === 'confirmTrade' && (
          <ConfirmTradeModal
            {...modalProps as any}
            onClose={() => openModal(null)}
            environment={this.context as any}
          />
        )}
        {modal === 'addSupplier' && (
          <AddSupplierModal
            {...modalProps}
            onClose={() => openModal(null)}
            environment={this.context as any}
          />
        )}
        {modal === 'editSupplier' && (
          <>
            <EditSupplierModal
              {...modalProps}
              onClose={() => openModal(null)}
              environment={this.context as any}
            />
          </>
        )}
        {modal === 'addTrader' && (
          <AddTraderModal
            {...modalProps}
            onClose={() => openModal(null)}
            environment={this.context as any}
          />
        )}
        {modal === 'editTrader' && (
          <EditTraderModal
            {...modalProps}
            onClose={() => openModal(null)}
            environment={this.context as any}
          />
        )}
        {modal === 'deleteSupplier' && (
          <DeleteSupplierModal
            {...modalProps}
            onClose={() => openModal(null)}
            environment={this.context as any}
          />
        )}
        {modal === 'deleteTrader' && (
          <DeleteTraderModal
            {...modalProps}
            onClose={() => openModal(null)}
            environment={this.context as any}
          />
        )}
        {modal === 'reverseTransaction' && (
          <ReverseTransactionModal
            {...modalProps as {
              transactionId?: string;
              transactionTimestamp: string;
            }}
            onClose={() => openModal(null)}
            environment={this.context as any}
          />
        )}
        {modal === 'error' && (
          <ErrorModal {...modalProps} onClose={() => openModal(null)} />
        )}
        {modal === 'text' && (
          <TextModal {...modalProps} onClose={() => openModal(null)} />
        )}
        {modal === 'editNotes' && (
          <EditNoteModal
            {...modalProps as {
              transactionOrTradeId: string;
              notes: string;
              classes: any;
            }}
            environment={this.context as any}
            onClose={() => openModal(null)}
          />
        )}
      </div>
    );
  }
}
export default withStyles(styles)(Modal);

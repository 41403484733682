import {createStyles} from '@material-ui/core/styles';
import {Theme} from '@material-ui/core';

export default (theme: Theme) =>
  createStyles({
    root: {
      // border:`solid 1px ${theme.palette.primary.contrastText}`,
      backgroundColor: theme.palette.secondary.light,
      minWidth: '25%',
      maxWidth: '40%',
      minHeight: '20%',
      color: theme.palette.primary.dark,
      boxShadow:
        '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
      position: 'relative',
      maxHeight: '80vh',
      overflowY: 'auto',
      '& form': {
        padding: 20,
      },
    },
    title: {
      position: 'relative',
      padding: '5px 30px 5px 10px',
      backgroundColor: theme.palette.primary.light,
      color: '#111',
    },
    exit: {
      position: 'absolute',
      right: 10,
      top: 5,
      cursor: 'pointer',
      transform: 'rotate(45deg)',
    },
    button: {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.secondary.light,
      borderRadius: 0,
      boxShadow: '0 0 0',
      textTransform: 'capitalize',
    },
    buttonDown: {
      right: '0px',
      position: 'relative',
      left: '0px',
      display: 'block',
      margin: 'auto',
      width: '80%',
      marginBottom: '20px',
    },
    textHalf: {
      width: 'calc(50% - 5px)',
      display: 'inline-flex',
    },
    first: {
      marginRight: 10,
    },
    error: {
      color: 'red',
    },
    info: {
      overflowWrap: 'break-word',
      width: '80%',
      margin: '0 auto',
      marginTop: '20px',
    },
    gram: {
      top: '22px',
      right: 0,
      width: '100px',
      textAlign: 'right',
      marginRight: '10px',
      position: 'absolute',
      fontSize: '0.875rem',
    },
    gramHigh: {
      top: '22px',
      right: 0,
      width: '100px',
      textAlign: 'right',
      marginRight: '10px',
      position: 'absolute',
      fontSize: '0.875rem',
    },
    tooltip: {
      maxWidth: '400px !important',
    },
  });

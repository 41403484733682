import React from 'react';
import {withStyles, WithStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-material-ui';
import Typography from '@material-ui/core/Typography';
import styles from './ModalStyles';
import * as Yup from 'yup';
import moment from 'moment';
import Spinner from 'react-spinkit';

import {EnvironmentProp} from '../../RelayRenderer';
import DeclineTradeMutation from './mutations/RejectTradeMutation';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import NumberCounter from './NumberCounter';

interface Props extends WithStyles<typeof styles>, EnvironmentProp {
  tradeId?: string;
  onClose: () => void;
}

const Clicked = (e: React.MouseEvent<HTMLElement>) => {
  e.stopPropagation();
};

const schema = Yup.object().shape({
  reason: Yup.string()
    .required()
    .max(200, 'Value is too long'),
});

function DeclineTradeModal(props: Props) {
  const {classes} = props;
  return (
    <div
      className={classes.root}
      onClick={(e: React.MouseEvent<HTMLElement>) => Clicked(e)}
    >
      <Typography variant="h6" className={classes.title}>
        Supply Reason for Declining Trade
        <span
          className={classes.exit}
          onClick={() => props.onClose && props.onClose()}
        >
          +
        </span>
      </Typography>
      <Formik
        onSubmit={async (values, {setSubmitting, setStatus}) => {
          setSubmitting(true);
          const now = moment().toISOString();
          try {
            await DeclineTradeMutation(props.environment, {
              input: {
                tradeId: props.tradeId || '',
                timestamp: now.valueOf(),
                reason: values.reason,
              },
            });
            setSubmitting(false);

            if (props.onClose) {
              props.onClose();
            }
          } catch (ex) {
            setStatus(ex.message || 'Submit Failed');
            setSubmitting(false);
          }
        }}
        validationSchema={schema}
        initialValues={{reason: ''}}
        render={({handleSubmit, status, isSubmitting, values}) => (
          <Form>
            <Field
              InputLabelProps={{shrink: true}}
              name="reason"
              label="Reason"
              type="text"
              component={TextField}
              multiline
              rows={6}
              style={{width: '100%'}}
            />
            <NumberCounter current={values.reason.length} max={200} />
            <br />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{width: '100%', margin: '30px 0 10px'}}
              className={classes.button}
              onClick={() => handleSubmit()}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Spinner
                  name="circle"
                  color="white"
                  // @ts-ignore
                  style={{margin: '0 auto'}}
                />
              ) : (
                'Decline'
              )}
              <FontAwesomeIcon
                icon={faChevronRight}
                style={{fontSize: '0.875rem', paddingLeft: '10px'}}
              />
            </Button>
            {status && (
              <Typography className={classes.error}>{status}</Typography>
            )}
          </Form>
        )}
      />
    </div>
  );
}

export default withStyles(styles)(DeclineTradeModal);

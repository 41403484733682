import * as React from 'react';
import classNames from 'classnames';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import {WithStyles, withStyles} from '@material-ui/core/styles';
import {graphql, createFragmentContainer} from 'react-relay';
import Moment from 'react-moment';
import * as Yup from 'yup';
import {Formik, Field} from 'formik';
import Spinner from 'react-spinkit';

import styles from '../../components/TableStyles';
import InputInTable from '../../components/InputInTable';
import {NumberFormat, NumberClean} from '../../components/NumberFormat';
import TradeAdminPendingUpdateMutation from '../mutations/TradeAdminPendingUpdateMutation';
import {PendingTradeRow_trade} from './__generated__/PendingTradeRow_trade.graphql';
import {EnvironmentProp} from '../../components/RelayRenderer';
import InputInTableEndAdornment from '../../components/InputInTableEndAdornment';

interface Props extends WithStyles<typeof styles>, EnvironmentProp {
  trade: PendingTradeRow_trade;
  openModal: (
    modal: null | 'declineTrade' | 'confirmTrade',
    modalprops?: {}
  ) => void;
}

interface State {
  message: string;
}

interface Values {
  usdPerTroyOunce: string;
  zarToUsd: string;
  reference: string;
  gramsRequested: number;
}

const schema = Yup.object().shape({
  usdPerTroyOunce: Yup.string()
    .required('Required')
    .matches(/(\d{0,3},)?(\d{3},)?\d{0,3}/, 'Please enter a valid number')
    .test(
      'test-is-not-0',
      'Please enter a non-zero value',
      (value) => parseFloat(value) !== 0
    )
    .max(9, 'Value is too high'),
  zarToUsd: Yup.string()
    .required('Required')
    .matches(/(\d{0,3},)?(\d{3},)?\d{0,3}/, 'Please enter a valid number')
    .test(
      'test-is-not-0',
      'Please enter a non-zero value',
      (value) => parseFloat(value) !== 0
    )
    .max(9, 'Value is too high'),
  reference: Yup.string().max(30, 'Value is too long'),
});

class PendingTradeRow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    const {classes, openModal, trade, environment} = this.props;
    return (
      <Formik<Values>
        onSubmit={async (values, {setSubmitting, setStatus}) => {
          setSubmitting(true);
          setStatus(null);
          try {
            await TradeAdminPendingUpdateMutation(environment, {
              input: {
                zarPerTroyOunce:
                  NumberClean(values.usdPerTroyOunce || '0') *
                  NumberClean(values.zarToUsd),
                zarToUsd: NumberClean(values.zarToUsd || '0'),
                reference: values.reference,
                weight: Number(values.gramsRequested),
                tradeId: trade.id,
              },
            });
            setStatus('Trade details successfully updated');
            setSubmitting(false);
          } catch (ex) {
            setStatus(ex.message);
            setSubmitting(false);
          }
        }}
        validationSchema={schema}
        initialValues={{
          usdPerTroyOunce: trade.usdPerTroyOunce.toFixed(2),
          zarToUsd: trade.zarToUsd.toFixed(2),
          reference: trade.refNumber || '',
          gramsRequested: trade.weight,
        }}
        render={({handleSubmit, values, isSubmitting, status}) => (
          <TableRow key={trade.id} className={classes.row}>
            <TableCell scope="row">
              <Moment format="YYYY-MM-DD" date={trade.tradeTimestamp} />
              <br />
              <Moment format="HH:mm:ss" date={trade.tradeTimestamp} />
            </TableCell>
            <TableCell>{trade.company.info.name}</TableCell>
            <TableCell>{trade.createdBy.displayName}</TableCell>
            <TableCell>
              <Field
                name="gramsRequested"
                label="g|oz Requested"
                type="number"
                component={InputInTableEndAdornment}
              />
              <br />
              {NumberFormat(values.gramsRequested * 0.0321507)} oz
            </TableCell>
            <TableCell>
              R{NumberFormat(trade.requestedZarPerGram.toFixed(2))}
            </TableCell>
            <TableCell>
              <Field
                name="usdPerTroyOunce"
                label="Metal Price (USD/oz)"
                type="number"
                component={InputInTable}
              />
            </TableCell>
            <TableCell>
              <Field
                name="zarToUsd"
                label="Exchange Rate (ZAR/USD)"
                type="number"
                component={InputInTable}
              />
            </TableCell>
            <TableCell>R{NumberFormat(trade.zarPerGram.toFixed(2))}</TableCell>
            <TableCell>
              <Field
                name="reference"
                label="Reference Nr"
                type="text"
                component={InputInTable}
                placeholder="Reference"
              />
            </TableCell>
            <TableCell>
              <Moment fromNow date={trade.tradeTimestamp} />{' '}
            </TableCell>
            <TableCell className={classes.buttonContainer}>
              <Button
                variant="contained"
                className={classNames(classes.button, classes.buttonDecline)}
                onClick={() => handleSubmit()}
                style={{marginRight: 15}}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <Spinner
                    name="circle"
                    color="white"
                    // @ts-ignore
                    style={{margin: '0 auto'}}
                  />
                ) : (
                  'Update'
                )}
              </Button>
              <Button
                variant="contained"
                className={classNames(classes.button, classes.buttonDecline)}
                onClick={() => openModal('declineTrade', {tradeId: trade.id})}
                disabled={isSubmitting}
              >
                Decline
              </Button>
              <Button
                variant="contained"
                className={classNames(classes.button, classes.buttonAccept)}
                onClick={() =>
                  openModal('confirmTrade', {
                    tradeId: trade.id,
                    price: values.usdPerTroyOunce,
                    rate: values.zarToUsd,
                    reference: values.reference,
                    requestedWeight: trade.weight,
                    runningBalance: trade.company.runningBalance,
                    tradeLimit: trade.company.tradeLimit.limit,
                  })
                }
                disabled={isSubmitting}
              >
                Confirm
              </Button>
              {status !== '' && (
                <span
                  className={`${classes.notification} ${
                    status !== 'Trade details successfully updated'
                      ? 'error'
                      : ''
                  }`}
                >
                  {status}
                </span>
              )}
            </TableCell>
          </TableRow>
        )}
      />
    );
  }
}

export default createFragmentContainer(withStyles(styles)(PendingTradeRow), {
  trade: graphql`
    fragment PendingTradeRow_trade on Trade {
      id
      refNumber
      tradeTimestamp
      company {
        id
        runningBalance
        tradeLimit {
          limit
        }
        info {
          name
        }
      }
      createdBy {
        id
        displayName
      }
      status
      weight
      usdPerTroyOunce
      zarToUsd
      zarPerGram
      requestedZarPerGram
      notes
    }
  `,
});

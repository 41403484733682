import {graphql} from 'react-relay';

import {createMutation} from '@stackworx/relay';

import {
  WeightTransactionAdminCreateMutationResponse,
  WeightTransactionAdminCreateMutationVariables,
} from './__generated__/WeightTransactionAdminCreateMutation.graphql';

const mutation = graphql`
  mutation WeightTransactionAdminCreateMutation(
    $input: WeightTransactionAdminCreateInput!
  ) {
    weightTransactionAdminCreate(input: $input) {
      clientMutationId
    }
  }
`;

export default createMutation<
  WeightTransactionAdminCreateMutationVariables,
  WeightTransactionAdminCreateMutationResponse
>(mutation);

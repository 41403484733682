import {graphql} from 'react-relay';

import {createMutation} from '@stackworx/relay';

import {
  WeightTransactionEditGoldPercentageMutationResponse,
  WeightTransactionEditGoldPercentageMutationVariables,
} from './__generated__/WeightTransactionEditGoldPercentageMutation.graphql';

const mutation = graphql`
  mutation WeightTransactionEditGoldPercentageMutation(
    $input: WeightTransactionGoldPercentageEditInput!
  ) {
    weightTransactionEditGoldPercentage(input: $input) {
      clientMutationId
      id
      goldPercentage
    }
  }
`;

export default createMutation<
  WeightTransactionEditGoldPercentageMutationVariables,
  WeightTransactionEditGoldPercentageMutationResponse
>(mutation, {
  updater: (store, data) => {
    // @ts-ignore
    const current = store.get(data.id);
    if (current) {
      // @ts-ignore
      current.setValue(data.goldPercentage, 'goldPercentage');
    }
  },
});

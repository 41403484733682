import {createStyles} from '@material-ui/core/styles';
import {Theme} from '@material-ui/core';

export default (theme: Theme) =>
  createStyles({
    table: {
      minWidth: 700,
    },
    notes: {
      maxHeight: '40px',
      maxWidth: '100px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      cursor: 'pointer',
    },
    header: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
    },
    headerRow: {
      color: theme.palette.primary.main,
    },
    headerCell: {
      color: theme.palette.primary.main,
      borderRight: `solid 1px ${theme.palette.secondary.light}`,
      fontSize: '0.8125rem',
      padding: 20,
      maxWidth: 80,
      borderBottomWidth: 0,
    },
    differenceCell: {
      minWidth: 156,
      maxWidth: 156,
    },
    tradeCell: {
      maxHeight: 57,
      cursor: 'pointer',
      overflowY: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical',
      '&:hover': {
        color: theme.palette.secondary.contrastText,
      },
    },
    traderCell: {
      borderTop: 'solid 1px #fff',
      fontSize: '0.8125rem',
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
      padding: '5px 25px 5px 20px !important',
      borderBottomWidth: '1px !important',
    },
    buttonContainer: {
      minWidth: 331,
      paddingRight: '10px !important',
    },
    receiptedButtonContainer: {
      minWidth: 216,
      paddingRight: '10px !important',
    },
    button: {
      margin: theme.spacing.unit,
      borderRadius: 0,
      width: 100,
      maxWidth: 100,
      fontSize: 13,
      lineHeight: '13px',
      padding: '6px 0 8px 0',
      boxShadow: '0 0 0',
      textTransform: 'capitalize',
      '&:hover': {
        boxShadow: '0 0 0',
      },
    },
    buttonDecline: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.secondary.light,
      marginLeft: 0,
    },
    buttonAccept: {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.secondary.light,
      marginRight: 0,
      borderRadius: 0,
      width: 100,
      maxWidth: 100,
      fontSize: 13,
      boxShadow: '0 0 0',
      '&:hover': {
        backgroundColor: theme.palette.secondary.contrastText,
        boxShadow: '0 0 0',
      },
    },
    row: {
      '& td': {
        borderRight: `solid 1px ${theme.palette.secondary.light}`,
        color: theme.palette.secondary.main,
        padding: '4px 10px',
        borderBottomWidth: 0,
        // '& class':{

        // },
      },
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.primary.light,
        height: 24,
      },
      '& svg': {
        float: 'right',
        marginRight: -5,
        display: 'block',
        height: 26,
      },
    },
    supplierTable: {
      width: '100%',
      paddingBottom: '20px',
    },
    supplierCell: {
      color: theme.palette.primary.main,
      fontSize: '0.8125rem',
      padding: 20,
      fontWeight: 500,
      minWidth: 280,
      maxWidth: 280,
      width: 280,
      display: 'inline-block',
      '&:last-child': {
        width: 250,
        minWidth: 250,
        maxWidth: 250,
        float: 'right',
      },
    },
    traderRow: {
      backgroundColor: '#fff !important',
    },
    traderCellSpan: {
      minWidth: 280,
      maxWidth: 280,
      display: 'inline-block',
      marginRight: 40,
      lineHeight: '26px',
      textOverflow: 'ellipsis',
      maxHeight: '26px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    traderButton: {
      minWidth: 160,
      marginTop: 20,
      marginLeft: 20,
      fontSize: '0.8125rem',
      cursor: 'pointer',
      color: theme.palette.primary.contrastText,
    },
    reload: {
      float: 'right',
      display: 'inline-block',
    },
    wordCounter: {
      display: 'inline-block',
      float: 'right',
      paddingBottom: '5px',
      fontSize: '12px',
    },
    wordCounterNegative: {
      color: '#a12324',
    },
    wordCounterPositive: {
      color: '#23a124',
    },
    headerLast: {
      minWidth: 180,
      lineHeight: '36px',
    },
    notification: {
      display: 'block',
      minWidth: '100%',
      '&.error': {
        color: 'red',
      },
    },
    disabled: {
      color: 'rgba(0, 0, 0, 0.26)',
      boxShadow: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      '&:hover': {
        color: 'rgba(0, 0, 0, 0.26)',
        boxShadow: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        cursor: 'default',
      },
    },
    tooltip: {
      maxWidth: '400px !important',
    },
  });

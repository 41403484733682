import * as React from 'react';
import {Theme} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {createStyles, WithStyles, withStyles} from '@material-ui/core/styles';
import {faRedo} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import GoldPrice from '../../GoldPrice/GoldPrice';

import {EnvironmentProp} from '../../components/RelayRenderer';
import TradeReloadContext from '../contexts/TradeReloadContext';
import {Environment} from 'relay-runtime';

type Tables = 'PendingTrades' | 'Transactions' | 'Declined';

interface Props extends WithStyles<typeof styles>, EnvironmentProp {
  select: (tables: Tables) => void;
  selected: Tables;
  openModal: (
    modal: null | 'newTransaction' | 'newTrade',
    modalprops?: {}
  ) => void;
  handleTradeExport(env: Environment): Promise<void>;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      borderBottom: `solid 1px ${theme.palette.primary.dark}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingBottom: 10,
    },
    selectorButton: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.secondary.light,
      borderRadius: 0,
      boxSizing: 'border-box',
      margin: '10px 5px',
      textTransform: 'unset',
      // width: 160,
      '&.active': {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.primary.dark,
        border: `solid 1px ${theme.palette.primary.dark}`,
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.primary.dark,
        border: `solid 1px ${theme.palette.primary.dark}`,
      },
      '&:first-child': {
        margin: '10px 5px 10px 0px',
      },
    },
    addButton: {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.secondary.light,
      border: `solid 1px ${theme.palette.primary.contrastText}`,
      borderRadius: 0,
      float: 'right',
      marginTop: 10,
      marginBottom: 10,
      lineHeight: 1.45,
      textTransform: 'unset',
      '&:hover': {
        backgroundColor: theme.palette.secondary.contrastText,
        color: theme.palette.primary.light,
        border: `solid 1px ${theme.palette.secondary.contrastText}`,
      },
    },
  });

class TableSelector extends React.Component<Props> {
  public render() {
    const {classes, openModal, selected} = this.props;
    return (
      <div className={classes.root}>
        <div>
          <Button
            className={`${classes.selectorButton} ${selected ===
              'PendingTrades' && 'active'}`}
            onClick={() => this.props.select('PendingTrades')}
          >
            Pending Trades
          </Button>
          <Button
            className={`${classes.selectorButton} ${selected ===
              'Transactions' && 'active'}`}
            onClick={() => this.props.select('Transactions')}
          >
            Transactions
          </Button>
          <Button
            className={`${classes.selectorButton} ${selected === 'Declined' &&
              'active'}`}
            onClick={() => this.props.select('Declined')}
          >
            Declined
          </Button>
        </div>

        <GoldPrice />

        <div>
          {selected === 'Transactions' && (
            <Button
              className={classes.addButton}
              onClick={() =>
                openModal('newTransaction', {close: openModal(null)})
              }
            >
              Add Transaction +
            </Button>
          )}
          {selected === 'PendingTrades' && (
            <Button
              className={classes.addButton}
              onClick={() => openModal('newTrade', {close: openModal(null)})}
            >
              Add Trade +
            </Button>
          )}
          <TradeReloadContext.Consumer>
            {({doReload}) => (
              <Button
                className={classes.addButton}
                onClick={() => doReload()}
                style={{marginRight: 10}}
              >
                <FontAwesomeIcon
                  icon={faRedo}
                  style={{height: 20, fontSize: '12px'}}
                />
              </Button>
            )}
          </TradeReloadContext.Consumer>
          <Button
            className={classes.addButton}
            style={{marginRight: 10}}
            onClick={async () => {
              this.setState({error: null});
              try {
                await this.props.handleTradeExport(this.props.environment);
              } catch (ex) {
                alert(ex.message);
              }
            }}
          >
            Download XLSX
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(TableSelector);

import * as React from 'react';
import classNames from 'classnames';
import {withStyles, createStyles, WithStyles} from '@material-ui/core/styles';
import {Theme} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import XLSX from 'xlsx';
import {Environment} from 'relay-runtime';
import moment from 'moment';

import Modal from '../components/modals/Modal';
import TableSelector from './components/TableSelector';
import TradeFilter from './components/TradeFilter';
import TradesSubscriptions from './components/TradesSubscriptions';
import TableFilterHandler from './components/TableFilterHandler';

import TradeReloadContext, {TradeReload} from './contexts/TradeReloadContext';
import TradeDownloadMutation from './mutations/TradeDownloadMutation';
import {RelayContext} from '../../contexts/RelayContext';
import {Filters, Tables} from './types';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing.unit * 3,
      boxShadow: '0px 0px 0px',
      overflowX: 'scroll',
    },
    blur: {
      filter: 'blur(1.5px)',
      oFilter: 'blur(1.5px)',
      msFilter: 'blur(1.5px)',
      mozFilter: 'blur(1.5px)',
      webkitFilter: 'blur(1.5px)',
    },
  });

interface Props extends WithStyles<typeof styles> {}

type Modal =
  | null
  | 'newTransaction'
  | 'newTrade'
  | 'declineTrade'
  | 'confirmTrade'
  | 'deliverTrade'
  | 'addSupplier'
  | 'editSupplier'
  | 'addTrader'
  | 'editTrader'
  | 'error'
  | 'reverseTransaction'
  | 'text';

interface State {
  selectedTable: Tables;
  modalOpen: Modal;
  modalProps: any;
  selected: string;
  filters: Filters;
}

class Trades extends React.Component<Props, State> {
  private tradeReload = new TradeReload();

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedTable: 'PendingTrades',
      modalOpen: null,
      modalProps: {},
      selected: '',

      filters: {
        supplierIds: [],
        traderIds: [],
      },
    };
  }

  public static contextType = RelayContext;

  public setTable = (table: Tables) => {
    if (this.state.selectedTable !== table) {
      const {filters} = this.state;
      this.setState({
        filters: {
          ...filters,
          supplierIds:
            this.state.selectedTable === 'Transactions'
              ? []
              : filters.supplierIds,
          traderIds:
            this.state.selectedTable === 'Transactions'
              ? []
              : filters.traderIds,
        },
        selectedTable: table,
      });
    }
  };

  public openModal = (modal: Modal, modalProps: {} = {}) => {
    this.setState({modalOpen: modal, modalProps});
  };

  public render() {
    const {classes} = this.props;
    const {selectedTable, modalOpen, modalProps, filters} = this.state;
    const {root, blur} = classes;

    const graphqlFilters = {
      // Default amount of records to load
      count: 20,
      supplierIds: filters.supplierIds,
      traderIds: filters.traderIds,
      // Backend expects date time
      startDate: filters.startDate
        ? moment(filters.startDate)
            .startOf('day')
            .toISOString()
        : undefined,
      endDate: filters.endDate
        ? moment(filters.endDate)
            .endOf('day')
            .toISOString()
        : undefined,
    };

    return (
      <TradeReloadContext.Provider value={this.tradeReload}>
        <div>
          {modalOpen !== null && (
            <Modal
              modal={modalOpen}
              modalProps={modalProps}
              openModal={this.openModal}
            />
          )}
          <TableSelector
            select={this.setTable}
            selected={selectedTable}
            openModal={this.openModal}
            environment={this.context as any}
            handleTradeExport={this.handleTradeExport}
          />
          <TradeFilter
            supplierIds={filters.supplierIds}
            traderIds={filters.traderIds}
            startDate={filters.startDate}
            endDate={filters.endDate}
            onSupplierIdsChange={(newIds) => {
              this.updateFilters({supplierIds: newIds});
            }}
            onTraderIdsChange={(newIds) => {
              this.updateFilters({traderIds: newIds});
            }}
            onStartDateChange={(startDate) => {
              this.updateFilters({startDate});
            }}
            onEndDateChange={(endDate) => {
              this.updateFilters({endDate});
            }}
          />
          <Paper className={modalOpen !== null ? classNames(root, blur) : root}>
            <TableFilterHandler
              selectedTable={selectedTable}
              openModal={this.openModal}
              filters={graphqlFilters}
            />
          </Paper>
        </div>
        <TradesSubscriptions environment={this.context} filters={filters} />
      </TradeReloadContext.Provider>
    );
  }

  private handleTradeExport = async (env: Environment) => {
    const {
      filters: {endDate, startDate, supplierIds},
    } = this.state;

    const output = await TradeDownloadMutation(env, {
      input: {
        supplierIds,
        startDate,
        endDate,
      },
    });
    if (!!output && !!output.tradeDownload) {
      XLSX.writeFile(
        JSON.parse(output.tradeDownload.output),
        output.tradeDownload.filename
      );
    } else {
      throw {message: 'Unable to download file.'};
    }
  };

  private updateFilters(update: Partial<State['filters']>) {
    this.setState(({filters}) => ({
      filters: {
        ...filters,
        ...update,
      },
    }));
  }
}

export default withStyles(styles)(Trades);

import {graphql} from 'react-relay';

import {createMutation} from '@stackworx/relay';

import {
  tradeAdminCreateMutation_MutationResponse,
  tradeAdminCreateMutation_MutationVariables,
} from './__generated__/tradeAdminCreateMutation_Mutation.graphql';

const mutation = graphql`
  mutation tradeAdminCreateMutation_Mutation($input: TradeAdminCreateInput!) {
    tradeAdminCreate(input: $input) {
      clientMutationId
    }
  }
`;

export default createMutation<
  tradeAdminCreateMutation_MutationVariables,
  tradeAdminCreateMutation_MutationResponse
>(mutation);

import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = {
  container: {
    flex: 1,
    padding: 20,
  },
};

export default () => (
  <div style={styles.container}>
    <LinearProgress />
  </div>
);

import * as React from 'react';
import {withStyles, WithStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import styles from './ModalStyles';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

interface Props extends WithStyles<typeof styles> {
  tradeId?: string;
  onClose: () => void;
  error?: string;
}

const Clicked = (e: React.MouseEvent<HTMLElement>) => {
  e.stopPropagation();
};

function ErrorModal(props: Props) {
  const {classes, error} = props;
  return (
    <div
      className={classes.root}
      onClick={(e: React.MouseEvent<HTMLElement>) => Clicked(e)}
    >
      <Typography variant="h6" className={classes.title}>
        Error
        <span
          className={classes.exit}
          onClick={() => props.onClose && props.onClose()}
        >
          +
        </span>
      </Typography>
      {error && <div className={classes.info}>{error}</div>}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        style={{width: '100%', margin: '30px 0 10px'}}
        className={`${classes.button} ${classes.buttonDown}`}
        onClick={() => props.onClose && props.onClose()}
      >
        Continue
        <FontAwesomeIcon
          icon={faChevronRight}
          style={{fontSize: '0.875rem', paddingLeft: '10px'}}
        />
      </Button>
    </div>
  );
}

export default withStyles(styles)(ErrorModal);

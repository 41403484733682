/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type UserProfileUpdateInput = {
    readonly userId: string;
    readonly companyId: string;
    readonly email: string;
    readonly firstname: string;
    readonly lastname: string;
    readonly cellphone: string;
    readonly clientMutationId?: string | null;
};
export type UserProfileUpdateMutation_MutationVariables = {
    readonly input: UserProfileUpdateInput;
};
export type UserProfileUpdateMutation_MutationResponse = {
    readonly userProfileUpdate: ({
        readonly clientMutationId: string | null;
    }) | null;
};
export type UserProfileUpdateMutation_Mutation = {
    readonly response: UserProfileUpdateMutation_MutationResponse;
    readonly variables: UserProfileUpdateMutation_MutationVariables;
};



/*
mutation UserProfileUpdateMutation_Mutation(
  $input: UserProfileUpdateInput!
) {
  userProfileUpdate(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UserProfileUpdateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "userProfileUpdate",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UserProfileUpdateInput!"
      }
    ],
    "concreteType": "UserProfileUpdatePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UserProfileUpdateMutation_Mutation",
  "id": null,
  "text": "mutation UserProfileUpdateMutation_Mutation(\n  $input: UserProfileUpdateInput!\n) {\n  userProfileUpdate(input: $input) {\n    clientMutationId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UserProfileUpdateMutation_Mutation",
    "type": "Mutations",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "UserProfileUpdateMutation_Mutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
(node as any).hash = 'f3ac80ef17ffc18a9fe01cfcf958dbf8';
export default node;

/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
type TransactionsTradeRow_weightTransaction$ref = any;
export type TransactionsTableCreatedSubscriptionVariables = {};
export type TransactionsTableCreatedSubscriptionResponse = {
    readonly weightTransactionCreated: {
        readonly id: string;
        readonly createdAt: any;
        readonly supplier: {
            readonly id: string;
        };
        readonly " $fragmentRefs": TransactionsTradeRow_weightTransaction$ref;
    };
};
export type TransactionsTableCreatedSubscription = {
    readonly response: TransactionsTableCreatedSubscriptionResponse;
    readonly variables: TransactionsTableCreatedSubscriptionVariables;
};



/*
subscription TransactionsTableCreatedSubscription {
  weightTransactionCreated {
    id
    createdAt
    supplier {
      id
    }
    ...TransactionsTradeRow_weightTransaction
  }
}

fragment TransactionsTradeRow_weightTransaction on WeightTransaction {
  id
  weight
  goldPercentage
  type
  rollingBalance
  notes
  pcCode
  reversed
  supplier {
    id
    info {
      name
    }
  }
  createdAt
  createdBy {
    id
    displayName
  }
  transactionTimestamp
  trade {
    id
    refNumber
    tradeTimestamp
    status
    weight
    requestedZarPerGram
    notes
    createdBy {
      id
      displayName
    }
    confirmation {
      eventTimestamp
      usdPerTroyOunce
      zarToUsd
      zarPerGram
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "weight",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "notes",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "createdBy",
  "storageKey": null,
  "args": null,
  "concreteType": "AuditUser",
  "plural": false,
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "displayName",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "subscription",
  "name": "TransactionsTableCreatedSubscription",
  "id": null,
  "text": "subscription TransactionsTableCreatedSubscription {\n  weightTransactionCreated {\n    id\n    createdAt\n    supplier {\n      id\n    }\n    ...TransactionsTradeRow_weightTransaction\n  }\n}\n\nfragment TransactionsTradeRow_weightTransaction on WeightTransaction {\n  id\n  weight\n  goldPercentage\n  type\n  rollingBalance\n  notes\n  pcCode\n  reversed\n  supplier {\n    id\n    info {\n      name\n    }\n  }\n  createdAt\n  createdBy {\n    id\n    displayName\n  }\n  transactionTimestamp\n  trade {\n    id\n    refNumber\n    tradeTimestamp\n    status\n    weight\n    requestedZarPerGram\n    notes\n    createdBy {\n      id\n      displayName\n    }\n    confirmation {\n      eventTimestamp\n      usdPerTroyOunce\n      zarToUsd\n      zarPerGram\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TransactionsTableCreatedSubscription",
    "type": "Subscription",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "weightTransactionCreated",
        "storageKey": null,
        "args": null,
        "concreteType": "WeightTransaction",
        "plural": false,
        "selections": [
          v0,
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "supplier",
            "storageKey": null,
            "args": null,
            "concreteType": "Company",
            "plural": false,
            "selections": [
              v0
            ]
          },
          {
            "kind": "FragmentSpread",
            "name": "TransactionsTradeRow_weightTransaction",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TransactionsTableCreatedSubscription",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "weightTransactionCreated",
        "storageKey": null,
        "args": null,
        "concreteType": "WeightTransaction",
        "plural": false,
        "selections": [
          v0,
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "supplier",
            "storageKey": null,
            "args": null,
            "concreteType": "Company",
            "plural": false,
            "selections": [
              v0,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "info",
                "storageKey": null,
                "args": null,
                "concreteType": "CompanyInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "goldPercentage",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "type",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "rollingBalance",
            "args": null,
            "storageKey": null
          },
          v3,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "pcCode",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "reversed",
            "args": null,
            "storageKey": null
          },
          v4,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "transactionTimestamp",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "trade",
            "storageKey": null,
            "args": null,
            "concreteType": "Trade",
            "plural": false,
            "selections": [
              v0,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "refNumber",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "tradeTimestamp",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "status",
                "args": null,
                "storageKey": null
              },
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "requestedZarPerGram",
                "args": null,
                "storageKey": null
              },
              v3,
              v4,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "confirmation",
                "storageKey": null,
                "args": null,
                "concreteType": "LatestTradeConfirmation",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "eventTimestamp",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "usdPerTroyOunce",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "zarToUsd",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "zarPerGram",
                    "args": null,
                    "storageKey": null
                  },
                  v0
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
(node as any).hash = '8ae8fb1e97e840bb8984f9f53fc6fcad';
export default node;

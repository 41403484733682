import React from 'react';
import {withStyles, WithStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {MenuItem} from '@material-ui/core';
import {TextField} from 'formik-material-ui';
import * as Yup from 'yup';
import {graphql, createFragmentContainer} from 'react-relay';
import moment from 'moment';
import Spinner from 'react-spinkit';

import {Formik, Form, Field, FieldProps} from 'formik';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';

import TextArea from './TextArea';
import styles from './ModalStyles';
import {createRelayRenderer, EnvironmentProp} from '../../RelayRenderer';
import {NewTradeModal_viewer} from './__generated__/NewTradeModal_viewer.graphql';
import tradeAdminCreateMutation from './mutations/tradeAdminCreateMutation';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import NumberCounter from './NumberCounter';

interface Props extends WithStyles<typeof styles>, EnvironmentProp {
  onClose: () => void;
  viewer: NewTradeModal_viewer;
}

const Clicked = (e: React.MouseEvent<HTMLElement>) => {
  e.stopPropagation();
};

interface Values {
  newTrade: {
    companyId: string;
    weight: number;
    usdPerTroyOunce: number;
    zarToUsd: number;
    date: string;
    time: string;
    notes: string;
    checked?: boolean;
  };
}

function NewTradeModal({onClose, viewer, classes, environment}: Props) {
  const schema = Yup.object().shape({
    newTrade: Yup.object()
      .shape({
        weight: Yup.number()
          .required('Required')
          .notOneOf([0], 'Please enter non-zero value')
          .max(999999, 'Trades this large are not allowed')
          .typeError('Valid number required'),
        companyId: Yup.string().required('Required'),
        usdPerTroyOunce: Yup.number()
          .required('Required')
          .notOneOf([0], 'Please enter non-zero value')
          .max(999999, 'Number too large')
          .typeError('Valid number required'),
        zarToUsd: Yup.number()
          .required('Required')
          .notOneOf([0], 'Please enter non-zero value')
          .max(999999, 'Number too large')
          .typeError('Valid number required'),
        date: Yup.string()
          .required('Required')
          .max(20, 'Value is too long'),
        time: Yup.string()
          .required('Required')
          .max(20, 'Value is too long'),
        checked: Yup.boolean(),
      })
      .test(
        'tradeLimitExceededTest',
        'Please confirm trade limit excess',

        (values) => {
          if (values.weight !== 0 && values.companyId !== '') {
            const {companies} = viewer;
            const selectedCompany = companies
              ? companies.edges.find(
                  (companyNode) => companyNode.node.id === values.companyId
                )
              : null;

            if (selectedCompany) {
              const {runningBalance, tradeLimit} = selectedCompany.node;

              // even though type is number, can't do addition without multiplying with one for some reason
              const limitCheck =
                -runningBalance * 1 + values.weight * 1 > tradeLimit.limit * 1;

              if (limitCheck) {
                if (values.checked) {
                  return true;
                }
                return false;
              } else {
                return true;
              }
            } else {
              return false;
            }
          } else {
            return false;
          }
        }
      ),
  });

  function renderTradeLimitExceededConfirmation(
    companyId: string,
    requestedWeight: number
  ) {
    const {companies} = viewer;
    const selectedCompany = companies
      ? companies.edges.find((companyNode) => companyNode.node.id === companyId)
      : null;

    if (selectedCompany) {
      const {runningBalance, tradeLimit} = selectedCompany.node;

      // even though type is number, can't do addition without multiplying with one
      const renderCheckbox =
        -runningBalance * 1 + requestedWeight * 1 > tradeLimit.limit * 1;

      return renderCheckbox === true ? (
        <div
          style={{
            marginTop: '25px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Field name="newTrade.checked">
            {({
              form: {submitCount, errors, setFieldValue},
              field,
            }: FieldProps) => {
              return (
                <>
                  <Checkbox
                    checked={field.value}
                    onChange={(_event) => {
                      setFieldValue(field.name, !field.value);
                    }}
                  />
                  <Typography
                    style={{
                      color:
                        submitCount > 0 &&
                        errors &&
                        errors.newTrade &&
                        typeof errors === 'object' &&
                        typeof errors.newTrade === 'string'
                          ? 'red'
                          : 'black',
                    }}
                  >
                    {`I am aware that confirming this trade will exceed the company debit limit by ${-runningBalance *
                      1 +
                      requestedWeight * 1 -
                      tradeLimit.limit * 1}g`}
                  </Typography>
                </>
              );
            }}
          </Field>
        </div>
      ) : null;
    }
  }

  return (
    <div
      className={classes.root}
      onClick={(e: React.MouseEvent<HTMLElement>) => Clicked(e)}
    >
      <Typography variant="h6" className={classes.title}>
        Au Trade Request
        <span className={classes.exit} onClick={() => onClose()}>
          +
        </span>
      </Typography>
      <Formik<Values>
        onSubmit={async (values, {setSubmitting, setStatus}) => {
          setSubmitting(true);

          try {
            await tradeAdminCreateMutation(environment, {
              input: {
                companyId: values.newTrade.companyId,
                weight: values.newTrade.weight,
                zarPerTroyOunce:
                  values.newTrade.usdPerTroyOunce * values.newTrade.zarToUsd,
                zarToUsd: values.newTrade.zarToUsd,
                notes: values.newTrade.notes,
                timestamp: moment(
                  `${values.newTrade.date} ${values.newTrade.time}`
                ),
              },
            });
            setSubmitting(false);

            onClose();
          } catch (ex) {
            setStatus(ex.message);
            setSubmitting(false);
            if (ex.message === 'Insufficient Trade Balance Available.') {
              setStatus([
                `Overall company limit will be exceeded.`,
                <br />,
                `Either wait for other trades to be delivered, or increase company limit. `,
              ]);
            }
          }
        }}
        validationSchema={schema}
        initialValues={{
          newTrade: {
            weight: 0,
            usdPerTroyOunce: viewer.currentTradePrice
              ? viewer.currentTradePrice.usdPerTroyOunce
              : 0,
            companyId: '',
            notes: '',
            zarToUsd: viewer.currentTradePrice
              ? viewer.currentTradePrice.zarToUsd
              : 0,
            date: moment().format('YYYY-MM-DD'),
            time: moment().format('HH:mm'),
            checked: false,
          },
        }}
        render={({handleSubmit, isSubmitting, status, values}) => (
          <Form>
            <div style={{position: 'relative'}}>
              <Field
                InputLabelProps={{shrink: true}}
                name="newTrade.weight"
                label="Weight (g)"
                type="number"
                component={TextField}
                style={{width: '100%'}}
              />
              <span className={classes.gram}>g</span>
            </div>
            <Field
              InputLabelProps={{shrink: true}}
              name="newTrade.companyId"
              label="Trading Company"
              type="text"
              component={TextField}
              select
              style={{width: '100%', margin: '10px 0 0'}}
            >
              {viewer!.companies!.edges.map(({node}) => (
                <MenuItem key={node.id} value={node.id}>
                  {node.info.name}
                </MenuItem>
              ))}
            </Field>
            <br />
            <div
              className={classNames(classes.textHalf, classes.first)}
              style={{position: 'relative'}}
            >
              <Field
                InputLabelProps={{shrink: true}}
                name="newTrade.usdPerTroyOunce"
                label="USD/oz"
                type="text"
                component={TextField}
                style={{width: '100%'}}
              />
              <span className={classes.gram}>USD/oz</span>
            </div>
            <div className={classes.textHalf} style={{position: 'relative'}}>
              <Field
                InputLabelProps={{shrink: true}}
                name="newTrade.zarToUsd"
                label="ZAR/USD"
                type="text"
                component={TextField}
                style={{width: '100%'}}
              />
              <span className={classes.gram}>ZAR/USD</span>
            </div>
            <Field
              InputLabelProps={{shrink: true}}
              type="date"
              label="Date"
              name="newTrade.date"
              component={TextField}
              className={classNames(classes.textHalf, classes.first)}
            />
            <Field
              InputLabelProps={{shrink: true}}
              name="newTrade.time"
              label="Time"
              type="time"
              component={TextField}
              className={classes.textHalf}
            />
            <br />
            <Field
              name="newTrade.notes"
              label="Notes"
              type="text"
              component={TextArea}
            />
            <NumberCounter current={values.newTrade.notes.length} max={200} />
            {values.newTrade.weight && values.newTrade.companyId
              ? renderTradeLimitExceededConfirmation(
                  values.newTrade.companyId,
                  values.newTrade.weight
                )
              : null}

            <br />
            <Button
              variant="contained"
              color="primary"
              style={{width: '100%', margin: '10px 0 10px'}}
              className={classes.button}
              onClick={() => handleSubmit()}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Spinner
                  name="circle"
                  color="white"
                  // @ts-ignore
                  style={{margin: '0 auto'}}
                />
              ) : (
                'Add Trade'
              )}
              <FontAwesomeIcon
                icon={faChevronRight}
                style={{fontSize: '0.875rem', paddingLeft: '10px'}}
              />
            </Button>
            {status && (
              <Typography className={classes.error}>{status}</Typography>
            )}
          </Form>
        )}
      />
    </div>
  );
}

const query = graphql`
  query NewTradeModalQuery {
    viewer {
      ...NewTradeModal_viewer
    }
  }
`;

export default withStyles(styles)(
  createRelayRenderer({
    container: createFragmentContainer(NewTradeModal, {
      viewer: graphql`
        fragment NewTradeModal_viewer on Viewer {
          currentTradePrice {
            zarPerTroyOunce
            usdPerTroyOunce
            zarToUsd
          }
          companies {
            edges {
              node {
                runningBalance
                tradeLimit {
                  limit
                }
                id
                info {
                  name
                }
              }
            }
          }
        }
      `,
    }),
    query,
    variables: {
      count: 20,
    },
  })
);

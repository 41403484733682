/* tslint:disable */

import { ConcreteFragment } from "relay-runtime";
type PendingTradeRow_trade$ref = any;
export type PendingTradesTable_viewer$ref = any;
export type PendingTradesTable_viewer = {
    readonly trades: ({
        readonly total: number | null;
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": PendingTradeRow_trade$ref;
            };
        }>;
    }) | null;
    readonly " $refType": PendingTradesTable_viewer$ref;
};



const node: ConcreteFragment = {
  "kind": "Fragment",
  "name": "PendingTradesTable_viewer",
  "type": "Viewer",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "trades"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "count",
      "type": "Int",
      "defaultValue": 10
    },
    {
      "kind": "LocalArgument",
      "name": "cursor",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "supplierIds",
      "type": "[ID!]",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "traderIds",
      "type": "[ID!]",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "startDate",
      "type": "DateTime",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "endDate",
      "type": "DateTime",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "trades",
      "name": "__ViewerPending_trades_connection",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "supplierIds",
          "variableName": "supplierIds",
          "type": "[ID!]"
        }
      ],
      "concreteType": "ViewerTradesConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "total",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ViewerTradesEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Trade",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "PendingTradeRow_trade",
                  "args": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
(node as any).hash = 'd3eb13233e5aaf38bdfd511cfc44e43a';
export default node;

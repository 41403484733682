import {graphql} from 'react-relay';

import {createMutation} from '@stackworx/relay';

import {
  RejectTradeMutation_MutationResponse,
  RejectTradeMutation_MutationVariables,
} from './__generated__/RejectTradeMutation_Mutation.graphql';
import {ConnectionHandler} from 'relay-runtime';

const mutation = graphql`
  mutation RejectTradeMutation_Mutation($input: TradeRejectInput!) {
    tradeReject(input: $input) {
      trade {
        id
        rejection {
          createdBy {
            id
          }
          createdAt
          reason
        }
      }
    }
  }
`;

export default createMutation<
  RejectTradeMutation_MutationVariables,
  RejectTradeMutation_MutationResponse
>(mutation, {
  updater: (store, data: any) => {
    if (data.tradeReject && data.tradeReject.trade) {
      const tradeId = data.tradeReject.trade.id;

      const viewer = store.get('client:root:viewer');

      if (viewer) {
        const conn = ConnectionHandler.getConnection(
          viewer,
          'ViewerPending_trades'
        );

        if (conn) {
          ConnectionHandler.deleteNode(conn, tradeId);
        }
      }
    }
  },
});

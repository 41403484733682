import React from 'react';
import isEqual from 'react-fast-compare';

import TransactionsTable from './TransactionsTable';
import DeclinedTable from './DeclinedTable';
import PendingTable from './PendingTradesTable';
import {Tables, Filters} from '../types';

interface Props {
  selectedTable: Tables;
  openModal: any;
  filters: Filters;
}

interface State {
  filters: Filters;
}

/**
 * This component intercepts filter changes and decides if we need a full re-render
 * (QueryRenderer re-fetch) or if we can delegate to the component to do a re-render itself
 *
 * The reason for this is that any new variables will cause a full re-render
 */
export default class TableFilterHandler extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      filters: props.filters,
    };
  }

  public componentWillReceiveProps(nextProps: Props) {
    if (nextProps.selectedTable !== this.props.selectedTable) {
      // Table switch, force re-render
      this.setState({
        filters: nextProps.filters,
      });
      return;
    }
  }

  public render() {
    const {openModal, selectedTable} = this.props;

    // Cache the filters so we can control updates
    const {filters} = this.state;

    return (
      <>
        {selectedTable === 'PendingTrades' && (
          // @ts-ignore
          <PendingTable
            openModal={openModal}
            filters={this.props.filters}
            // @ts-ignore
            variables={filters}
          />
        )}
        {selectedTable === 'Transactions' && (
          // @ts-ignore
          <TransactionsTable
            openModal={openModal}
            filters={this.props.filters}
            // @ts-ignore
            variables={filters}
          />
        )}
        {selectedTable === 'Declined' && (
          // @ts-ignore
          <DeclinedTable
            openModal={openModal}
            filters={this.props.filters}
            // @ts-ignore
            variables={filters}
          />
        )}
      </>
    );
  }
}

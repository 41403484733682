import * as React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import styles from '../../components/TableStyles';
import InputInTable from '../../components/InputInTable';
import {WithStyles, withStyles} from '@material-ui/core/styles';
import {graphql, createFragmentContainer} from 'react-relay';
import {DeclinedTradeRow_trade} from './__generated__/DeclinedTradeRow_trade.graphql';
import Moment from 'react-moment';
import ReactTooltip from 'react-tooltip';

import {NumberFormat} from '../../components/NumberFormat';

interface Props extends WithStyles<typeof styles> {
  trade: DeclinedTradeRow_trade;
  openModal: (modal: null | 'text', modalprops?: {}) => void;
}

const DeclinedTradeRow = (props: Props) => {
  const {classes, trade, openModal} = props;
  return (
    <TableRow key={trade.id} className={classes.row}>
      <TableCell scope="row">
        <Moment format="YYYY-MM-DD" date={trade.tradeTimestamp} />
        <br />
        <Moment format="HH:mm:ss" date={trade.tradeTimestamp} />
      </TableCell>
      <TableCell>
        {trade.rejection ? (
          <span>
            <Moment format="YYYY-MM-DD" date={trade.rejection.eventTimestamp} />
            <br />
            <Moment format="HH:mm:ss" date={trade.rejection.eventTimestamp} />
          </span>
        ) : (
          <span>N/A</span>
        )}
      </TableCell>
      <TableCell
        className={classes.tradeCell}
        data-tip={trade.rejection && trade.rejection.reason}
        onClick={() => {
          openModal('text', {
            title: `Notes on trade ${trade.id.replace('Trade:', '#')}`,
            content: trade.rejection && trade.rejection.reason,
          });
        }}
      >
        {trade.rejection &&
          (trade.rejection.reason.length > 40
            ? `${trade.rejection.reason.slice(0, 40)} ...`
            : trade.rejection.reason)}
        <ReactTooltip className={classes.tooltip} />
      </TableCell>
      <TableCell>{trade.company.info.name}</TableCell>
      <TableCell>{trade.createdBy.displayName}</TableCell>
      <TableCell>{NumberFormat(trade.weight)}</TableCell>
      <TableCell>
        R{NumberFormat(trade.requestedZarPerGram.toFixed(2))}
      </TableCell>
      <TableCell>
        <InputInTable
          placeholder={NumberFormat(trade.usdPerTroyOunce.toFixed(2))}
          disabled={true}
        />
      </TableCell>
      <TableCell>
        <InputInTable
          placeholder={NumberFormat(trade.zarToUsd)}
          disabled={true}
        />
      </TableCell>
      <TableCell>R{NumberFormat(trade.zarPerGram.toFixed(2))}</TableCell>
    </TableRow>
  );
};

export default createFragmentContainer(withStyles(styles)(DeclinedTradeRow), {
  trade: graphql`
    fragment DeclinedTradeRow_trade on Trade {
      id
      tradeTimestamp
      status
      weight
      company {
        id
        info {
          name
        }
      }
      createdBy {
        id
        displayName
      }
      usdPerTroyOunce
      zarToUsd
      zarPerGram
      requestedZarPerGram
      rejection {
        eventTimestamp
        reason
      }
    }
  `,
});

/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type TradePriceSubscriptionVariables = {};
export type TradePriceSubscriptionResponse = {
    readonly currentTradePrice: {
        readonly id: string;
        readonly zarPerTroyOunce: number;
        readonly zarToUsd: number;
        readonly zarPerGram: number;
        readonly usdPerTroyOunce: number;
        readonly lastUpdated: any;
    };
};
export type TradePriceSubscription = {
    readonly response: TradePriceSubscriptionResponse;
    readonly variables: TradePriceSubscriptionVariables;
};



/*
subscription TradePriceSubscription {
  currentTradePrice {
    id
    zarPerTroyOunce
    zarToUsd
    zarPerGram
    usdPerTroyOunce
    lastUpdated
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "currentTradePrice",
    "storageKey": null,
    "args": null,
    "concreteType": "TradePrice",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "zarPerTroyOunce",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "zarToUsd",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "zarPerGram",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "usdPerTroyOunce",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "lastUpdated",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "subscription",
  "name": "TradePriceSubscription",
  "id": null,
  "text": "subscription TradePriceSubscription {\n  currentTradePrice {\n    id\n    zarPerTroyOunce\n    zarToUsd\n    zarPerGram\n    usdPerTroyOunce\n    lastUpdated\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TradePriceSubscription",
    "type": "Subscription",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": v0
  },
  "operation": {
    "kind": "Operation",
    "name": "TradePriceSubscription",
    "argumentDefinitions": [],
    "selections": v0
  }
};
})();
(node as any).hash = 'a6a5e5659c6e2da8c143a9edb54503cc';
export default node;

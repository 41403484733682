/* tslint:disable */

import { ConcreteFragment } from "relay-runtime";
export type TradeStatus = "confirmed" | "delivered" | "pending" | "rejected" | "%future added value";
export type DeclinedTradeRow_trade$ref = any;
export type DeclinedTradeRow_trade = {
    readonly id: string;
    readonly tradeTimestamp: any;
    readonly status: TradeStatus;
    readonly weight: number;
    readonly company: {
        readonly id: string;
        readonly info: {
            readonly name: string;
        };
    };
    readonly createdBy: {
        readonly id: string;
        readonly displayName: string | null;
    };
    readonly usdPerTroyOunce: number;
    readonly zarToUsd: number;
    readonly zarPerGram: number;
    readonly requestedZarPerGram: number;
    readonly rejection: ({
        readonly eventTimestamp: any;
        readonly reason: string;
    }) | null;
    readonly " $refType": DeclinedTradeRow_trade$ref;
};



const node: ConcreteFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "DeclinedTradeRow_trade",
  "type": "Trade",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "tradeTimestamp",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "weight",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "company",
      "storageKey": null,
      "args": null,
      "concreteType": "Company",
      "plural": false,
      "selections": [
        v0,
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "info",
          "storageKey": null,
          "args": null,
          "concreteType": "CompanyInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "name",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "createdBy",
      "storageKey": null,
      "args": null,
      "concreteType": "AuditUser",
      "plural": false,
      "selections": [
        v0,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "displayName",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "usdPerTroyOunce",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "zarToUsd",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "zarPerGram",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "requestedZarPerGram",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "rejection",
      "storageKey": null,
      "args": null,
      "concreteType": "LatestTradeRejection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "eventTimestamp",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "reason",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
(node as any).hash = '7233a4df73803db8159e7c88473c49f2';
export default node;

/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
type Suppliers_viewer$ref = any;
export type SuppliersQueryVariables = {};
export type SuppliersQueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": Suppliers_viewer$ref;
    };
};
export type SuppliersQuery = {
    readonly response: SuppliersQueryResponse;
    readonly variables: SuppliersQueryVariables;
};



/*
query SuppliersQuery {
  viewer {
    ...Suppliers_viewer
  }
}

fragment Suppliers_viewer on Viewer {
  companies(active: true) {
    edges {
      node {
        id
        info {
          name
          vatNumber
          registrationNumber
          contactNumber
          emailAddress
          sharepointIdentifier
        }
        primaryLicense {
          type
          number
          expiry
        }
        secondaryLicense {
          type
          number
          expiry
        }
        tradeLimit {
          limit
        }
        tradeBalance {
          available
          pending
        }
        blocked
        users(active: true) {
          edges {
            node {
              id
              username
              profile {
                firstname
                lastname
                email
                cellphone
              }
              blocked
            }
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "active",
    "value": true,
    "type": "Boolean"
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "type",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "number",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "expiry",
    "args": null,
    "storageKey": null
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "blocked",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "SuppliersQuery",
  "id": null,
  "text": "query SuppliersQuery {\n  viewer {\n    ...Suppliers_viewer\n  }\n}\n\nfragment Suppliers_viewer on Viewer {\n  companies(active: true) {\n    edges {\n      node {\n        id\n        info {\n          name\n          vatNumber\n          registrationNumber\n          contactNumber\n          emailAddress\n          sharepointIdentifier\n        }\n        primaryLicense {\n          type\n          number\n          expiry\n        }\n        secondaryLicense {\n          type\n          number\n          expiry\n        }\n        tradeLimit {\n          limit\n        }\n        tradeBalance {\n          available\n          pending\n        }\n        blocked\n        users(active: true) {\n          edges {\n            node {\n              id\n              username\n              profile {\n                firstname\n                lastname\n                email\n                cellphone\n              }\n              blocked\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SuppliersQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "viewer",
        "name": "__viewer_viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "Suppliers_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SuppliersQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "companies",
            "storageKey": "companies(active:true)",
            "args": v0,
            "concreteType": "ViewerCompaniesConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ViewerCompaniesEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Company",
                    "plural": false,
                    "selections": [
                      v1,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "info",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CompanyInfo",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "name",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "vatNumber",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "registrationNumber",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "contactNumber",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "emailAddress",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "sharepointIdentifier",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "primaryLicense",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CompanyPrimaryLicense",
                        "plural": false,
                        "selections": v2
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "secondaryLicense",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CompanySecondaryLicense",
                        "plural": false,
                        "selections": v2
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "tradeLimit",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CompanyTradeLimit",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "limit",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "tradeBalance",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CompanyTradeBalance",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "available",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "pending",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      v3,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "users",
                        "storageKey": "users(active:true)",
                        "args": v0,
                        "concreteType": "CompanyUsersConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CompanyUsersEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "User",
                                "plural": false,
                                "selections": [
                                  v1,
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "username",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "profile",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "UserProfile",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "firstname",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "lastname",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "email",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "cellphone",
                                        "args": null,
                                        "storageKey": null
                                      }
                                    ]
                                  },
                                  v3
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "viewer",
        "args": null,
        "handle": "viewer",
        "key": "",
        "filters": null
      }
    ]
  }
};
})();
(node as any).hash = '4bddece7b345fe9a134c29ba6f87dd0e';
export default node;

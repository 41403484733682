import {graphql} from 'react-relay';

import {createMutation} from '@stackworx/relay';

import {
  UserBlockMutation_MutationResponse,
  UserBlockMutation_MutationVariables,
} from './__generated__/UserBlockMutation_Mutation.graphql';

const mutation = graphql`
  mutation UserBlockMutation_Mutation($input: UserBlockInput!) {
    userBlock(input: $input) {
      clientMutationId
    }
  }
`;

export default createMutation<
  UserBlockMutation_MutationVariables,
  UserBlockMutation_MutationResponse
>(mutation);

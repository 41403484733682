import * as React from 'react';
import {withStyles, WithStyles} from '@material-ui/core/styles';
import styles from '../../TableStyles';
import classNames from 'classnames';

interface Props extends WithStyles<typeof styles> {
  current: number;
  max: number;
}

function NumberDisplay(props: Props) {
  const {max, current, classes} = props;
  return (
    <div
      className={classNames(
        classes.wordCounter,
        current > max
          ? classes.wordCounterNegative
          : classes.wordCounterPositive
      )}
    >
      {max - current}
    </div>
  );
}

export default withStyles(styles)(NumberDisplay);

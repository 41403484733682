/* tslint:disable */

import { ConcreteFragment } from "relay-runtime";
export type Suppliers_viewer$ref = any;
export type Suppliers_viewer = {
    readonly companies: ({
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly info: {
                    readonly name: string;
                    readonly vatNumber: string;
                    readonly registrationNumber: string;
                    readonly contactNumber: string;
                    readonly emailAddress: string;
                    readonly sharepointIdentifier: string | null;
                };
                readonly primaryLicense: ({
                    readonly type: string;
                    readonly number: string;
                    readonly expiry: any;
                }) | null;
                readonly secondaryLicense: ({
                    readonly type: string;
                    readonly number: string;
                    readonly expiry: any;
                }) | null;
                readonly tradeLimit: {
                    readonly limit: number;
                };
                readonly tradeBalance: {
                    readonly available: number;
                    readonly pending: number;
                };
                readonly blocked: boolean | null;
                readonly users: ({
                    readonly edges: ReadonlyArray<{
                        readonly node: {
                            readonly id: string;
                            readonly username: string;
                            readonly profile: ({
                                readonly firstname: string;
                                readonly lastname: string;
                                readonly email: string;
                                readonly cellphone: string;
                            }) | null;
                            readonly blocked: boolean | null;
                        };
                    }>;
                }) | null;
            };
        }>;
    }) | null;
    readonly " $refType": Suppliers_viewer$ref;
};



const node: ConcreteFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "active",
    "value": true,
    "type": "Boolean"
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "type",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "number",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "expiry",
    "args": null,
    "storageKey": null
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "blocked",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "Suppliers_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "companies",
      "storageKey": "companies(active:true)",
      "args": v0,
      "concreteType": "ViewerCompaniesConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ViewerCompaniesEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Company",
              "plural": false,
              "selections": [
                v1,
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "info",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CompanyInfo",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "name",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "vatNumber",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "registrationNumber",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "contactNumber",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "emailAddress",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "sharepointIdentifier",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "primaryLicense",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CompanyPrimaryLicense",
                  "plural": false,
                  "selections": v2
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "secondaryLicense",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CompanySecondaryLicense",
                  "plural": false,
                  "selections": v2
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "tradeLimit",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CompanyTradeLimit",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "limit",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "tradeBalance",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CompanyTradeBalance",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "available",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "pending",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                v3,
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "users",
                  "storageKey": "users(active:true)",
                  "args": v0,
                  "concreteType": "CompanyUsersConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CompanyUsersEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "User",
                          "plural": false,
                          "selections": [
                            v1,
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "username",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "profile",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "UserProfile",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "firstname",
                                  "args": null,
                                  "storageKey": null
                                },
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "lastname",
                                  "args": null,
                                  "storageKey": null
                                },
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "email",
                                  "args": null,
                                  "storageKey": null
                                },
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "cellphone",
                                  "args": null,
                                  "storageKey": null
                                }
                              ]
                            },
                            v3
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
(node as any).hash = '7fef21fa73ebc12f8565737751fceac1';
export default node;

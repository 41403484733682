import * as React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  RouteComponentProps,
  RouteProps,
  Switch,
} from 'react-router-dom';
import {LoginService, ConfigService} from '@stackworx/react';
import {MuiThemeProvider} from '@material-ui/core/styles';
import bugsnagReact from '@bugsnag/plugin-react';

import bugsnag from './bugsnag';
import theme from './styles/theme';

import './App.css';

import Root from './modules/Root/Root';
import Trades from './modules/Trades/Trades';
import Suppliers from './modules/Suppliers/Suppliers';
import Login from './modules/Login/Login';
import NotFound from './modules/NotFound/NotFound';
import {TradePriceSubscription} from './TradePriceSubscription';

bugsnag.use(bugsnagReact, React);
const ErrorBoundary = bugsnag.getPlugin('react');

import {
  AuthContext,
  AuthContextType,
  RelayContext,
  RelayContextType,
} from './contexts';
import createRelayEnv from './CreateRelayEnv';

const loginService = new LoginService(ConfigService.serverUri);

function PrivateRoute({
  // tslint:disable-next-line
  component: Component,
  ...rest
}: RouteProps) {
  return (
    <AuthContext.Consumer>
      {(auth) => (
        <Route
          {...rest}
          render={(props) =>
            auth.authenticated ? (
              // @ts-ignore
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: '/login',
                  state: {from: props.location},
                }}
              />
            )
          }
        />
      )}
    </AuthContext.Consumer>
  );
}

interface State {
  auth: AuthContextType;
  loading: boolean;
  environment?: RelayContextType;
}

export default class App extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      auth: {authenticated: false},
      loading: true,
    };
  }

  public componentDidMount() {
    // TODO: yup schema check auth
    const authString = localStorage.getItem('auth');

    if (authString) {
      const auth = JSON.parse(authString);

      if (auth) {
        this.setState({
          auth,
          loading: false,
          environment: this.createEnvironment(),
        });
        return;
      }
    }

    this.setState({loading: false, environment: this.createEnvironment()});
  }

  public handleLogout = () => {
    delete bugsnag.user;
    localStorage.removeItem('auth');
    this.setState({
      auth: {authenticated: false},
      environment: this.createEnvironment(),
    });
  };

  public handleSubmit = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<void> => {
    const {access_token} = await loginService.login(email, password);

    bugsnag.user = {username: email, email};

    const auth: AuthContextType = {
      authenticated: true,
      token: access_token,
      username: email,
    };
    this.setState(
      {
        auth,
        environment: this.createEnvironment(),
      },
      () => {
        localStorage.setItem('auth', JSON.stringify(auth));
      }
    );
  };

  public render() {
    const {auth, loading, environment} = this.state;

    if (loading || !environment) {
      return <div>Loading...</div>;
    }

    return (
      <ErrorBoundary>
        <MuiThemeProvider theme={theme}>
          <AuthContext.Provider value={auth}>
            <RelayContext.Provider value={environment}>
              <Router>
                <div>
                  <Root handleLogout={this.handleLogout}>
                    <Switch>
                      <PrivateRoute path="/" exact component={Trades} />
                      <PrivateRoute
                        path="/suppliers"
                        exact
                        component={Suppliers}
                      />
                      <Route
                        path="/login/"
                        extact
                        component={(props: RouteComponentProps) => (
                          <Login {...props} handleSubmit={this.handleSubmit} />
                        )}
                      />
                      <Route component={NotFound} />
                    </Switch>
                  </Root>
                  {auth.authenticated && <TradePriceSubscription />}
                </div>
              </Router>
            </RelayContext.Provider>
          </AuthContext.Provider>
        </MuiThemeProvider>
      </ErrorBoundary>
    );
  }

  private createEnvironment() {
    return createRelayEnv(this.handleLogout, () => {
      const {auth} = this.state;

      if (auth.authenticated) {
        return auth.token;
      } else {
        return '';
      }
    });
  }
}

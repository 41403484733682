import React from 'react';
import {QueryRenderer} from 'react-relay';
import {GraphQLTaggedNode, Variables, Environment} from 'relay-runtime';

import Error from './Error';
import Loading from './Loading';
import {RelayContext} from '../../contexts';

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

interface Props<T> {
  container: React.ComponentType<T>;
  // tslint:disable-next-line no-any
  renderLoading?: () => React.ReactElement<any>;
  variables?: Variables;
  query: GraphQLTaggedNode;
  // Pass through props
  // tslint:disable-next-line no-any
  [key: string]: any;
}

export interface EnvironmentProp {
  environment: Environment;
}

export default function RelayRenderer<T extends EnvironmentProp>({
  query,
  container: Container,
  renderLoading,
  variables,
  extraVariables,
  ...moduleProps
}: Props<T>) {
  const combinedVariables = {
    ...variables,
    ...extraVariables,
    ...(moduleProps.match ? moduleProps.match.params : null),
  };
  return (
    <RelayContext.Consumer>
      {(environment: Environment) => (
        <QueryRenderer
          environment={environment}
          query={query}
          variables={combinedVariables}
          render={({error, props, retry}) => {
            if (error) {
              return <Error error={error} retry={retry} />;
            } else if (props) {
              return (
                // @ts-ignore
                <Container
                  {...moduleProps}
                  {...props}
                  environment={environment}
                />
              );
            }

            if (renderLoading) {
              return renderLoading();
            }

            return <Loading />;
          }}
        />
      )}
    </RelayContext.Consumer>
  );
}

interface RenderProps<T> {
  container: React.ComponentType<T>;
  query: GraphQLTaggedNode;
  variables?: Variables;
  extraVariables?: {};
}

export function createRelayRenderer<T extends EnvironmentProp>({
  container,
  query,
  variables,
  extraVariables,
}: RenderProps<T>): React.ComponentType<
  Omit<T, 'environment' | 'viewer' | 'relay'>
> {
  return (props: Omit<T, 'environment' | 'viewer' | 'relay'>) => (
    <RelayRenderer
      query={query}
      variables={variables}
      container={container}
      extraVariables={extraVariables}
      {...props}
    />
  );
}

import {graphql} from 'react-relay';

import {createMutation} from '@stackworx/relay';

import {
  CompanyUnblockMutation_MutationResponse,
  CompanyUnblockMutation_MutationVariables,
} from './__generated__/CompanyUnblockMutation_Mutation.graphql';

const mutation = graphql`
  mutation CompanyUnblockMutation_Mutation($input: CompanyUnblockInput!) {
    companyUnblock(input: $input) {
      clientMutationId
    }
  }
`;

export default createMutation<
  CompanyUnblockMutation_MutationVariables,
  CompanyUnblockMutation_MutationResponse
>(mutation);

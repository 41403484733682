import {graphql} from 'react-relay';

import {createMutation} from '@stackworx/relay';

import {
  UserUnblockMutation_MutationResponse,
  UserUnblockMutation_MutationVariables,
} from './__generated__/UserUnblockMutation_Mutation.graphql';

const mutation = graphql`
  mutation UserUnblockMutation_Mutation($input: UserUnblockInput!) {
    userUnblock(input: $input) {
      clientMutationId
    }
  }
`;

export default createMutation<
  UserUnblockMutation_MutationVariables,
  UserUnblockMutation_MutationResponse
>(mutation);

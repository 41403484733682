/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type TradeAdminCreateInput = {
    readonly companyId: string;
    readonly timestamp?: any | null;
    readonly weight: number;
    readonly zarPerTroyOunce: number;
    readonly zarToUsd: number;
    readonly notes?: string | null;
    readonly clientMutationId?: string | null;
};
export type tradeAdminCreateMutation_MutationVariables = {
    readonly input: TradeAdminCreateInput;
};
export type tradeAdminCreateMutation_MutationResponse = {
    readonly tradeAdminCreate: ({
        readonly clientMutationId: string | null;
    }) | null;
};
export type tradeAdminCreateMutation_Mutation = {
    readonly response: tradeAdminCreateMutation_MutationResponse;
    readonly variables: tradeAdminCreateMutation_MutationVariables;
};



/*
mutation tradeAdminCreateMutation_Mutation(
  $input: TradeAdminCreateInput!
) {
  tradeAdminCreate(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "TradeAdminCreateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "tradeAdminCreate",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "TradeAdminCreateInput!"
      }
    ],
    "concreteType": "TradeAdminCreatePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "tradeAdminCreateMutation_Mutation",
  "id": null,
  "text": "mutation tradeAdminCreateMutation_Mutation(\n  $input: TradeAdminCreateInput!\n) {\n  tradeAdminCreate(input: $input) {\n    clientMutationId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "tradeAdminCreateMutation_Mutation",
    "type": "Mutations",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "tradeAdminCreateMutation_Mutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
(node as any).hash = '178c2dd089037535092e2798d8386bf6';
export default node;

/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type UserDeactivateInput = {
    readonly userId: string;
    readonly endDate?: any | null;
    readonly clientMutationId?: string | null;
};
export type UserDeactivateMutation_MutationVariables = {
    readonly input: UserDeactivateInput;
};
export type UserDeactivateMutation_MutationResponse = {
    readonly userDeactivate: ({
        readonly clientMutationId: string | null;
    }) | null;
};
export type UserDeactivateMutation_Mutation = {
    readonly response: UserDeactivateMutation_MutationResponse;
    readonly variables: UserDeactivateMutation_MutationVariables;
};



/*
mutation UserDeactivateMutation_Mutation(
  $input: UserDeactivateInput!
) {
  userDeactivate(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UserDeactivateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "userDeactivate",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UserDeactivateInput!"
      }
    ],
    "concreteType": "UserDeactivatePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UserDeactivateMutation_Mutation",
  "id": null,
  "text": "mutation UserDeactivateMutation_Mutation(\n  $input: UserDeactivateInput!\n) {\n  userDeactivate(input: $input) {\n    clientMutationId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UserDeactivateMutation_Mutation",
    "type": "Mutations",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "UserDeactivateMutation_Mutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
(node as any).hash = 'e1dd28236bfe95f0e4a1cb9cc5e298c3';
export default node;

/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type UserChangePasswordInput = {
    readonly userId: string;
    readonly password: string;
    readonly clientMutationId?: string | null;
};
export type UserChangePasswordMutation_MutationVariables = {
    readonly input: UserChangePasswordInput;
};
export type UserChangePasswordMutation_MutationResponse = {
    readonly userChangePassword: ({
        readonly clientMutationId: string | null;
    }) | null;
};
export type UserChangePasswordMutation_Mutation = {
    readonly response: UserChangePasswordMutation_MutationResponse;
    readonly variables: UserChangePasswordMutation_MutationVariables;
};



/*
mutation UserChangePasswordMutation_Mutation(
  $input: UserChangePasswordInput!
) {
  userChangePassword(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UserChangePasswordInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "userChangePassword",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UserChangePasswordInput!"
      }
    ],
    "concreteType": "UserChangePasswordPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UserChangePasswordMutation_Mutation",
  "id": null,
  "text": "mutation UserChangePasswordMutation_Mutation(\n  $input: UserChangePasswordInput!\n) {\n  userChangePassword(input: $input) {\n    clientMutationId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UserChangePasswordMutation_Mutation",
    "type": "Mutations",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "UserChangePasswordMutation_Mutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
(node as any).hash = '44e17af3ad126f55393dc7bc34d6ce56';
export default node;

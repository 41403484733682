import React from 'react';
import {withStyles, WithStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {MenuItem, FormControlLabel, Radio} from '@material-ui/core';
import {TextField, RadioGroup} from 'formik-material-ui';
import * as Yup from 'yup';
import {graphql, createFragmentContainer} from 'react-relay';
import moment from 'moment';
import Spinner from 'react-spinkit';

import {Formik, Form, Field} from 'formik';
import Typography from '@material-ui/core/Typography';

import TextArea from './TextArea';
import styles from './ModalStyles';
import {createRelayRenderer, EnvironmentProp} from '../../RelayRenderer';
import {NewTransactionModal_viewer} from './__generated__/NewTransactionModal_viewer.graphql';
import WeightTransactionAdminCreateMutation from './mutations/WeightTransactionAdminCreateMutation';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import NumberCounter from './NumberCounter';

interface Props extends WithStyles<typeof styles>, EnvironmentProp {
  onClose?: () => void;
  viewer: NewTransactionModal_viewer;
}
const percentagePattern = /^\d+(\.\d{0,5})?$/;
const schema = Yup.object().shape({
  fineContent: Yup.number()
    .required('Required')
    .min(0.01, 'Weight must be minimum of 1g')
    .max(999999, 'Transactions this large are not allowed'),
  goldPercentage: Yup.number()
    .positive()
    .required('Required')
    .min(0, 'Minimum of 0%')
    .max(100, 'Maximum of 100%')
    .test(
      'is-decimal',
      'Percentage should have a maximum of 5 digits after the comma',
      (val: any) => {
        if (val !== undefined) {
          return percentagePattern.test(val);
        }
        return true;
      }
    ),
  companyId: Yup.string().required('Required'),
  pcCode: Yup.string().max(63, 'Code is too long'),
  date: Yup.string()
    .required('Required')
    .max(20, 'Value is too long'),
  debitOrCredit: Yup.string(),
  notes: Yup.string().max(200, 'Message is too long'),
});

const Clicked = (e: React.MouseEvent<HTMLElement>) => {
  e.stopPropagation();
};

interface Values {
  companyId: string;
  date: string;
  pcCode: string;
  fineContent: number;
  goldPercentage: number;
  debitOrCredit: 'debit' | 'credit';
  notes: string;
}

function NewTransactionModal(props: Props) {
  const {classes, viewer} = props;
  return (
    <div
      className={classes.root}
      onClick={(e: React.MouseEvent<HTMLElement>) => Clicked(e)}
    >
      <Typography variant="h6" className={classes.title}>
        New Transaction
        <span
          className={classes.exit}
          onClick={() => props.onClose && props.onClose()}
        >
          +
        </span>
      </Typography>
      <Formik<Values>
        onSubmit={async (values, {setSubmitting, setStatus}) => {
          setSubmitting(true);

          try {
            await WeightTransactionAdminCreateMutation(props.environment, {
              input: {
                companyId: values.companyId,
                fineContent: values.fineContent,
                goldPercentage: values.goldPercentage,
                pcCode: values.pcCode,
                debitOrCredit: values.debitOrCredit,
                notes: values.notes,
                timestamp: moment(`${values.date}`),
              },
            });
            setSubmitting(false);

            if (props.onClose) {
              props.onClose();
            }
          } catch (ex) {
            setStatus(ex.message);
            setSubmitting(false);
            if (ex.message === 'Insufficient Trade Balance Available.') {
              setStatus([
                `Overall company limit will be exceeded.`,
                <br />,
                `Either wait for other trades to be delivered, or increase company limit. `,
              ]);
            }
          }
        }}
        validationSchema={schema}
        initialValues={{
          companyId: '',
          date: moment().format('YYYY-MM-DD'),
          pcCode: '',
          fineContent: 0,
          goldPercentage: 0,
          debitOrCredit: 'debit',
          notes: '',
        }}
        render={({handleSubmit, isSubmitting, status, values}) => (
          <Form>
            <Field
              InputLabelProps={{shrink: true}}
              name="companyId"
              label="Supplier"
              type="text"
              component={TextField}
              select
              style={{width: '100%', margin: '10px 0 0'}}
            >
              {viewer!.companies!.edges.map(({node}) => (
                <MenuItem key={node.id} value={node.id}>
                  {node.info.name}
                </MenuItem>
              ))}
            </Field>
            <br />
            <br />
            <Field
              InputLabelProps={{shrink: true}}
              type="date"
              label="Date"
              name="date"
              component={TextField}
              style={{width: '100%'}}
            />
            <br />
            <br />
            <Field
              InputLabelProps={{shrink: true}}
              name="pcCode"
              label="PC Code"
              type="text"
              component={TextField}
              style={{width: '100%'}}
            />
            <br />
            <br />
            <div style={{position: 'relative'}}>
              <Field
                InputLabelProps={{shrink: true}}
                name="fineContent"
                label="Fine content (g)"
                type="text"
                component={TextField}
                style={{width: '100%'}}
              />
              <span className={classes.gram}>g</span>
            </div>
            <br />
            <br />
            <div style={{position: 'relative'}}>
              <Field
                InputLabelProps={{shrink: true}}
                name="goldPercentage"
                label="Gold Percentage (Fine %)"
                type="number"
                component={TextField}
                style={{width: '100%'}}
              />
              <span className={classes.gram}>%</span>
            </div>
            <br />
            <Field
              name="debitOrCredit"
              component={RadioGroup}
              style={{display: 'flex', flexDirection: 'row'}}
            >
              <FormControlLabel
                value="debit"
                control={<Radio disabled={isSubmitting} />}
                label="Debit"
                disabled={isSubmitting}
                style={{maxWidth: '50%'}}
              />
              <FormControlLabel
                value="credit"
                control={<Radio disabled={isSubmitting} />}
                label="Credit"
                disabled={isSubmitting}
                style={{maxWidth: '50%'}}
              />
            </Field>
            <Field
              name="notes"
              label="Notes"
              type="text"
              component={TextArea}
            />
            <NumberCounter current={values.notes.length} max={200} />
            <br />
            <Button
              variant="contained"
              color="primary"
              style={{width: '100%', margin: '10px 0 10px'}}
              className={classes.button}
              onClick={() => handleSubmit()}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Spinner
                  name="circle"
                  color="white"
                  // @ts-ignore
                  style={{margin: '0 auto'}}
                />
              ) : (
                'Add Transaction'
              )}
              <FontAwesomeIcon
                icon={faChevronRight}
                style={{fontSize: '0.875rem', paddingLeft: '10px'}}
              />
            </Button>
            {status && (
              <Typography className={classes.error}>{status}</Typography>
            )}
          </Form>
        )}
      />
    </div>
  );
}

const query = graphql`
  query NewTransactionModalQuery {
    viewer {
      ...NewTransactionModal_viewer
    }
  }
`;

export default withStyles(styles)(
  createRelayRenderer({
    container: createFragmentContainer(NewTransactionModal, {
      viewer: graphql`
        fragment NewTransactionModal_viewer on Viewer {
          currentTradePrice {
            zarPerTroyOunce
            usdPerTroyOunce
            zarToUsd
          }
          companies {
            edges {
              node {
                id
                info {
                  name
                }
              }
            }
          }
        }
      `,
    }),
    query,
    variables: {
      count: 20,
    },
  })
);

import {graphql} from 'react-relay';

import {createMutation} from '@stackworx/relay';

import {
  CompanyCreateMutation_MutationResponse,
  CompanyCreateMutation_MutationVariables,
} from './__generated__/CompanyCreateMutation_Mutation.graphql';

const mutation = graphql`
  mutation CompanyCreateMutation_Mutation($input: CompanyCreateInput!) {
    companyCreate(input: $input) {
      clientMutationId
    }
  }
`;

export default createMutation<
  CompanyCreateMutation_MutationVariables,
  CompanyCreateMutation_MutationResponse
>(mutation);

/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type GoldPriceQueryVariables = {};
export type GoldPriceQueryResponse = {
    readonly viewer: {
        readonly currentTradePrice: ({
            readonly id: string;
            readonly zarPerTroyOunce: number;
            readonly zarToUsd: number;
            readonly zarPerGram: number;
            readonly usdPerTroyOunce: number;
            readonly lastUpdated: any;
        }) | null;
    };
};
export type GoldPriceQuery = {
    readonly response: GoldPriceQueryResponse;
    readonly variables: GoldPriceQueryVariables;
};



/*
query GoldPriceQuery {
  viewer {
    currentTradePrice {
      id
      zarPerTroyOunce
      zarToUsd
      zarPerGram
      usdPerTroyOunce
      lastUpdated
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "currentTradePrice",
    "storageKey": null,
    "args": null,
    "concreteType": "TradePrice",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "zarPerTroyOunce",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "zarToUsd",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "zarPerGram",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "usdPerTroyOunce",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "lastUpdated",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "GoldPriceQuery",
  "id": null,
  "text": "query GoldPriceQuery {\n  viewer {\n    currentTradePrice {\n      id\n      zarPerTroyOunce\n      zarToUsd\n      zarPerGram\n      usdPerTroyOunce\n      lastUpdated\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "GoldPriceQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "viewer",
        "name": "__viewer_viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": v0
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "GoldPriceQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": v0
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "viewer",
        "args": null,
        "handle": "viewer",
        "key": "",
        "filters": null
      }
    ]
  }
};
})();
(node as any).hash = 'd7b7ecb4a3091642acb17ef7da59d3be';
export default node;

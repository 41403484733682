/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type TradeDownloadInput = {
    readonly supplierIds?: ReadonlyArray<string | null> | null;
    readonly startDate?: any | null;
    readonly endDate?: any | null;
    readonly clientMutationId?: string | null;
};
export type TradeDownloadMutation_MutationVariables = {
    readonly input: TradeDownloadInput;
};
export type TradeDownloadMutation_MutationResponse = {
    readonly tradeDownload: ({
        readonly clientMutationId: string | null;
        readonly output: string;
        readonly filename: string;
    }) | null;
};
export type TradeDownloadMutation_Mutation = {
    readonly response: TradeDownloadMutation_MutationResponse;
    readonly variables: TradeDownloadMutation_MutationVariables;
};



/*
mutation TradeDownloadMutation_Mutation(
  $input: TradeDownloadInput!
) {
  tradeDownload(input: $input) {
    clientMutationId
    output
    filename
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "TradeDownloadInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "tradeDownload",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "TradeDownloadInput!"
      }
    ],
    "concreteType": "TradeDownloadPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "output",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "filename",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "TradeDownloadMutation_Mutation",
  "id": null,
  "text": "mutation TradeDownloadMutation_Mutation(\n  $input: TradeDownloadInput!\n) {\n  tradeDownload(input: $input) {\n    clientMutationId\n    output\n    filename\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TradeDownloadMutation_Mutation",
    "type": "Mutations",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "TradeDownloadMutation_Mutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
(node as any).hash = 'f25f7a2039edf528f2b431c573204f38';
export default node;

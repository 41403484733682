import * as React from 'react';
import {Theme} from '@material-ui/core';
import {createStyles, WithStyles, withStyles} from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import {faChevronDown} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {
  createRelayRenderer,
  EnvironmentProp,
} from '../../components/RelayRenderer';
import appTheme from '../../../styles/theme';

import {graphql} from 'react-relay';
import {TraderFilterQueryResponse} from './__generated__/TraderFilterQuery.graphql';
import {forEach} from 'lodash';

interface Props
  extends WithStyles<typeof styles>,
    EnvironmentProp,
    TraderFilterQueryResponse {
  value: string[];
  companyIds: string[];
  onChange: (company?: string[]) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      float: 'right',
      padding: '10px',
      paddingRight: '0px',
      height: '18px',
      fontSize: '0.875rem',
      '& label': {
        paddingRight: '5px',
        lineHeight: '39px',
        display: 'block',
        float: 'left',
      },
    },
    option: {
      color: '#5c5c5c',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    input: {
      color: theme.palette.secondary.main,
      minWidth: '200px',
      fontSize: '0.875rem',
      display: 'block',
      float: 'right',
      backgroundColor: theme.palette.primary.light,
      lineHeight: '28px',
      padding: '0 10px 0 10px',
      maxWidth: '200px',
      '&:before': {
        display: 'none',
      },
      '& svg': {
        marginTop: 5,
      },
      '& filled': {
        left: '0 !Important',
        width: '214px !important',
      },
    },
    dropdownStyle: {
      borderRadius: '0',
      width: '220px',
      marginLeft: '-10px',
      marginTop: '33px',
      '& *:focus': {
        outline: 'none',
      },
      '& ul': {
        padding: '0',
        '& option': {
          padding: '0 10px',
        },
      },
    },
  });

export class TraderFilter extends React.Component<Props> {
  public render() {
    const {
      classes,
      viewer: {companies},
    } = this.props;
    const {value, companyIds} = this.props;

    const users: Array<{
      node: {
        id: string;
        username: string;
      };
    }> = [];

    if (companies) {
      forEach(
        companies.edges.filter((company) => {
          if (companyIds && companyIds.length > 0) {
            return companyIds.includes(company.node.id);
          }
          return true;
        }),
        (company) => {
          if (company.node.users) {
            company.node.users.edges.forEach((user) => users.push(user));
          }
        }
      );
    }

    const usernameById: {[key: string]: string} = users
      ? users.reduce((acc: {[key: string]: string}, edge) => {
          if (edge && edge.node) {
            acc[edge.node.id] = edge.node.username;
          }

          return acc;
        }, {})
      : {};

    return (
      <div className={classes.root}>
        <label>Filter by Trader</label>

        <Select
          className={classes.input}
          IconComponent={(props) => (
            <FontAwesomeIcon icon={faChevronDown} className={props.className} />
          )}
          onChange={(event: React.ChangeEvent<{value: unknown}>) => {
            const val = event.target.value as string[];
            if (val.includes('all')) {
              const allIds = users.map((user) => user.node.id);
              this.props.onChange(allIds);
            } else {
              this.props.onChange(val);
            }
          }}
          value={value}
          disabled={!users}
          displayEmpty
          multiple={true}
          renderValue={(selected) => {
            if ((selected as string[]).length === 0) {
              return <em>Search for trader</em>;
            }
            if ((selected as string[]).length === users.length) {
              return <em>All selected</em>;
            }

            return (selected as string[])
              .map((id) => usernameById[id] || 'Unknown')
              .join(', ');
          }}
          MenuProps={{classes: {paper: classes.dropdownStyle}}}
        >
          <MenuItem disabled={true} value="" className={classes.option}>
            Search for Trader
          </MenuItem>

          <MenuItem value="all" className={classes.option}>
            Select All
          </MenuItem>

          {users
            ? users.map((user) => (
                <MenuItem
                  key={user.node.id}
                  value={user.node.id}
                  className={classes.option}
                  style={
                    value.includes(user.node.id)
                      ? {backgroundColor: '#dcdcdf'}
                      : {}
                  }
                >
                  {user.node.username}
                </MenuItem>
              ))
            : 'Loading...'}
        </Select>
      </div>
    );
  }
}

export default createRelayRenderer({
  query: graphql`
    query TraderFilterQuery {
      viewer {
        companies {
          edges {
            node {
              id
              info {
                name
              }
              users {
                edges {
                  node {
                    id
                    username
                  }
                }
              }
            }
          }
        }
      }
    }
  `,

  container: withStyles(styles)(TraderFilter),
});

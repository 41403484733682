/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
type DeclinedTable_viewer$ref = any;
export type DeclinedTableQueryVariables = {
    readonly count: number;
    readonly cursor?: string | null;
    readonly supplierIds?: ReadonlyArray<string> | null;
    readonly traderIds?: ReadonlyArray<string> | null;
    readonly startDate?: any | null;
    readonly endDate?: any | null;
};
export type DeclinedTableQueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": DeclinedTable_viewer$ref;
    };
};
export type DeclinedTableQuery = {
    readonly response: DeclinedTableQueryResponse;
    readonly variables: DeclinedTableQueryVariables;
};



/*
query DeclinedTableQuery(
  $count: Int!
  $cursor: String
  $supplierIds: [ID!]
  $traderIds: [ID!]
  $startDate: DateTime
  $endDate: DateTime
) {
  viewer {
    ...DeclinedTable_viewer_1qdc0
  }
}

fragment DeclinedTable_viewer_1qdc0 on Viewer {
  trades(first: $count, after: $cursor, status: rejected, supplierIds: $supplierIds, traderIds: $traderIds, from: $startDate, to: $endDate) {
    total
    edges {
      node {
        id
        ...DeclinedTradeRow_trade
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment DeclinedTradeRow_trade on Trade {
  id
  tradeTimestamp
  status
  weight
  company {
    id
    info {
      name
    }
  }
  createdBy {
    id
    displayName
  }
  usdPerTroyOunce
  zarToUsd
  zarPerGram
  requestedZarPerGram
  rejection {
    eventTimestamp
    reason
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "supplierIds",
    "type": "[ID!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "traderIds",
    "type": "[ID!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "startDate",
    "type": "DateTime",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "endDate",
    "type": "DateTime",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "from",
    "variableName": "startDate",
    "type": "DateTime"
  },
  {
    "kind": "Literal",
    "name": "status",
    "value": "rejected",
    "type": "TradeStatus"
  },
  {
    "kind": "Variable",
    "name": "supplierIds",
    "variableName": "supplierIds",
    "type": "[ID!]"
  },
  {
    "kind": "Variable",
    "name": "to",
    "variableName": "endDate",
    "type": "DateTime"
  },
  {
    "kind": "Variable",
    "name": "traderIds",
    "variableName": "traderIds",
    "type": "[ID!]"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "DeclinedTableQuery",
  "id": null,
  "text": "query DeclinedTableQuery(\n  $count: Int!\n  $cursor: String\n  $supplierIds: [ID!]\n  $traderIds: [ID!]\n  $startDate: DateTime\n  $endDate: DateTime\n) {\n  viewer {\n    ...DeclinedTable_viewer_1qdc0\n  }\n}\n\nfragment DeclinedTable_viewer_1qdc0 on Viewer {\n  trades(first: $count, after: $cursor, status: rejected, supplierIds: $supplierIds, traderIds: $traderIds, from: $startDate, to: $endDate) {\n    total\n    edges {\n      node {\n        id\n        ...DeclinedTradeRow_trade\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment DeclinedTradeRow_trade on Trade {\n  id\n  tradeTimestamp\n  status\n  weight\n  company {\n    id\n    info {\n      name\n    }\n  }\n  createdBy {\n    id\n    displayName\n  }\n  usdPerTroyOunce\n  zarToUsd\n  zarPerGram\n  requestedZarPerGram\n  rejection {\n    eventTimestamp\n    reason\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DeclinedTableQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "viewer",
        "name": "__viewer_viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "DeclinedTable_viewer",
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count",
                "type": null
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor",
                "type": null
              },
              {
                "kind": "Variable",
                "name": "endDate",
                "variableName": "endDate",
                "type": null
              },
              {
                "kind": "Variable",
                "name": "startDate",
                "variableName": "startDate",
                "type": null
              },
              {
                "kind": "Variable",
                "name": "supplierIds",
                "variableName": "supplierIds",
                "type": null
              },
              {
                "kind": "Variable",
                "name": "traderIds",
                "variableName": "traderIds",
                "type": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DeclinedTableQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "trades",
            "storageKey": null,
            "args": v1,
            "concreteType": "ViewerTradesConnection",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "total",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ViewerTradesEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Trade",
                    "plural": false,
                    "selections": [
                      v2,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "tradeTimestamp",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "status",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "weight",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "company",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Company",
                        "plural": false,
                        "selections": [
                          v2,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "info",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CompanyInfo",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "name",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "createdBy",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AuditUser",
                        "plural": false,
                        "selections": [
                          v2,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "displayName",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "usdPerTroyOunce",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "zarToUsd",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "zarPerGram",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "requestedZarPerGram",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "rejection",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "LatestTradeRejection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "eventTimestamp",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "reason",
                            "args": null,
                            "storageKey": null
                          },
                          v2
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "__typename",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "trades",
            "args": v1,
            "handle": "connection",
            "key": "ViewerDeclined_trades",
            "filters": [
              "supplierIds"
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "viewer",
        "args": null,
        "handle": "viewer",
        "key": "",
        "filters": null
      }
    ]
  }
};
})();
(node as any).hash = '2852a850078dd0a6e17dab74b718a629';
export default node;

/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
type PendingTradeRow_trade$ref = any;
export type TradeAdminPendingUpdateInput = {
    readonly tradeId: string;
    readonly reference: string;
    readonly zarPerTroyOunce: number;
    readonly zarToUsd: number;
    readonly weight: number;
    readonly clientMutationId?: string | null;
};
export type TradeAdminPendingUpdateMutationVariables = {
    readonly input: TradeAdminPendingUpdateInput;
};
export type TradeAdminPendingUpdateMutationResponse = {
    readonly tradeAdminPendingUpdate: ({
        readonly clientMutationId: string | null;
        readonly trade: {
            readonly " $fragmentRefs": PendingTradeRow_trade$ref;
        };
    }) | null;
};
export type TradeAdminPendingUpdateMutation = {
    readonly response: TradeAdminPendingUpdateMutationResponse;
    readonly variables: TradeAdminPendingUpdateMutationVariables;
};



/*
mutation TradeAdminPendingUpdateMutation(
  $input: TradeAdminPendingUpdateInput!
) {
  tradeAdminPendingUpdate(input: $input) {
    clientMutationId
    trade {
      ...PendingTradeRow_trade
      id
    }
  }
}

fragment PendingTradeRow_trade on Trade {
  id
  refNumber
  tradeTimestamp
  company {
    id
    runningBalance
    tradeLimit {
      limit
    }
    info {
      name
    }
  }
  createdBy {
    id
    displayName
  }
  status
  weight
  usdPerTroyOunce
  zarToUsd
  zarPerGram
  requestedZarPerGram
  notes
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "TradeAdminPendingUpdateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "TradeAdminPendingUpdateInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "clientMutationId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "TradeAdminPendingUpdateMutation",
  "id": null,
  "text": "mutation TradeAdminPendingUpdateMutation(\n  $input: TradeAdminPendingUpdateInput!\n) {\n  tradeAdminPendingUpdate(input: $input) {\n    clientMutationId\n    trade {\n      ...PendingTradeRow_trade\n      id\n    }\n  }\n}\n\nfragment PendingTradeRow_trade on Trade {\n  id\n  refNumber\n  tradeTimestamp\n  company {\n    id\n    runningBalance\n    tradeLimit {\n      limit\n    }\n    info {\n      name\n    }\n  }\n  createdBy {\n    id\n    displayName\n  }\n  status\n  weight\n  usdPerTroyOunce\n  zarToUsd\n  zarPerGram\n  requestedZarPerGram\n  notes\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TradeAdminPendingUpdateMutation",
    "type": "Mutations",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "tradeAdminPendingUpdate",
        "storageKey": null,
        "args": v1,
        "concreteType": "TradeAdminPendingUpdatePayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "trade",
            "storageKey": null,
            "args": null,
            "concreteType": "Trade",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "PendingTradeRow_trade",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TradeAdminPendingUpdateMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "tradeAdminPendingUpdate",
        "storageKey": null,
        "args": v1,
        "concreteType": "TradeAdminPendingUpdatePayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "trade",
            "storageKey": null,
            "args": null,
            "concreteType": "Trade",
            "plural": false,
            "selections": [
              v3,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "refNumber",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "tradeTimestamp",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "company",
                "storageKey": null,
                "args": null,
                "concreteType": "Company",
                "plural": false,
                "selections": [
                  v3,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "runningBalance",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "tradeLimit",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CompanyTradeLimit",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "limit",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "info",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CompanyInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "name",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "createdBy",
                "storageKey": null,
                "args": null,
                "concreteType": "AuditUser",
                "plural": false,
                "selections": [
                  v3,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "displayName",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "status",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "weight",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "usdPerTroyOunce",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "zarToUsd",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "zarPerGram",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "requestedZarPerGram",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "notes",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
(node as any).hash = '028689c6e1f55ef1c88b0d027b09954a';
export default node;

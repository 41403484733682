import {graphql} from 'react-relay';

import {createMutation} from '@stackworx/relay';

import {
  ConfirmTradeMutation_MutationResponse,
  ConfirmTradeMutation_MutationVariables,
} from './__generated__/ConfirmTradeMutation_Mutation.graphql';
import {ConnectionHandler} from 'relay-runtime';

const mutation = graphql`
  mutation ConfirmTradeMutation_Mutation($input: TradeConfirmInput!) {
    tradeConfirm(input: $input) {
      clientMutationId
      trade {
        id
        status
      }
    }
  }
`;

export default createMutation<
  ConfirmTradeMutation_MutationVariables,
  ConfirmTradeMutation_MutationResponse
>(mutation, {
  updater: (store, data: any) => {
    if (data.tradeConfirm && data.tradeConfirm.trade) {
      const tradeId = data.tradeConfirm.trade.id;

      const viewer = store.get('client:root:viewer');

      if (viewer) {
        const conn = ConnectionHandler.getConnection(
          viewer,
          'ViewerPending_trades'
        );

        if (conn) {
          ConnectionHandler.deleteNode(conn, tradeId);
        }
      }
    }
  },
});

/* tslint:disable */

import { ConcreteFragment } from "relay-runtime";
type TransactionsTradeRow_weightTransaction$ref = any;
export type TransactionsTable_viewer$ref = any;
export type TransactionsTable_viewer = {
    readonly weightTransactions: ({
        readonly total: number | null;
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": TransactionsTradeRow_weightTransaction$ref;
            };
        }>;
    }) | null;
    readonly " $refType": TransactionsTable_viewer$ref;
};



const node: ConcreteFragment = {
  "kind": "Fragment",
  "name": "TransactionsTable_viewer",
  "type": "Viewer",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "weightTransactions"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "count",
      "type": "Int"
    },
    {
      "kind": "RootArgument",
      "name": "cursor",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "supplierIds",
      "type": "[ID!]"
    },
    {
      "kind": "RootArgument",
      "name": "traderIds",
      "type": "[ID!]"
    },
    {
      "kind": "RootArgument",
      "name": "startDate",
      "type": "DateTime"
    },
    {
      "kind": "RootArgument",
      "name": "endDate",
      "type": "DateTime"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "weightTransactions",
      "name": "__ViewerWeightTransactions_weightTransactions_connection",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "supplierIds",
          "variableName": "supplierIds",
          "type": "[ID!]"
        }
      ],
      "concreteType": "ViewerWeightTransactionConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "total",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ViewerWeightTransactionEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "WeightTransaction",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "TransactionsTradeRow_weightTransaction",
                  "args": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
(node as any).hash = 'de1badebb31f894814aa9b28949fc6e3';
export default node;

/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type CompanyDeactivateInput = {
    readonly companyId: string;
    readonly endDate?: any | null;
    readonly clientMutationId?: string | null;
};
export type CompanyDeactivateMutation_MutationVariables = {
    readonly input: CompanyDeactivateInput;
};
export type CompanyDeactivateMutation_MutationResponse = {
    readonly companyDeactivate: ({
        readonly clientMutationId: string | null;
    }) | null;
};
export type CompanyDeactivateMutation_Mutation = {
    readonly response: CompanyDeactivateMutation_MutationResponse;
    readonly variables: CompanyDeactivateMutation_MutationVariables;
};



/*
mutation CompanyDeactivateMutation_Mutation(
  $input: CompanyDeactivateInput!
) {
  companyDeactivate(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CompanyDeactivateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "companyDeactivate",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "CompanyDeactivateInput!"
      }
    ],
    "concreteType": "CompanyDeactivatePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CompanyDeactivateMutation_Mutation",
  "id": null,
  "text": "mutation CompanyDeactivateMutation_Mutation(\n  $input: CompanyDeactivateInput!\n) {\n  companyDeactivate(input: $input) {\n    clientMutationId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CompanyDeactivateMutation_Mutation",
    "type": "Mutations",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "CompanyDeactivateMutation_Mutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
(node as any).hash = '9937318e83cf5244b85a67f70dde56a7';
export default node;

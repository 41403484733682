/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type UserCreateInput = {
    readonly email: string;
    readonly firstname: string;
    readonly lastname: string;
    readonly cellphone: string;
    readonly companyId: string;
    readonly password: string;
    readonly pin: string;
    readonly clientMutationId?: string | null;
};
export type UserCreateMutation_MutationVariables = {
    readonly input: UserCreateInput;
};
export type UserCreateMutation_MutationResponse = {
    readonly userCreate: ({
        readonly clientMutationId: string | null;
    }) | null;
};
export type UserCreateMutation_Mutation = {
    readonly response: UserCreateMutation_MutationResponse;
    readonly variables: UserCreateMutation_MutationVariables;
};



/*
mutation UserCreateMutation_Mutation(
  $input: UserCreateInput!
) {
  userCreate(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UserCreateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "userCreate",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UserCreateInput!"
      }
    ],
    "concreteType": "UserCreatePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UserCreateMutation_Mutation",
  "id": null,
  "text": "mutation UserCreateMutation_Mutation(\n  $input: UserCreateInput!\n) {\n  userCreate(input: $input) {\n    clientMutationId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UserCreateMutation_Mutation",
    "type": "Mutations",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "UserCreateMutation_Mutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
(node as any).hash = '72996d42e18215ba0f34426ad99b492b';
export default node;

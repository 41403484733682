/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type WeightTransactionNotesEditInput = {
    readonly id: string;
    readonly notes?: string | null;
    readonly clientMutationId?: string | null;
};
export type WeightTransactionEditNotesMutationVariables = {
    readonly input: WeightTransactionNotesEditInput;
};
export type WeightTransactionEditNotesMutationResponse = {
    readonly weightTransactionEditNotes: ({
        readonly clientMutationId: string | null;
        readonly id: string;
        readonly notes: string | null;
    }) | null;
};
export type WeightTransactionEditNotesMutation = {
    readonly response: WeightTransactionEditNotesMutationResponse;
    readonly variables: WeightTransactionEditNotesMutationVariables;
};



/*
mutation WeightTransactionEditNotesMutation(
  $input: WeightTransactionNotesEditInput!
) {
  weightTransactionEditNotes(input: $input) {
    clientMutationId
    id
    notes
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "WeightTransactionNotesEditInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "weightTransactionEditNotes",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "WeightTransactionNotesEditInput!"
      }
    ],
    "concreteType": "WeightTransactionNotesEditPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "notes",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "WeightTransactionEditNotesMutation",
  "id": null,
  "text": "mutation WeightTransactionEditNotesMutation(\n  $input: WeightTransactionNotesEditInput!\n) {\n  weightTransactionEditNotes(input: $input) {\n    clientMutationId\n    id\n    notes\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "WeightTransactionEditNotesMutation",
    "type": "Mutations",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "WeightTransactionEditNotesMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
(node as any).hash = 'ade814ddde0fe50d0c0abfd415426589';
export default node;

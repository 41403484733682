import React from 'react';
import {withStyles, WithStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import styles from './ModalStyles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import {TextField} from 'formik-material-ui';
import {Formik, Form, Field, FieldProps} from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import Spinner from 'react-spinkit';

import {EnvironmentProp} from '../../RelayRenderer';
import ConfirmTradeMutation from './mutations/ConfirmTradeMutation';
import classNames from 'classnames';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import NumberCounter from './NumberCounter';

interface Values {
  confirmTrade: {
    reference: string;
    usdPerTroyOunce: number;
    zarToUsd: number;
    date: string;
    time: string;
    confirmationNotes: string;
    checked?: boolean;
  };
}

interface Props extends WithStyles<typeof styles>, EnvironmentProp {
  tradeId?: string;
  price?: string;
  rate?: string;
  reference?: string;
  runningBalance: number;
  tradeLimit: number;
  requestedWeight: number;
  onClose: () => void;
}

const Clicked = (e: React.MouseEvent<HTMLElement>) => {
  e.stopPropagation();
};

function ConfirmTradeModal({
  runningBalance,
  tradeLimit,
  tradeId,
  requestedWeight,
  reference,
  rate,
  price,
  classes,
  onClose,
  environment,
}: Props) {
  const schema = Yup.object().shape({
    confirmTrade: Yup.object().shape({
      reference: Yup.string().max(60, 'Value is too long'),
      usdPerTroyOunce: Yup.number()
        .required('Required')
        .max(999999, 'Number too large'),
      zarToUsd: Yup.number()
        .required('Required')
        .max(999999, 'Number too large'),
      date: Yup.string()
        .required('Required')
        .max(20, 'Value is too long'),
      time: Yup.string()
        .required('Required')
        .max(20, 'Value is too long'),
      confirmationNotes: Yup.string().max(200, 'Value is too long'),
      checked: Yup.boolean().test(
        'tradeLimitExceededTest',
        'Please confirm trade limit excess',
        (value: boolean) => {
          const limitCheck =
            -runningBalance * 1 + requestedWeight * 1 > tradeLimit * 1;
          if (limitCheck) {
            if (value) {
              return true;
            }
            return false;
          } else {
            // if limitCheck is false, then submission is allowed
            return true;
          }
        }
      ),
    }),
  });

  const renderTradeLimitExceededConfirmation = () => {
    const renderCheckbox =
      -runningBalance * 1 + requestedWeight * 1 > tradeLimit * 1;

    return renderCheckbox === true ? (
      <div
        style={{
          marginTop: '25px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Field name="confirmTrade.checked">
          {({
            form: {submitCount, errors, setFieldValue},
            field,
          }: FieldProps) => {
            return (
              <>
                <Checkbox
                  checked={field.value}
                  onChange={(_event) => {
                    setFieldValue(field.name, !field.value);
                  }}
                />
                <Typography
                  style={{
                    color:
                      submitCount > 0 &&
                      errors &&
                      errors.confirmTrade &&
                      typeof errors.confirmTrade === 'object' &&
                      typeof errors.confirmTrade.checked === 'string'
                        ? 'red'
                        : 'black',
                  }}
                >
                  {`I am aware that confirming this trade will exceed the company debit limit by ${-runningBalance *
                    1 +
                    requestedWeight * 1 -
                    tradeLimit * 1}g`}
                </Typography>
              </>
            );
          }}
        </Field>
      </div>
    ) : null;
  };

  return (
    <div
      className={classes.root}
      onClick={(e: React.MouseEvent<HTMLElement>) => Clicked(e)}
    >
      <Typography variant="h6" className={classes.title}>
        Confirm Trade
        <span className={classes.exit} onClick={() => onClose && onClose()}>
          +
        </span>
      </Typography>
      <Formik<Values>
        onSubmit={async (values, {setSubmitting, setStatus}) => {
          setSubmitting(true);
          try {
            await ConfirmTradeMutation(environment, {
              input: {
                tradeId: tradeId || '',
                reference: values.confirmTrade.reference || '',
                zarPerTroyOunce:
                  values.confirmTrade.usdPerTroyOunce *
                    values.confirmTrade.zarToUsd || 0,
                zarToUsd: values.confirmTrade.zarToUsd || 0,
                notes: values.confirmTrade.confirmationNotes || '',
                timestamp: moment(
                  `${values.confirmTrade.date} ${values.confirmTrade.time}`
                ),
              },
            });
            setSubmitting(false);

            if (onClose) {
              onClose();
            }
          } catch (ex) {
            setStatus(ex.message);
            setSubmitting(false);
          }
        }}
        validationSchema={schema}
        initialValues={{
          confirmTrade: {
            reference: reference || '',
            usdPerTroyOunce: parseFloat(price || '0'),
            zarToUsd: parseFloat(rate || '0'),
            date: moment().format('YYYY-MM-DD'),
            time: moment().format('HH:mm:ss'),
            confirmationNotes: '',
            checked: false,
          },
        }}
        render={({handleSubmit, values, isSubmitting, status}) => (
          <Form>
            <Field
              InputLabelProps={{shrink: true}}
              name="confirmTrade.reference"
              label="Ref Nr"
              type="text"
              component={TextField}
              style={{width: '100%'}}
            />
            <br />
            <div
              className={classNames(classes.textHalf, classes.first)}
              style={{position: 'relative'}}
            >
              <Field
                InputLabelProps={{shrink: true}}
                name="confirmTrade.usdPerTroyOunce"
                label="USD per Troy Ounce"
                type="text"
                component={TextField}
                value={values.confirmTrade.usdPerTroyOunce}
                style={{width: '100%'}}
              />
              <span className={classes.gram}>USD/oz</span>
            </div>
            <div className={classes.textHalf} style={{position: 'relative'}}>
              <Field
                InputLabelProps={{shrink: true}}
                name="confirmTrade.zarToUsd"
                label="ZAR to USD"
                type="text"
                component={TextField}
                className={classes.textHalf}
                style={{width: '100%'}}
              />
              <span className={classes.gram}>ZAR/USD</span>
            </div>
            <Field
              name="confirmTrade.date"
              label="Date"
              type="date"
              component={TextField}
              className={classNames(classes.textHalf, classes.first)}
              InputLabelProps={{shrink: true}}
            />
            <Field
              name="confirmTrade.time"
              label="Time"
              type="time"
              component={TextField}
              className={classes.textHalf}
              InputLabelProps={{shrink: true}}
            />
            <br />
            <Field
              InputLabelProps={{shrink: true}}
              name="confirmTrade.confirmationNotes"
              label="Confirm Notes"
              type="text"
              component={TextField}
              multiline
              rows={6}
              style={{width: '100%'}}
            />
            <NumberCounter
              current={values.confirmTrade.confirmationNotes.length}
              max={200}
            />
            {renderTradeLimitExceededConfirmation()}
            <br />
            <Button
              variant="contained"
              color="primary"
              // disabled={showLimitCheck ? (checked ? false : true) : false}
              style={{width: '100%', margin: '0px 0 10px'}}
              className={classes.button}
              onClick={() => handleSubmit()}
            >
              {isSubmitting ? (
                <Spinner
                  name="circle"
                  color="white"
                  // @ts-ignore
                  style={{margin: '0 auto'}}
                />
              ) : (
                'Confirm Order'
              )}
              <FontAwesomeIcon
                icon={faChevronRight}
                style={{fontSize: '0.875rem', paddingLeft: '10px'}}
              />
            </Button>
            {status && (
              <Typography className={classes.error}>{status}</Typography>
            )}
          </Form>
        )}
      />
    </div>
  );
}

export default withStyles(styles)(ConfirmTradeModal);

import {graphql} from 'react-relay';

import {createMutation} from '@stackworx/relay';

import {
  UserChangePasswordMutation_MutationResponse,
  UserChangePasswordMutation_MutationVariables,
} from './__generated__/UserChangePasswordMutation_Mutation.graphql';

const mutation = graphql`
  mutation UserChangePasswordMutation_Mutation(
    $input: UserChangePasswordInput!
  ) {
    userChangePassword(input: $input) {
      clientMutationId
    }
  }
`;

export default createMutation<
  UserChangePasswordMutation_MutationVariables,
  UserChangePasswordMutation_MutationResponse
>(mutation);

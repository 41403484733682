import React from 'react';
import {withStyles, WithStyles} from '@material-ui/core/styles';
import styles from './InputInTableStyles';
import {Input} from '@material-ui/core';

interface Props extends WithStyles<typeof styles> {
  field?: any;
  form?: any;
}

function InputInTableEndAdornment({classes, field, form, ...atts}: Props) {
  const name = field && field.name ? field.name : null;
  return (
    <div className={classes.root}>
      <Input
        className={classes.tableInputEndAdornment}
        {...atts}
        {...field}
        endAdornment={<span style={{fontSize: '0.875rem'}}>g</span>}
        classes={{
          input: classes.input,
        }}
      />
      {form && form.errors && form.errors[name] && (
        <span className={classes.error}>{form.errors[name]}</span>
      )}
    </div>
  );
}

export default withStyles(styles)(InputInTableEndAdornment);

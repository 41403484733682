import {graphql} from 'react-relay';

import {createMutation} from '@stackworx/relay';

import {
  TradeAdminPendingUpdateMutationVariables,
  TradeAdminPendingUpdateMutationResponse,
} from './__generated__/TradeAdminPendingUpdateMutation.graphql';

const mutation = graphql`
  mutation TradeAdminPendingUpdateMutation(
    $input: TradeAdminPendingUpdateInput!
  ) {
    tradeAdminPendingUpdate(input: $input) {
      clientMutationId
      trade {
        ...PendingTradeRow_trade
      }
    }
  }
`;

export default createMutation<
  TradeAdminPendingUpdateMutationVariables,
  TradeAdminPendingUpdateMutationResponse
>(mutation);

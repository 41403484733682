import {graphql} from 'react-relay';

import {createMutation} from '@stackworx/relay';

import {
  CompanyBlockMutation_MutationResponse,
  CompanyBlockMutation_MutationVariables,
} from './__generated__/CompanyBlockMutation_Mutation.graphql';

const mutation = graphql`
  mutation CompanyBlockMutation_Mutation($input: CompanyBlockInput!) {
    companyBlock(input: $input) {
      clientMutationId
    }
  }
`;

export default createMutation<
  CompanyBlockMutation_MutationVariables,
  CompanyBlockMutation_MutationResponse
>(mutation);

import {createStyles} from '@material-ui/core/styles';
import {Theme} from '@material-ui/core';

export default (theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-block',
    },
    tableInput: {
      padding: '5px 10px',
      border: `solid 1px ${theme.palette.primary.dark}`,
      backgroundColor: theme.palette.secondary.dark,
      outlineStyle: 'none',
      boxShadow: 'none',
      width: 95,
      color: theme.palette.secondary.main,
      '&::placeholder': {
        color: theme.palette.primary.dark,
      },
    },
    supplierInput: {
      width: 80,
    },
    error: {
      color: 'red',
      display: 'block',
    },
    input: {
      padding: 0,
    },
    tableInputEndAdornment: {
      font: 'revert',
      padding: '5px 10px',
      border: `solid 1px ${theme.palette.primary.dark}`,
      backgroundColor: theme.palette.secondary.dark,
      outlineStyle: 'none',
      boxShadow: 'none',
      width: 95,
      color: theme.palette.secondary.main,
      '&::placeholder': {
        color: theme.palette.primary.dark,
      },
      '&&&&:hover:before': {
        borderBottom: 'none',
      },
      '&::before': {
        border: 'none',
      },
      '&::after': {
        border: 'none',
      },
    },
  });

import {graphql} from 'react-relay';

import {createMutation} from '@stackworx/relay';

import {
  WeightTransactionReversalMutationResponse,
  WeightTransactionReversalMutationVariables,
} from './__generated__/WeightTransactionReversalMutation.graphql';
import {RecordSourceSelectorProxy} from 'relay-runtime';

const mutation = graphql`
  mutation WeightTransactionReversalMutation(
    $input: WeightTransactionReversalInput!
  ) {
    weightTransactionReversal(input: $input) {
      clientMutationId
      weightTransaction {
        id
      }
    }
  }
`;

export default createMutation<
  WeightTransactionReversalMutationVariables,
  WeightTransactionReversalMutationResponse
>(mutation);

/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type TradeStatus = "confirmed" | "delivered" | "pending" | "rejected" | "%future added value";
export type TradeConfirmInput = {
    readonly tradeId: string;
    readonly timestamp?: any | null;
    readonly reference: string;
    readonly zarPerTroyOunce: number;
    readonly zarToUsd: number;
    readonly notes?: string | null;
    readonly clientMutationId?: string | null;
};
export type ConfirmTradeMutation_MutationVariables = {
    readonly input: TradeConfirmInput;
};
export type ConfirmTradeMutation_MutationResponse = {
    readonly tradeConfirm: ({
        readonly clientMutationId: string | null;
        readonly trade: {
            readonly id: string;
            readonly status: TradeStatus;
        };
    }) | null;
};
export type ConfirmTradeMutation_Mutation = {
    readonly response: ConfirmTradeMutation_MutationResponse;
    readonly variables: ConfirmTradeMutation_MutationVariables;
};



/*
mutation ConfirmTradeMutation_Mutation(
  $input: TradeConfirmInput!
) {
  tradeConfirm(input: $input) {
    clientMutationId
    trade {
      id
      status
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "TradeConfirmInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "tradeConfirm",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "TradeConfirmInput!"
      }
    ],
    "concreteType": "TradeConfirmPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "trade",
        "storageKey": null,
        "args": null,
        "concreteType": "Trade",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "ConfirmTradeMutation_Mutation",
  "id": null,
  "text": "mutation ConfirmTradeMutation_Mutation(\n  $input: TradeConfirmInput!\n) {\n  tradeConfirm(input: $input) {\n    clientMutationId\n    trade {\n      id\n      status\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ConfirmTradeMutation_Mutation",
    "type": "Mutations",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "ConfirmTradeMutation_Mutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
(node as any).hash = 'abb93cb4ec9fad1bb9b1c3491c49788c';
export default node;

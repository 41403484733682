/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type TradeRejectInput = {
    readonly tradeId: string;
    readonly timestamp?: any | null;
    readonly reason: string;
    readonly clientMutationId?: string | null;
};
export type RejectTradeMutation_MutationVariables = {
    readonly input: TradeRejectInput;
};
export type RejectTradeMutation_MutationResponse = {
    readonly tradeReject: ({
        readonly trade: {
            readonly id: string;
            readonly rejection: ({
                readonly createdBy: {
                    readonly id: string;
                };
                readonly createdAt: any;
                readonly reason: string;
            }) | null;
        };
    }) | null;
};
export type RejectTradeMutation_Mutation = {
    readonly response: RejectTradeMutation_MutationResponse;
    readonly variables: RejectTradeMutation_MutationVariables;
};



/*
mutation RejectTradeMutation_Mutation(
  $input: TradeRejectInput!
) {
  tradeReject(input: $input) {
    trade {
      id
      rejection {
        createdBy {
          id
        }
        createdAt
        reason
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "TradeRejectInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "TradeRejectInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "createdBy",
  "storageKey": null,
  "args": null,
  "concreteType": "AuditUser",
  "plural": false,
  "selections": [
    v2
  ]
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "reason",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "RejectTradeMutation_Mutation",
  "id": null,
  "text": "mutation RejectTradeMutation_Mutation(\n  $input: TradeRejectInput!\n) {\n  tradeReject(input: $input) {\n    trade {\n      id\n      rejection {\n        createdBy {\n          id\n        }\n        createdAt\n        reason\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "RejectTradeMutation_Mutation",
    "type": "Mutations",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "tradeReject",
        "storageKey": null,
        "args": v1,
        "concreteType": "TradeRejectPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "trade",
            "storageKey": null,
            "args": null,
            "concreteType": "Trade",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "rejection",
                "storageKey": null,
                "args": null,
                "concreteType": "LatestTradeRejection",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RejectTradeMutation_Mutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "tradeReject",
        "storageKey": null,
        "args": v1,
        "concreteType": "TradeRejectPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "trade",
            "storageKey": null,
            "args": null,
            "concreteType": "Trade",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "rejection",
                "storageKey": null,
                "args": null,
                "concreteType": "LatestTradeRejection",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5,
                  v2
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
(node as any).hash = 'b0d27465cadb34fe1347a41fe40c3149';
export default node;

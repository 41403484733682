import * as React from 'react';
import {withStyles, WithStyles} from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import {EnvironmentProp} from '../../components/RelayRenderer';
import InputInTable from '../../components/InputInTable';
import ItemMenu from './ItemMenu';
import classNames from 'classnames';
import styles from '../../components/TableStyles';
import {Suppliers_viewer} from '../__generated__/Suppliers_viewer.graphql';

interface Props extends WithStyles<typeof styles>, EnvironmentProp {
  openModal: (
    modal:
      | null
      | 'editSupplier'
      | 'addTrader'
      | 'editTrader'
      | 'deleteSupplier'
      | 'deleteTrader',
    modalprops?: {}
  ) => void;
  viewer: Suppliers_viewer;
  refetch: () => void;
}

function CompanyList(props: Props) {
  const {classes, viewer, environment} = props;

  return (
    <div className={classes.table}>
      {viewer &&
        viewer.companies &&
        viewer.companies.edges.map(({node: company}) => {
          const {sharepointIdentifier} = company.info;
          return (
            <table
              key={company.id}
              className={classes.supplierTable}
              cellSpacing={0}
            >
              <tbody>
                <tr className={classNames(classes.headerRow, classes.header)}>
                  <td className={classes.supplierCell}>
                    {company.info.name}
                    {' - '}
                    {sharepointIdentifier ? sharepointIdentifier : 'UNASSIGNED'}
                  </td>
                  <td className={classes.supplierCell}>
                    Trade limit:{' '}
                    <InputInTable
                      placeholder={`${company.tradeBalance.available} / ${
                        company.tradeLimit.limit
                      }`}
                      supplier
                      disabled
                    />{' '}
                  </td>
                  <td className={classes.supplierCell}>
                    Pending balance:{' '}
                    <InputInTable
                      placeholder={company.tradeBalance.pending}
                      supplier
                      disabled
                    />{' '}
                  </td>
                  <td className={classes.supplierCell}>
                    <ItemMenu
                      type={'supplier'}
                      target={company.id}
                      targetName={company.info.name}
                      openModal={props.openModal}
                      refetch={props.refetch}
                      blocked={company.blocked || false}
                      environment={environment}
                      data={{
                        name: company.info.name,
                        regNumber: company.info.registrationNumber,
                        vatNumber: company.info.vatNumber,
                        email: company.info.emailAddress,
                        contact: company.info.contactNumber,
                        primaryLicenseType: company.primaryLicense
                          ? company.primaryLicense.type
                          : undefined,
                        primaryLicenseNumber: company.primaryLicense
                          ? company.primaryLicense.number
                          : undefined,
                        primaryLicenseExpires: company.primaryLicense
                          ? company.primaryLicense.expiry
                          : undefined,
                        otherLicenseType:
                          company.secondaryLicense &&
                          company.secondaryLicense.type,
                        otherLicenseNumber:
                          company.secondaryLicense &&
                          company.secondaryLicense.number,
                        otherLicenseExpires:
                          company.secondaryLicense &&
                          company.secondaryLicense.expiry,
                        tradeLimit: company.tradeLimit.limit,
                        sharepointIdentifier: company.info.sharepointIdentifier,
                      }}
                    />
                  </td>
                </tr>
                {company.users &&
                  company.users.edges.map(({node: user}) => {
                    return (
                      <tr
                        key={user.id}
                        className={classNames(classes.row, classes.traderRow)}
                      >
                        <td colSpan={4} className={classes.traderCell}>
                          <span className={classes.traderCellSpan}>
                            <strong
                              data-tip={
                                user.profile &&
                                user.profile.firstname.length +
                                  user.profile.lastname.length >
                                  35
                                  ? `${user.profile.firstname} ${
                                      user.profile.lastname
                                    }`
                                  : undefined
                              }
                            >
                              {user.profile && user.profile.firstname}{' '}
                              {user.profile && user.profile.lastname}
                            </strong>
                          </span>
                          <span
                            className={classes.traderCellSpan}
                            data-tip={
                              user.profile && user.profile.email.length > 35
                                ? user.profile.email
                                : undefined
                            }
                          >
                            {user.profile && user.profile.email}
                          </span>
                          <span className={classes.traderCellSpan}>
                            {user.profile && user.profile.cellphone}
                          </span>
                          <ItemMenu
                            type={'trader'}
                            target={user.id}
                            targetName={user.username}
                            openModal={props.openModal}
                            userInfo={{...user}}
                            refetch={props.refetch}
                            blocked={user.blocked || false}
                            parentBlocked={company.blocked || false}
                            environment={environment}
                            data={{
                              userId: user.id,
                              companyId: company.id,
                              firstName: user.profile!.firstname,
                              lastName: user.profile!.lastname,
                              emailAddress: user.profile!.email,
                              cellphone: user.profile!.cellphone,
                            }}
                            supplierName={company.info.name}
                          />
                        </td>
                      </tr>
                    );
                  })}
                <tr>
                  <td colSpan={4}>
                    <a
                      className={classes.traderButton}
                      onClick={() =>
                        props.openModal('addTrader', {
                          companyId: company.id,
                          companyName: company.info.name,
                          refetch: props.refetch,
                        })
                      }
                    >
                      Add Trader +
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          );
        })}
      <ReactTooltip className={classes.tooltip} />
    </div>
  );
}

export default withStyles(styles)(CompanyList);

import * as React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {WithStyles, withStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import {graphql, createFragmentContainer} from 'react-relay';
import Moment from 'react-moment';
import Button from '@material-ui/core/Button';
import {Formik, Field} from 'formik';

import {EnvironmentProp} from '../../components/RelayRenderer';
import InputInTable from '../../components/InputInTable';
import styles from '../../components/TableStyles';
import {TransactionsTradeRow_weightTransaction} from './__generated__/TransactionsTradeRow_weightTransaction.graphql';

import {NumberClean, NumberFormat} from '../../components/NumberFormat';
import classNames from 'classnames';
import TradeAdminUpdateMutation from '../mutations/TradeAdminConfirmedUpdateMutation';
import * as Yup from 'yup';
import WeightTransactionEditGoldPercentageMutation from '../mutations/WeightTransactionEditGoldPercentageMutation';
import moment from 'moment';

interface Props extends WithStyles<typeof styles>, EnvironmentProp {
  weightTransaction: TransactionsTradeRow_weightTransaction;
  openModal: (
    modal: null | 'reverseTransaction' | 'editNotes',
    modalprops?: {}
  ) => void;
}

interface State {
  isSubmitting: boolean;
}

const schema = Yup.object().shape({
  usdPerTroyOunce: Yup.string()
    .matches(/(\d{0,3},)?(\d{3},)?\d{0,3}/, 'Please enter a valid number')
    .max(9, 'Value is too high')
    .test(
      'test-is-not-0',
      'Please enter a non-zero value',
      (value) => parseFloat(value) !== 0
    ),
  zarToUsd: Yup.string()
    .matches(/(\d{0,3},)?(\d{3},)?\d{0,3}/, 'Please enter a valid number')
    .max(9, 'Value is too high')
    .test(
      'test-is-not-0',
      'Please enter a non-zero value',
      (value) => parseFloat(value) !== 0
    ),
  reference: Yup.string()
    .max(30, 'Value is too long')
    .nullable(true),
  goldPercentage: Yup.string().nullable(true),
});

const isNonDRC = (transaction: TransactionsTradeRow_weightTransaction) => {
  return (
    transaction.type === 'credit' &&
    moment(transaction.transactionTimestamp).isBefore(
      moment('20220801', 'YYYYMMDD')
    )
  );
};
class TransactionsTradeRow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
  }

  private checkIsDebit() {
    const {weightTransaction} = this.props;

    return (
      weightTransaction.type === 'trade' ||
      weightTransaction.type === 'debit' ||
      weightTransaction.type === 'openingBalance' ||
      weightTransaction.type === 'creditReversal'
    );
  }

  private renderRow(
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void,
    isSubmitting: boolean,
    status: string
  ) {
    const {classes, weightTransaction, openModal} = this.props;

    // Notes can either originate from weightTransaction or Trade
    const notes = weightTransaction.trade
      ? weightTransaction.trade.notes
      : weightTransaction.notes;

    const transactionOrTradeId = weightTransaction.trade
      ? weightTransaction.trade.id
      : weightTransaction.id;

    return (
      <TableRow key={weightTransaction.id} className={classes.row}>
        <TableCell scope="row">
          <Moment format="YYYY-MM-DD h:mm" date={weightTransaction.createdAt} />
        </TableCell>
        <TableCell
          className={classes.notes}
          onClick={async () => {
            openModal('editNotes', {
              title: 'Edit notes',
              notes,
              transactionOrTradeId,
            });
          }}
        >
          <h4 style={{display: 'inline'}}>{notes}</h4>
        </TableCell>
        <TableCell>
          <Moment
            format="YYYY-MM-DD h:mm"
            date={
              weightTransaction.trade
                ? weightTransaction.trade.tradeTimestamp
                : weightTransaction.transactionTimestamp
                ? weightTransaction.transactionTimestamp
                : weightTransaction.createdAt
            }
          />
        </TableCell>
        <TableCell>{weightTransaction.supplier.info.name}</TableCell>
        <TableCell>
          {weightTransaction.trade
            ? weightTransaction.trade.createdBy.displayName
            : weightTransaction.createdBy.displayName}
        </TableCell>
        <TableCell>
          {weightTransaction.trade
            ? weightTransaction.trade.requestedZarPerGram
            : ''}
        </TableCell>
        {weightTransaction.trade && weightTransaction.type === 'trade' ? (
          <TableCell>
            <Field
              name="usdPerTroyOunce"
              label="usdPerTroyOunce"
              type="text"
              component={InputInTable}
            />
          </TableCell>
        ) : (
          <TableCell />
        )}
        <TableCell>
          {weightTransaction.trade && (
            <Field
              name="zarToUsd"
              label="zarToUsd"
              type="text"
              component={InputInTable}
            />
          )}
        </TableCell>
        {weightTransaction.trade ? (
          <TableCell>
            R
            {NumberFormat(
              weightTransaction.trade.confirmation!!.zarPerGram.toFixed(2)
            )}
          </TableCell>
        ) : (
          <TableCell>NA</TableCell>
        )}
        <TableCell>
          {weightTransaction.trade ? (
            <Field
              name="reference"
              label="Reference Nr"
              type="text"
              component={InputInTable}
              placeholder={
                weightTransaction.trade.refNumber
                  ? weightTransaction.trade.refNumber
                  : ''
              }
            />
          ) : (
            <div>{weightTransaction.pcCode}</div>
          )}
        </TableCell>
        <TableCell>
          {this.checkIsDebit() ? (
            <div>{NumberFormat(weightTransaction.weight)} g</div>
          ) : (
            ''
          )}
        </TableCell>
        <TableCell>
          {!this.checkIsDebit() ? (
            <div>{NumberFormat(weightTransaction.weight)} g</div>
          ) : (
            ''
          )}
        </TableCell>
        <TableCell>
          {weightTransaction.type === 'credit' ? (
            isNonDRC(weightTransaction) ? (
              'non-DRC'
            ) : (
              <Field
                name="goldPercentage"
                label="goldPercentage"
                type="text"
                component={InputInTable}
              />
            )
          ) : (
            ''
          )}
        </TableCell>
        <TableCell>
          <strong>{NumberFormat(weightTransaction.rollingBalance)} g</strong>
        </TableCell>
        <TableCell className={classes.receiptedButtonContainer}>
          <Tooltip
            title={
              weightTransaction.reversed !== null
                ? 'Transaction has been reversed'
                : weightTransaction.type === 'debitReversal' ||
                  weightTransaction.type === 'creditReversal'
                ? 'Reversal transaction types cannot be reversed'
                : ''
            }
            aria-label="Add"
            placement="top"
          >
            <div style={{display: 'inline-block'}}>
              <Button
                variant="contained"
                className={classNames(classes.button, classes.buttonDecline)}
                onClick={async () =>
                  openModal('reverseTransaction', {
                    transactionId: weightTransaction.id,
                    transactionTimestamp:
                      weightTransaction.transactionTimestamp,
                  })
                }
                disabled={
                  isSubmitting ||
                  weightTransaction.type === 'trade' ||
                  weightTransaction.reversed !== null ||
                  weightTransaction.type === 'debitReversal' ||
                  weightTransaction.type === 'creditReversal'
                }
              >
                Reverse
              </Button>
            </div>
          </Tooltip>
          <Button
            variant="contained"
            className={classNames(classes.button, classes.buttonAccept)}
            onClick={() => {
              handleSubmit();
            }}
            disabled={
              isSubmitting
              || (weightTransaction.type === 'credit' && isNonDRC(weightTransaction))
              
            }
          >
            Update
          </Button>
          {status !== '' && (
            <span
              className={`${classes.notification} ${
                status !== 'Transaction details successfully updated'
                  ? 'error'
                  : ''
              }`}
            >
              {status}
            </span>
          )}
        </TableCell>
      </TableRow>
    );
  }

  public render() {
    const {weightTransaction, environment} = this.props;
    return (
      <Formik
        onSubmit={async (values, {setSubmitting, setStatus}) => {
          setSubmitting(true);
          setStatus(null);

          try {
            if (weightTransaction.trade) {
              await TradeAdminUpdateMutation(environment, {
                input: {
                  zarPerTroyOunce:
                    NumberClean(values.usdPerTroyOunce || '0') *
                    NumberClean(values.zarToUsd),
                  zarToUsd: NumberClean(values.zarToUsd || '0'),
                  reference: values.reference !== null ? values.reference : '',
                  tradeId: weightTransaction.trade!!.id,
                },
              });
            } else {
              await WeightTransactionEditGoldPercentageMutation(environment, {
                input: {
                  id: weightTransaction.id,
                  goldPercentage: Number.parseFloat(
                    values.goldPercentage.toString()
                  ),
                },
              });
            }

            setStatus('Transaction details successfully updated');
            setSubmitting(false);
          } catch (ex) {
            setStatus(ex.message);
            setSubmitting(false);
          }
        }}
        validationSchema={schema}
        initialValues={{
          reference: weightTransaction.trade
            ? weightTransaction.trade.refNumber
            : '',
          usdPerTroyOunce: weightTransaction.trade
            ? weightTransaction.trade.confirmation!!.usdPerTroyOunce.toFixed(2)
            : '',
          zarToUsd: weightTransaction.trade
            ? weightTransaction.trade.confirmation!!.zarToUsd.toFixed(2)
            : '',
          goldPercentage: weightTransaction.goldPercentage || '',
        }}
        render={({handleSubmit, isSubmitting, status}) => {
          return this.renderRow(handleSubmit, isSubmitting, status);
        }}
      />
    );
  }
}

export default createFragmentContainer(
  withStyles(styles)(TransactionsTradeRow),
  {
    weightTransaction: graphql`
      fragment TransactionsTradeRow_weightTransaction on WeightTransaction {
        id
        weight
        goldPercentage
        type
        rollingBalance
        notes
        pcCode
        reversed
        supplier {
          id
          info {
            name
          }
        }
        createdAt
        createdBy {
          id
          displayName
        }
        transactionTimestamp
        trade {
          id
          refNumber
          tradeTimestamp
          status
          weight
          requestedZarPerGram
          notes
          createdBy {
            id
            displayName
          }
          confirmation {
            eventTimestamp
            usdPerTroyOunce
            zarToUsd
            zarPerGram
          }
        }
      }
    `,
  }
);

/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
type NewTradeModal_viewer$ref = any;
export type NewTradeModalQueryVariables = {};
export type NewTradeModalQueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": NewTradeModal_viewer$ref;
    };
};
export type NewTradeModalQuery = {
    readonly response: NewTradeModalQueryResponse;
    readonly variables: NewTradeModalQueryVariables;
};



/*
query NewTradeModalQuery {
  viewer {
    ...NewTradeModal_viewer
  }
}

fragment NewTradeModal_viewer on Viewer {
  currentTradePrice {
    zarPerTroyOunce
    usdPerTroyOunce
    zarToUsd
    id
  }
  companies {
    edges {
      node {
        runningBalance
        tradeLimit {
          limit
        }
        id
        info {
          name
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "NewTradeModalQuery",
  "id": null,
  "text": "query NewTradeModalQuery {\n  viewer {\n    ...NewTradeModal_viewer\n  }\n}\n\nfragment NewTradeModal_viewer on Viewer {\n  currentTradePrice {\n    zarPerTroyOunce\n    usdPerTroyOunce\n    zarToUsd\n    id\n  }\n  companies {\n    edges {\n      node {\n        runningBalance\n        tradeLimit {\n          limit\n        }\n        id\n        info {\n          name\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "NewTradeModalQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "viewer",
        "name": "__viewer_viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "NewTradeModal_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "NewTradeModalQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "currentTradePrice",
            "storageKey": null,
            "args": null,
            "concreteType": "TradePrice",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "zarPerTroyOunce",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "usdPerTroyOunce",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "zarToUsd",
                "args": null,
                "storageKey": null
              },
              v0
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "companies",
            "storageKey": null,
            "args": null,
            "concreteType": "ViewerCompaniesConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ViewerCompaniesEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Company",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "runningBalance",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "tradeLimit",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CompanyTradeLimit",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "limit",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      v0,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "info",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CompanyInfo",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "name",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "viewer",
        "args": null,
        "handle": "viewer",
        "key": "",
        "filters": null
      }
    ]
  }
};
})();
(node as any).hash = 'afa2b49a440561099399b5af8b81d729';
export default node;

import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {MenuItem, Typography, WithStyles} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import {Field, Form, FormikProps} from 'formik';
import {TextField} from 'formik-material-ui';
import React from 'react';
import Spinner from 'react-spinkit';
import ReactTooltip from 'react-tooltip';
import * as Yup from 'yup';
import styles from './ModalStyles';

interface Values {
  company: string;
  regNumber: string;
  vatNumber: string;
  email: string;
  contact: string;
  primaryLicenseType?: string;
  primaryLicenseNumber?: string;
  primaryLicenseExpires?: string;
  otherLicenseType?: string;
  otherLicenseNumber?: string;
  otherLicenseExpires?: string;
  tradeLimit: number;
  sharepointIdentifier?: string;
}

const licenseTypes: Array<{key: string; value: string | undefined}> = [
  {key: 'Refining Licence', value: 'Refining Licence'},
  {key: 'Mining Licence', value: 'Mining Licence'},
  {key: 'Beneficiation Licence', value: 'Beneficiation Licence'},
  {key: 'None', value: 'None'},
];

interface Props extends FormikProps<Values>, WithStyles<typeof styles> {
  buttonTitle: string;
}

export const schema = Yup.object().shape({
  company: Yup.string()
    .required('Required')
    .max(100, 'Value is too long'),
  regNumber: Yup.string()
    .required('Required')
    .max(30, 'Value is too long'),
  vatNumber: Yup.string()
    .required('Required')
    .max(30, 'Value is too long'),
  email: Yup.string()
    .email()
    .required('Required')
    .max(100, 'Value is too long'),
  contact: Yup.string()
    .required('Required')
    .matches(/^0{1}(\d[\s]*){9}$/, 'Invalid phone number format'),
  primaryLicenseType: Yup.string().required('Required'),
  primaryLicenseNumber: Yup.string().when('primaryLicenseType', {
    is: (primaryLicenseType) => {
      return primaryLicenseType !== 'None';
    },
    then: Yup.string()
      .required('Required')
      .max(60, 'Value is too long'),
    otherwise: Yup.string(),
  }),
  primaryLicenseExpires: Yup.string().when('primaryLicenseType', {
    is: (primaryLicenseType) => {
      return primaryLicenseType === 'None';
    },
    then: Yup.string(),
    otherwise: Yup.string()
      .notOneOf(['yyyy/mm/dd'], 'Please select a date')
      .required('Required'),
  }),

  otherLicenseType: Yup.string(),
  otherLicenseNumber: Yup.string()
    .max(60, 'Value is too long')
    .when('otherLicenseType', {
      is: (value) => value !== 'None',
      then: Yup.string().required(
        'Please complete all required license fields'
      ),
      otherwise: Yup.string(),
    }),
  otherLicenseExpires: Yup.string().when('otherLicenseType', {
    is: (value) => value === 'None',
    then: Yup.string(),
    otherwise: Yup.string()
      .notOneOf(['yyyy/mm/dd'], 'Please select a date')
      .required('Required'),
  }),
  sharePointId: Yup.string(),
  tradeLimit: Yup.number()
    .required('Required')
    .max(9999999999, 'Trade limits this large are not allowed'),
});

function SupplierFields({
  buttonTitle,
  values,
  setFieldValue,
  handleSubmit,
  isSubmitting,
  status,
  classes,
}: Props) {
  return (
    <Form>
      <Field
        InputLabelProps={{shrink: true}}
        name="company"
        label="Supplier Company Name"
        type="text"
        component={TextField}
        style={{width: '100%'}}
      />
      <br />
      <Field
        InputLabelProps={{shrink: true}}
        name="regNumber"
        label="Registration Number"
        type="text"
        component={TextField}
        style={{width: '100%', margin: '10px 0 0'}}
      />
      <br />
      <Field
        InputLabelProps={{shrink: true}}
        name="vatNumber"
        label="VAT Number"
        type="text"
        component={TextField}
        style={{width: '100%', margin: '10px 0 0'}}
      />
      <br />
      <Field
        InputLabelProps={{shrink: true}}
        name="email"
        label="Email Address"
        type="text"
        component={TextField}
        style={{width: '100%', margin: '10px 0 0'}}
      />
      <br />
      <Field
        InputLabelProps={{shrink: true}}
        name="contact"
        label="Contact Number"
        type="text"
        component={TextField}
        style={{width: '100%', margin: '10px 0 0'}}
        placeholder={'080 000 00 00'}
      />
      <br />
      <Field
        InputLabelProps={{shrink: true}}
        name="primaryLicenseType"
        label="Primary License Type"
        type="text"
        component={TextField}
        style={{width: '100%', margin: '10px 0 0'}}
        select
        placeholder="None"
        InputProps={{
          //   value: '',
          onChange: (
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setFieldValue('primaryLicenseType', e.target.value);
            setFieldValue('primaryLicenseExpires', 'yyyy/mm/dd');
            setFieldValue('primaryLicenseNumber', '');
            if (e.target.value === 'None') {
              setFieldValue('otherLicenseType', 'None');
              setFieldValue('otherLicenseNumber', '');
              setFieldValue('otherLicenseExpires', 'yyyy/mm/dd');
            }
          },
        }}
      >
        {licenseTypes.map(({key, value}) => (
          <MenuItem key={key} value={value}>
            {key}
          </MenuItem>
        ))}
      </Field>
      <br />
      {values.primaryLicenseType && values.primaryLicenseType !== 'None' && (
        <>
          <Field
            InputLabelProps={{shrink: true}}
            name="primaryLicenseNumber"
            label="Primary License Number"
            type="text"
            component={TextField}
            style={{width: '100%', margin: '10px 0 0'}}
          />
          <br />
          <Field
            InputLabelProps={{shrink: true}}
            name="primaryLicenseExpires"
            label="Primary License Expiry Date"
            type="date"
            component={TextField}
            style={{width: '100%', margin: '10px 0 0'}}
          />
          <br />
        </>
      )}
      <Field
        InputLabelProps={{shrink: true}}
        name="otherLicenseType"
        label="Other License Type"
        type="text"
        component={TextField}
        style={{width: '100%', margin: '10px 0 0'}}
        select
        InputProps={{
          onChange: (
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setFieldValue('otherLicenseType', e.target.value);
            setFieldValue('otherLicenseNumber', '');
            setFieldValue('otherLicenseExpires', 'yyyy/mm/dd');
          },
        }}
        value={values.otherLicenseType}
      >
        {licenseTypes.map(({key, value}) => (
          <MenuItem key={key} value={value}>
            {key}
          </MenuItem>
        ))}
      </Field>
      <br />
      {values.otherLicenseType && values.otherLicenseType !== 'None' && (
        <>
          <Field
            InputLabelProps={{shrink: true}}
            name="otherLicenseNumber"
            label="Other License Number"
            type="text"
            component={TextField}
            style={{width: '100%', margin: '10px 0 0'}}
            disabled={!values.otherLicenseType}
            data-tip={
              values.otherLicenseType === undefined
                ? 'Please select License type first.'
                : undefined
            }
          />
          <br />
          <Field
            InputLabelProps={{shrink: true}}
            name="otherLicenseExpires"
            label="Other License Expires"
            type="date"
            component={TextField}
            style={{width: '100%', margin: '10px 0 0'}}
            disabled={!values.otherLicenseType}
            data-tip={
              !values.otherLicenseType
                ? 'Please select License type first.'
                : undefined
            }
          />
        </>
      )}
      <Field
        InputLabelProps={{shrink: true}}
        name="sharepointIdentifier"
        label="Sharepoint ID"
        type="text"
        component={TextField}
        style={{width: '100%', margin: '10px 0 0'}}
      />
      <br />
      <div style={{position: 'relative'}}>
        <Field
          InputLabelProps={{shrink: true}}
          name="tradeLimit"
          label="Trade Limit"
          type="text"
          component={TextField}
          style={{width: '100%', margin: '10px 0 0'}}
        />
        <span className={classes.gramHigh}>g</span>
      </div>
      <br />
      <Button
        variant="contained"
        color="primary"
        type="submit"
        style={{width: '100%', margin: '30px 0 10px'}}
        className={classes.button}
        onClick={() => handleSubmit()}
        disabled={isSubmitting}
      >
        {isSubmitting ? (
          <Spinner name="circle" color="white" style={{margin: '0 auto'}} />
        ) : (
          buttonTitle
        )}
        <FontAwesomeIcon
          icon={faChevronRight}
          style={{fontSize: '0.875rem', paddingLeft: '10px'}}
        />
      </Button>
      {status && <Typography className={classes.error}>{status}</Typography>}
      <ReactTooltip className={classes.tooltip} />
    </Form>
  );
}

export default withStyles(styles)(SupplierFields);

import {graphql} from 'react-relay';

import {createMutation} from '@stackworx/relay';

import {
  WeightTransactionEditNotesMutationResponse,
  WeightTransactionEditNotesMutationVariables,
} from './__generated__/WeightTransactionEditNotesMutation.graphql';

const mutation = graphql`
  mutation WeightTransactionEditNotesMutation(
    $input: WeightTransactionNotesEditInput!
  ) {
    weightTransactionEditNotes(input: $input) {
      clientMutationId
      id
      notes
    }
  }
`;

export default createMutation<
  WeightTransactionEditNotesMutationVariables,
  WeightTransactionEditNotesMutationResponse
>(mutation, {
  updater: (store, data) => {
    // @ts-ignore
    const weightTransactionOrNote = store.get(data.id);

    if (weightTransactionOrNote) {
      // @ts-ignore
      weightTransactionOrNote.setValue(data.notes, 'notes');
    }
  },
});

import {graphql} from 'react-relay';

import {createMutation} from '@stackworx/relay';

import {
  CompanyDeactivateMutation_MutationResponse,
  CompanyDeactivateMutation_MutationVariables,
} from './__generated__/CompanyDeactivateMutation_Mutation.graphql';

const mutation = graphql`
  mutation CompanyDeactivateMutation_Mutation($input: CompanyDeactivateInput!) {
    companyDeactivate(input: $input) {
      clientMutationId
    }
  }
`;

export default createMutation<
  CompanyDeactivateMutation_MutationVariables,
  CompanyDeactivateMutation_MutationResponse
>(mutation);

/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type TradeStatus = "confirmed" | "delivered" | "pending" | "rejected" | "%future added value";
export type TradeAdminConfirmedUpdateInput = {
    readonly tradeId: string;
    readonly reference?: string | null;
    readonly zarPerTroyOunce: number;
    readonly zarToUsd: number;
    readonly clientMutationId?: string | null;
};
export type TradeAdminConfirmedUpdateMutationVariables = {
    readonly input: TradeAdminConfirmedUpdateInput;
};
export type TradeAdminConfirmedUpdateMutationResponse = {
    readonly tradeAdminConfirmedUpdate: ({
        readonly clientMutationId: string | null;
        readonly trade: {
            readonly id: string;
            readonly refNumber: string | null;
            readonly tradeTimestamp: any;
            readonly status: TradeStatus;
            readonly weight: number;
            readonly notes: string | null;
            readonly requestedZarPerGram: number;
            readonly createdBy: {
                readonly id: string;
                readonly displayName: string | null;
            };
            readonly confirmation: ({
                readonly eventTimestamp: any;
                readonly usdPerTroyOunce: number;
                readonly zarToUsd: number;
                readonly zarPerGram: number;
            }) | null;
        };
    }) | null;
};
export type TradeAdminConfirmedUpdateMutation = {
    readonly response: TradeAdminConfirmedUpdateMutationResponse;
    readonly variables: TradeAdminConfirmedUpdateMutationVariables;
};



/*
mutation TradeAdminConfirmedUpdateMutation(
  $input: TradeAdminConfirmedUpdateInput!
) {
  tradeAdminConfirmedUpdate(input: $input) {
    clientMutationId
    trade {
      id
      refNumber
      tradeTimestamp
      status
      weight
      notes
      requestedZarPerGram
      createdBy {
        id
        displayName
      }
      confirmation {
        eventTimestamp
        usdPerTroyOunce
        zarToUsd
        zarPerGram
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "TradeAdminConfirmedUpdateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "TradeAdminConfirmedUpdateInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "clientMutationId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "refNumber",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tradeTimestamp",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "weight",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "notes",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "requestedZarPerGram",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "createdBy",
  "storageKey": null,
  "args": null,
  "concreteType": "AuditUser",
  "plural": false,
  "selections": [
    v3,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "displayName",
      "args": null,
      "storageKey": null
    }
  ]
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "eventTimestamp",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "usdPerTroyOunce",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "zarToUsd",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "zarPerGram",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "TradeAdminConfirmedUpdateMutation",
  "id": null,
  "text": "mutation TradeAdminConfirmedUpdateMutation(\n  $input: TradeAdminConfirmedUpdateInput!\n) {\n  tradeAdminConfirmedUpdate(input: $input) {\n    clientMutationId\n    trade {\n      id\n      refNumber\n      tradeTimestamp\n      status\n      weight\n      notes\n      requestedZarPerGram\n      createdBy {\n        id\n        displayName\n      }\n      confirmation {\n        eventTimestamp\n        usdPerTroyOunce\n        zarToUsd\n        zarPerGram\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TradeAdminConfirmedUpdateMutation",
    "type": "Mutations",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "tradeAdminConfirmedUpdate",
        "storageKey": null,
        "args": v1,
        "concreteType": "TradeAdminConfirmedUpdatePayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "trade",
            "storageKey": null,
            "args": null,
            "concreteType": "Trade",
            "plural": false,
            "selections": [
              v3,
              v4,
              v5,
              v6,
              v7,
              v8,
              v9,
              v10,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "confirmation",
                "storageKey": null,
                "args": null,
                "concreteType": "LatestTradeConfirmation",
                "plural": false,
                "selections": [
                  v11,
                  v12,
                  v13,
                  v14
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TradeAdminConfirmedUpdateMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "tradeAdminConfirmedUpdate",
        "storageKey": null,
        "args": v1,
        "concreteType": "TradeAdminConfirmedUpdatePayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "trade",
            "storageKey": null,
            "args": null,
            "concreteType": "Trade",
            "plural": false,
            "selections": [
              v3,
              v4,
              v5,
              v6,
              v7,
              v8,
              v9,
              v10,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "confirmation",
                "storageKey": null,
                "args": null,
                "concreteType": "LatestTradeConfirmation",
                "plural": false,
                "selections": [
                  v11,
                  v12,
                  v13,
                  v14,
                  v3
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
(node as any).hash = 'f17b407ed59ebbcfd26974d37f6a8ca0';
export default node;

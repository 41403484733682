/* tslint:disable */

import { ConcreteFragment } from "relay-runtime";
export type TradeStatus = "confirmed" | "delivered" | "pending" | "rejected" | "%future added value";
export type WeightTransactionType = "credit" | "creditReversal" | "debit" | "debitReversal" | "openingBalance" | "trade" | "%future added value";
export type TransactionsTradeRow_weightTransaction$ref = any;
export type TransactionsTradeRow_weightTransaction = {
    readonly id: string;
    readonly weight: number;
    readonly goldPercentage: number | null;
    readonly type: WeightTransactionType | null;
    readonly rollingBalance: number;
    readonly notes: string | null;
    readonly pcCode: string | null;
    readonly reversed: number | null;
    readonly supplier: {
        readonly id: string;
        readonly info: {
            readonly name: string;
        };
    };
    readonly createdAt: any;
    readonly createdBy: {
        readonly id: string;
        readonly displayName: string | null;
    };
    readonly transactionTimestamp: any | null;
    readonly trade: ({
        readonly id: string;
        readonly refNumber: string | null;
        readonly tradeTimestamp: any;
        readonly status: TradeStatus;
        readonly weight: number;
        readonly requestedZarPerGram: number;
        readonly notes: string | null;
        readonly createdBy: {
            readonly id: string;
            readonly displayName: string | null;
        };
        readonly confirmation: ({
            readonly eventTimestamp: any;
            readonly usdPerTroyOunce: number;
            readonly zarToUsd: number;
            readonly zarPerGram: number;
        }) | null;
    }) | null;
    readonly " $refType": TransactionsTradeRow_weightTransaction$ref;
};



const node: ConcreteFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "weight",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "notes",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "createdBy",
  "storageKey": null,
  "args": null,
  "concreteType": "AuditUser",
  "plural": false,
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "displayName",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Fragment",
  "name": "TransactionsTradeRow_weightTransaction",
  "type": "WeightTransaction",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    v1,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "goldPercentage",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "type",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "rollingBalance",
      "args": null,
      "storageKey": null
    },
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "pcCode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "reversed",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "supplier",
      "storageKey": null,
      "args": null,
      "concreteType": "Company",
      "plural": false,
      "selections": [
        v0,
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "info",
          "storageKey": null,
          "args": null,
          "concreteType": "CompanyInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "name",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "createdAt",
      "args": null,
      "storageKey": null
    },
    v3,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "transactionTimestamp",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "trade",
      "storageKey": null,
      "args": null,
      "concreteType": "Trade",
      "plural": false,
      "selections": [
        v0,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "refNumber",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "tradeTimestamp",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "status",
          "args": null,
          "storageKey": null
        },
        v1,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "requestedZarPerGram",
          "args": null,
          "storageKey": null
        },
        v2,
        v3,
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "confirmation",
          "storageKey": null,
          "args": null,
          "concreteType": "LatestTradeConfirmation",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "eventTimestamp",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "usdPerTroyOunce",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "zarToUsd",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "zarPerGram",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
(node as any).hash = '583459999b01f4196d173dacacb2c026';
export default node;

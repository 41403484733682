import * as React from 'react';
import classNames from 'classnames';
import {withStyles, createStyles, WithStyles} from '@material-ui/core/styles';
import {Theme, Button} from '@material-ui/core';
import {faEllipsisH} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Spinner from 'react-spinkit';

import UserBlockMutation from './mutations/UserBlockMutation';
import UserUnblockMutation from './mutations/UserUnblockMutation';
import CompanyBlockMutation from './mutations/CompanyBlockMutation';
import CompanyUnblockMutation from './mutations/CompanyUnblockMutation';
import {EnvironmentProp} from '../../components/RelayRenderer';
import ReactTooltip from 'react-tooltip';

const styles = (_theme: Theme) =>
  createStyles({
    root: {
      float: 'right',
      display: 'inline-block',
      justifySelf: 'flex-end',
      textAlign: 'right',
      padding: '0 10px',
    },
    icon: {
      transition: '0.4s',
      cursor: 'pointer',
    },
    open: {
      transform: 'rotate(90deg)',
    },
    child: {
      overflow: 'hidden',
      transition: '0.4s',
      padding: '0 10px',
      opacity: 0,
      lineHeight: '26px',
    },
    openChild: {
      overflow: 'visible',
      opacity: 1,
      lineHeight: '26px',
      display: 'inline',
    },
    button: {
      textTransform: 'unset',
      '& > span': {
        padding: 0,
      },
      padding: '0 !important',
      verticalAlign: 'baseline',
      '&.supplier': {
        minHeight: 24,
      },
      '&.trader': {
        minHeight: 16,
        lineHeight: '16px',
        fontSize: '12px',
      },
    },
    error: {
      color: 'red',
    },
    tooltip: {
      maxWidth: '400px !important',
    },
    disabled: {
      color: '#00000042',
      backgroundColor: 'transparent !important',
      cursor: 'pointer',
    },
  });

interface Props extends WithStyles<typeof styles>, EnvironmentProp {
  type: 'trader' | 'supplier';
  target: string;
  targetName?: string;
  openModal: (
    modal:
      | null
      | 'editSupplier'
      | 'editTrader'
      | 'deleteSupplier'
      | 'deleteTrader',
    modalprops?: {}
  ) => void;
  /*TODO: type this out*/
  userInfo?: {};
  refetch: () => void;
  data: {};
  blocked: boolean;
  supplierName?: string;
  parentBlocked?: boolean;
}

interface State {
  open: boolean;
  error: string;
  isLoading: boolean;
}

class ItemMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      error: '',
      isLoading: false,
    };
  }

  public handleBlockClick = async () => {
    const {environment, target, refetch} = this.props;
    this.setState({error: '', isLoading: true});
    try {
      if (this.props.type === 'trader') {
        if (this.props.blocked) {
          await UserUnblockMutation(environment, {
            input: {
              userId: target,
            },
          });
        } else {
          await UserBlockMutation(environment, {
            input: {
              userId: target,
            },
          });
        }
      } else {
        if (this.props.blocked) {
          await CompanyUnblockMutation(environment, {
            input: {
              companyId: target,
            },
          });
        } else {
          await CompanyBlockMutation(environment, {
            input: {
              companyId: target,
            },
          });
        }
      }
      refetch();
    } catch (e) {
      this.setState({error: e.message, isLoading: false});
    }
  };

  public render() {
    const {
      classes,
      type,
      target,
      targetName,
      openModal,
      refetch,
      data,
      blocked,
      supplierName,
      parentBlocked,
    } = this.props;
    const {open, error, isLoading} = this.state;
    return (
      <span className={classes.root}>
        {open && (
          <span className={classNames(classes.child, classes.openChild)}>
            {error && <div className={classes.error}>error</div>}
            <Button
              className={
                type === 'trader'
                  ? classNames(classes.button, 'trader')
                  : classNames(classes.button, 'supplier')
              }
              onClick={() =>
                openModal(
                  type === 'trader' ? 'editTrader' : 'editSupplier',
                  type === 'trader'
                    ? {
                        userId: target,
                        userName: targetName,
                        refetch,
                        data,
                      }
                    : {
                        supplierId: target,
                        supplierName: targetName,
                        refetch,
                        data,
                      }
                )
              }
            >
              Edit
            </Button>
            <Button
              className={
                type === 'trader'
                  ? classNames(
                      classes.button,
                      'trader',
                      parentBlocked && classes.disabled
                    )
                  : classNames(
                      classes.button,
                      'supplier',
                      parentBlocked && classes.disabled
                    )
              }
              onClick={() => (parentBlocked ? false : this.handleBlockClick())}
              data-tip={
                parentBlocked
                  ? 'Please lift the supplier-wide block before attempting to unblock individual traders.'
                  : undefined
              }
            >
              {isLoading ? (
                <Spinner
                  name="circle"
                  color="black"
                  // @ts-ignore
                  style={{margin: '0 auto'}}
                />
              ) : blocked ? (
                'Unblock'
              ) : (
                'Block'
              )}
            </Button>
            <ReactTooltip className={classes.tooltip} />
            <Button
              className={
                type === 'trader'
                  ? classNames(classes.button, 'trader')
                  : classNames(classes.button, 'supplier')
              }
              onClick={() =>
                openModal(
                  type === 'trader' ? 'deleteTrader' : 'deleteSupplier',
                  type === 'trader'
                    ? {
                        traderId: target,
                        traderName: targetName,
                        supplierName,
                        refetch,
                      }
                    : {
                        supplierId: target,
                        supplierName: targetName,
                        refetch,
                      }
                )
              }
            >
              Delete
            </Button>
          </span>
        )}

        <span onClick={() => this.setState({open: !open})}>
          <FontAwesomeIcon
            icon={faEllipsisH}
            className={
              open ? classNames(classes.icon, classes.open) : classes.icon
            }
          />
        </span>
      </span>
    );
  }
}

export default withStyles(styles)(ItemMenu);

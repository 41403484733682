import * as React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import isEqual from 'react-fast-compare';
import {WithStyles, withStyles} from '@material-ui/core/styles';
import {
  createPaginationContainer,
  graphql,
  RelayPaginationProp,
} from 'react-relay';

import {
  createRelayRenderer,
  EnvironmentProp,
} from '../../components/RelayRenderer';
import styles from '../../components/TableStyles';
import DeclinedTradeRow from './DeclinedTradeRow';
import {DeclinedTable_viewer} from './__generated__/DeclinedTable_viewer.graphql';
import TradeReloadContext from '../contexts/TradeReloadContext';
import Loading from '../../components/Loading';
import {Filters} from '../types';
import Error from '../../components/Error';

interface Props extends WithStyles<typeof styles>, EnvironmentProp {
  viewer: DeclinedTable_viewer;
  relay: RelayPaginationProp;
  openModal: (modal: null | 'text', modalprops?: {}) => void;
  filters: Filters;
}

interface State {
  isLoading: boolean;
  error?: Error;
}

const TOTAL = 20;

class DeclinedTable extends React.Component<Props, State> {
  public static contextType = TradeReloadContext;
  private handlerRef: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  private loadMore() {
    if (!this.props.relay.hasMore() || this.props.relay.isLoading()) {
      return;
    }

    this.props.relay.loadMore(
      10, // Fetch the next 10 feed items
      (error) => {
        this.setState({error});
      }
    );
  }

  public componentDidMount() {
    this.handlerRef = this.context.addListener(() => this.refetchConnection());
  }

  public componentWillUnmount() {
    if (this.handlerRef) {
      this.handlerRef();
    }
  }

  public componentWillReceiveProps(nextProps: Props) {
    if (!isEqual(nextProps.filters, this.props.filters)) {
      this.setState({isLoading: true, error: undefined});
      this.props.relay.refetchConnection(
        TOTAL,
        (error) => {
          this.setState({error, isLoading: false});
        },
        nextProps.filters
      );
    }
  }

  private refetchConnection() {
    this.setState({isLoading: true, error: undefined});
    this.props.relay.refetchConnection(
      TOTAL,
      (error) => {
        this.setState({error, isLoading: false});
      },
      this.props.filters
    );
  }

  public render() {
    const {classes, viewer, relay, openModal} = this.props;
    const {isLoading, error} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <>
        {error && <Error error={error} />}
        <Table className={classes.table}>
          <TableHead className={classes.header}>
            <TableRow className={classes.headerRow}>
              <TableCell className={classes.headerCell}>Requested</TableCell>
              <TableCell className={classes.headerCell}>Declined</TableCell>
              <TableCell className={classes.headerCell}>Reason</TableCell>
              <TableCell className={classes.headerCell}>Supplier</TableCell>
              <TableCell className={classes.headerCell}>Trader</TableCell>
              <TableCell className={classes.headerCell}>
                Requested (g)
              </TableCell>
              <TableCell className={classes.headerCell}>
                Requested Spot Price (ZAR/g)
              </TableCell>
              <TableCell className={classes.headerCell}>
                Metal Price (USD/oz)
              </TableCell>
              <TableCell className={classes.headerCell}>
                Exchange Rate (ZAR/USD)
              </TableCell>
              <TableCell
                className={classNames(classes.headerCell, classes.headerLast)}
              >
                Confirmed Spot Price (ZAR/g)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {viewer.trades &&
              viewer.trades.edges.map(({node}) => {
                return (
                  <DeclinedTradeRow
                    trade={node as any}
                    key={node.id}
                    openModal={openModal}
                  />
                );
              })}
            {viewer.trades && viewer.trades.edges.length === 0 && (
              <TableRow>
                <TableCell colSpan={13}>No results found.</TableCell>
              </TableRow>
            )}
          </TableBody>
          {relay.hasMore() && (
            <Button onClick={() => this.loadMore()} className={classes.button}>
              Load More
            </Button>
          )}
        </Table>
      </>
    );
  }
}

const query = graphql`
  query DeclinedTableQuery(
    $count: Int!
    $cursor: String
    $supplierIds: [ID!]
    $traderIds: [ID!]
    $startDate: DateTime
    $endDate: DateTime
  ) {
    viewer {
      ...DeclinedTable_viewer
        @arguments(
          count: $count
          cursor: $cursor
          supplierIds: $supplierIds
          traderIds: $traderIds
          startDate: $startDate
          endDate: $endDate
        )
    }
  }
`;

const DeclinedTablePagination = createPaginationContainer(
  DeclinedTable,
  {
    viewer: graphql`
      fragment DeclinedTable_viewer on Viewer
        @argumentDefinitions(
          count: {type: "Int", defaultValue: 10}
          cursor: {type: "String"}
          supplierIds: {type: "[ID!]", list: true}
          traderIds: {type: "[ID!]", list: true}
          startDate: {type: "DateTime"}
          endDate: {type: "DateTime"}
        ) {
        trades(
          first: $count
          after: $cursor
          status: rejected
          supplierIds: $supplierIds
          traderIds: $traderIds
          from: $startDate
          to: $endDate
        ) @connection(key: "ViewerDeclined_trades", filters: ["supplierIds"]) {
          total
          edges {
            node {
              id
              ...DeclinedTradeRow_trade
            }
          }
        }
      }
    `,
  },
  {
    getVariables(_props, {count, cursor}, fragmentVariables) {
      return {
        count,
        cursor,
        ...fragmentVariables,
      };
    },
    query,
  }
);

export default withStyles(styles)(
  createRelayRenderer({
    container: DeclinedTablePagination,
    query,
    variables: {
      count: TOTAL,
    },
  })
);

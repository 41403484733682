import React from 'react';
import {withStyles, WithStyles} from '@material-ui/core/styles';
import styles from './InputInTableStyles';
import {Input} from '@material-ui/core';

interface Props extends WithStyles<typeof styles> {
  placeholder: any;
  disabled?: boolean;
  field?: any;
  form?: any;
  supplier?: boolean;
  type?: string;
}

function InputInTable({
  classes,
  disabled = false,
  field,
  form,
  supplier,
  type,
  ...atts
}: Props) {
  const name = field && field.name ? field.name : null;
  const nameCheck = name === 'gramsRequested' ? true : false;
  return (
    <div className={classes.root}>
      <input
        className={`${classes.tableInput} ${
          supplier ? classes.supplierInput : ''
        }`}
        disabled={disabled}
        readOnly={disabled}
        {...atts}
        {...field}
      />
      {form && form.errors && form.errors[name] && (
        <span className={classes.error}>{form.errors[name]}</span>
      )}
    </div>
  );
}

export default withStyles(styles)(InputInTable);

/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type CompanyUnblockInput = {
    readonly effectiveDateTime?: any | null;
    readonly companyId: string;
    readonly clientMutationId?: string | null;
};
export type CompanyUnblockMutation_MutationVariables = {
    readonly input: CompanyUnblockInput;
};
export type CompanyUnblockMutation_MutationResponse = {
    readonly companyUnblock: ({
        readonly clientMutationId: string | null;
    }) | null;
};
export type CompanyUnblockMutation_Mutation = {
    readonly response: CompanyUnblockMutation_MutationResponse;
    readonly variables: CompanyUnblockMutation_MutationVariables;
};



/*
mutation CompanyUnblockMutation_Mutation(
  $input: CompanyUnblockInput!
) {
  companyUnblock(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CompanyUnblockInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "companyUnblock",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "CompanyUnblockInput!"
      }
    ],
    "concreteType": "CompanyUnblockPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CompanyUnblockMutation_Mutation",
  "id": null,
  "text": "mutation CompanyUnblockMutation_Mutation(\n  $input: CompanyUnblockInput!\n) {\n  companyUnblock(input: $input) {\n    clientMutationId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CompanyUnblockMutation_Mutation",
    "type": "Mutations",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "CompanyUnblockMutation_Mutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
(node as any).hash = '5d81918cd9da22cdee1467e2b14b9b59';
export default node;

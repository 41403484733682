/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type CompanyInfoUpdateInput = {
    readonly companyId: string;
    readonly name: string;
    readonly vatNumber: string;
    readonly registrationNumber: string;
    readonly contactNumber: string;
    readonly emailAddress: string;
    readonly licenseType?: string | null;
    readonly licenseNumber?: string | null;
    readonly licenseExpiry?: any | null;
    readonly secondaryLicenseType?: string | null;
    readonly secondaryLicenseNumber?: string | null;
    readonly secondaryLicenseExpiry?: any | null;
    readonly tradeLimit: number;
    readonly sharepointIdentifier?: string | null;
    readonly clientMutationId?: string | null;
};
export type CompanyEditMutation_MutationVariables = {
    readonly input: CompanyInfoUpdateInput;
};
export type CompanyEditMutation_MutationResponse = {
    readonly companyInfoUpdate: ({
        readonly clientMutationId: string | null;
    }) | null;
};
export type CompanyEditMutation_Mutation = {
    readonly response: CompanyEditMutation_MutationResponse;
    readonly variables: CompanyEditMutation_MutationVariables;
};



/*
mutation CompanyEditMutation_Mutation(
  $input: CompanyInfoUpdateInput!
) {
  companyInfoUpdate(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CompanyInfoUpdateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "companyInfoUpdate",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "CompanyInfoUpdateInput!"
      }
    ],
    "concreteType": "CompanyInfoUpdatePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CompanyEditMutation_Mutation",
  "id": null,
  "text": "mutation CompanyEditMutation_Mutation(\n  $input: CompanyInfoUpdateInput!\n) {\n  companyInfoUpdate(input: $input) {\n    clientMutationId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CompanyEditMutation_Mutation",
    "type": "Mutations",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "CompanyEditMutation_Mutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
(node as any).hash = 'a7b4e3c907332790bdfb46c0456f351c';
export default node;

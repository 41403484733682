import {
  createPaginationContainer,
  graphql,
  RelayPaginationProp,
  requestSubscription,
} from 'react-relay';
import {Disposable} from 'relay-runtime';
import {RecordSourceSelectorProxy, ConnectionHandler} from 'relay-runtime';
import * as React from 'react';
import isEqual from 'react-fast-compare';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import {WithStyles, withStyles} from '@material-ui/core/styles';

import {
  createRelayRenderer,
  EnvironmentProp,
} from '../../components/RelayRenderer';
import Loading from '../../components/Loading';
import styles from '../../components/TableStyles';
import TransactionsTableRow from './TransactionsTradeRow';
import {TransactionsTable_viewer} from './__generated__/TransactionsTable_viewer.graphql';
import TradeReloadContext from '../contexts/TradeReloadContext';
import {Filters} from '../types';
import {TransactionsTableCreatedSubscriptionResponse} from './__generated__/TransactionsTableCreatedSubscription.graphql';
import {isInFilter} from './TradesSubscriptions';
import Error from '../../components/Error';

interface Props extends WithStyles<typeof styles>, EnvironmentProp {
  viewer: TransactionsTable_viewer;
  relay: RelayPaginationProp;
  openModal: (
    modal: null | 'reverseTransaction' | 'editNotes',
    modalprops?: {}
  ) => void;
  filters: Filters;
}

interface State {
  isLoading: boolean;
  error?: Error;
}

const TOTAL = 20;

class TransactionsTable extends React.Component<Props, State> {
  public static contextType = TradeReloadContext;
  private handlerRef: any;

  private transactionCreatedSubscription?: Disposable;
  private transactionUpdatedSubscription?: Disposable;

  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  private loadMore() {
    if (!this.props.relay.hasMore() || this.props.relay.isLoading()) {
      return;
    }

    this.setState({isLoading: true});
    this.props.relay.loadMore(TOTAL, (error) => {
      this.setState({error, isLoading: false});
    });
  }

  public componentDidMount() {
    const {relay} = this.props;
    this.handlerRef = this.context.addListener(() => this.refetchConnection());

    this.transactionCreatedSubscription = requestSubscription(
      relay.environment,
      {
        subscription: graphql`
          subscription TransactionsTableCreatedSubscription {
            weightTransactionCreated {
              id
              createdAt
              supplier {
                id
              }
              ...TransactionsTradeRow_weightTransaction
            }
          }
        `,
        variables: {},
        // @ts-ignore
        updater: (
          store: RecordSourceSelectorProxy,
          data: TransactionsTableCreatedSubscriptionResponse
        ) => {
          const {supplierIds} = this.props.filters;

          if (
            isInFilter(
              data.weightTransactionCreated.createdAt,
              data.weightTransactionCreated.supplier.id,
              this.props.filters
            )
          ) {
            const viewer = store.get('client:root:viewer');
            const transactions = ConnectionHandler.getConnection(
              viewer,
              'ViewerWeightTransactions_weightTransactions',
              {supplierIds}
            );

            const transaction = store.getRootField('weightTransactionCreated');

            if (transactions && transaction) {
              const edge = ConnectionHandler.createEdge(
                store,
                transactions,
                transaction,
                'ViewerWeightTransactionEdge'
              );
              ConnectionHandler.insertEdgeBefore(transactions, edge);
            }
          }
        },
      }
    );

    this.transactionUpdatedSubscription = requestSubscription(
      relay.environment,
      {
        subscription: graphql`
          subscription TransactionsTableUpdatedSubscription {
            weightTransactionUpdated {
              id
              createdAt
              supplier {
                id
              }
              ...TransactionsTradeRow_weightTransaction
            }
          }
        `,
        variables: {},
      }
    );
  }

  public componentWillUnmount() {
    if (this.handlerRef) {
      this.handlerRef();
    }

    if (this.transactionCreatedSubscription) {
      this.transactionCreatedSubscription.dispose();
    }

    if (this.transactionUpdatedSubscription) {
      this.transactionUpdatedSubscription.dispose();
    }
  }

  public componentWillReceiveProps(nextProps: Props) {
    if (!isEqual(nextProps.filters, this.props.filters)) {
      this.setState({isLoading: true, error: undefined});
      this.props.relay.refetchConnection(
        TOTAL,
        (error) => {
          this.setState({error, isLoading: false});
        },
        nextProps.filters
      );
    }
  }

  private refetchConnection() {
    this.setState({isLoading: true, error: undefined});
    this.props.relay.refetchConnection(
      TOTAL,
      (error) => {
        this.setState({error, isLoading: false});
      },
      this.props.filters
    );
  }

  public render() {
    const {classes, viewer, relay, openModal} = this.props;
    const {isLoading, error} = this.state;

    return (
      <>
        {error && <Error error={error} />}
        <Table className={classes.table}>
          <TableHead className={classes.header}>
            <TableRow className={classes.headerRow}>
              <TableCell className={classes.headerCell}>Created</TableCell>
              <TableCell className={classes.headerCell}>Notes</TableCell>
              <TableCell className={classes.headerCell}>
                Confirmed / Transaction Added
              </TableCell>
              <TableCell className={classes.headerCell}>Supplier</TableCell>
              <TableCell className={classes.headerCell}>Trader/User</TableCell>
              <TableCell className={classes.headerCell}>
                Requested Spot Price (ZAR/g)
              </TableCell>
              <TableCell className={classes.headerCell}>
                Metal Price (USD/oz)
              </TableCell>
              <TableCell className={classes.headerCell}>
                Exchange Rate (ZAR/USD)
              </TableCell>
              <TableCell className={classes.headerCell}>
                Confirmed Spot Price (ZAR/g)
              </TableCell>
              <TableCell className={classes.headerCell}>
                Ref Nr/PC Code
              </TableCell>
              <TableCell className={classes.headerCell}>Debit (g)</TableCell>
              <TableCell className={classes.headerCell}>Credit (g)</TableCell>
              <TableCell className={classes.headerCell}>Gold (%)</TableCell>
              <TableCell className={classes.headerCell}>
                Running Balance (g)
              </TableCell>
              <TableCell className={classes.headerCell}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {viewer.weightTransactions &&
              viewer.weightTransactions.edges.map(({node}) => {
                return (
                  <>
                    <TransactionsTableRow
                      weightTransaction={node as any}
                      key={node.id}
                      openModal={openModal}
                      environment={this.props.environment}
                    />
                  </>
                );
              })}
            {viewer.weightTransactions &&
              viewer.weightTransactions.edges.length === 0 && (
                <TableRow>
                  <TableCell colSpan={13}>No results found.</TableCell>
                </TableRow>
              )}
          </TableBody>
          {relay.hasMore() && (
            <Button onClick={() => this.loadMore()} className={classes.button}>
              Load More
            </Button>
          )}
          {relay.isLoading() && <Loading />}
        </Table>
      </>
    );
  }
}

const query = graphql`
  query TransactionsTableQuery(
    $count: Int!
    $cursor: String
    $supplierIds: [ID!]
    $traderIds: [ID!]
    $startDate: DateTime
    $endDate: DateTime
  ) {
    viewer {
      ...TransactionsTable_viewer
    }
  }
`;

const TransactionsTablePagination = createPaginationContainer(
  TransactionsTable,
  {
    viewer: graphql`
      fragment TransactionsTable_viewer on Viewer {
        weightTransactions(
          first: $count
          after: $cursor
          supplierIds: $supplierIds
          traderIds: $traderIds
          from: $startDate
          to: $endDate
        )
          @connection(
            key: "ViewerWeightTransactions_weightTransactions"
            filters: ["supplierIds"]
          ) {
          total
          edges {
            node {
              id
              ...TransactionsTradeRow_weightTransaction
            }
          }
        }
      }
    `,
  },
  {
    getVariables(_props, {count, cursor}, fragmentVariables) {
      return {
        ...fragmentVariables,
        count,
        cursor,
      };
    },
    query,
  }
);

export default withStyles(styles)(
  createRelayRenderer({
    container: TransactionsTablePagination,
    query,
    variables: {
      count: TOTAL,
    },
  })
);

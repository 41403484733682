import * as React from 'react';
import {graphql, requestSubscription} from 'react-relay';
import {
  Environment,
  Disposable,
  ConnectionHandler,
  RecordSourceSelectorProxy,
} from 'relay-runtime';
import moment from 'moment';

import {TradesSubscriptionsTransitionedSubscriptionResponse} from './__generated__/TradesSubscriptionsTransitionedSubscription.graphql';
import {Filters} from '../types';

interface Props {
  environment: Environment;
  filters: Filters;
}

export function isInFilter(
  timestamp: string,
  companyId: string,
  {startDate, endDate, supplierIds}: Filters
): boolean {
  return (
    (!startDate || moment(startDate).isSameOrBefore(timestamp)) &&
    (!endDate || moment(endDate).isSameOrAfter(timestamp)) &&
    (!supplierIds ||
      supplierIds.length === 0 ||
      supplierIds.includes(companyId))
  );
}

export default class TradesSubscriptions extends React.Component<Props> {
  private tradeTransitionSubscription?: Disposable;
  private tradeUpdatedSubscription?: Disposable;

  public componentDidMount() {
    const {environment} = this.props;

    this.tradeUpdatedSubscription = requestSubscription(environment, {
      subscription: graphql`
        subscription TradesSubscriptionsUpdatedSubscription {
          tradeUpdated {
            id
            ...PendingTradeRow_trade
            ...DeclinedTradeRow_trade
          }
        }
      `,
      variables: {},
    });

    this.tradeTransitionSubscription = requestSubscription(environment, {
      subscription: graphql`
        subscription TradesSubscriptionsTransitionedSubscription {
          tradeTransitioned {
            trade {
              id
              tradeTimestamp
              status
              company {
                id
              }
              ...PendingTradeRow_trade
              ...DeclinedTradeRow_trade
            }
            previousStatus
          }
        }
      `,
      variables: {},
      // @ts-ignore
      updater: (
        store: RecordSourceSelectorProxy,
        data: TradesSubscriptionsTransitionedSubscriptionResponse
      ) => {
        const {supplierIds} = this.props.filters;

        if (!data.tradeTransitioned) {
          return;
        }

        if (
          isInFilter(
            data.tradeTransitioned.trade.tradeTimestamp,
            data.tradeTransitioned.trade.company.id,
            this.props.filters
          )
        ) {
          const viewer = store.get('client:root:viewer');
          const pendingTrades = ConnectionHandler.getConnection(
            viewer,
            'ViewerPending_trades',
            {supplierIds}
          );

          const declinedTrades = ConnectionHandler.getConnection(
            viewer,
            'ViewerDeclined_trades',
            {supplierIds}
          );

          if (data.tradeTransitioned) {
            const {previousStatus, trade} = data.tradeTransitioned;

            if (previousStatus === null) {
              // Admin Created Trade
            } else if (
              previousStatus === 'pending' &&
              trade.status === 'confirmed'
            ) {
              // Remove from pending
              if (pendingTrades) {
                ConnectionHandler.deleteNode(
                  pendingTrades,
                  data.tradeTransitioned.trade.id
                );
              }
            } else if (
              previousStatus === 'pending' &&
              trade.status === 'rejected'
            ) {
              const tradeTransitioned = store.getRootField('tradeTransitioned');

              // Add to declined screen
              if (declinedTrades && tradeTransitioned) {
                const edge = ConnectionHandler.createEdge(
                  store,
                  declinedTrades,
                  tradeTransitioned.getLinkedRecord('trade'),
                  'ViewerTradesEdge'
                );
                ConnectionHandler.insertEdgeBefore(declinedTrades, edge);
              }
            } else {
              // tslint:disable-next-line no-console
              console.log(
                `Ignoring Trade Transition ${previousStatus} -> ${
                  trade.status
                }.`
              );
            }
          }
        }
      },
    });
  }

  public componentWillUnmount() {
    if (this.tradeTransitionSubscription) {
      this.tradeTransitionSubscription.dispose();
    }

    if (this.tradeUpdatedSubscription) {
      this.tradeUpdatedSubscription.dispose();
    }
  }

  public render() {
    return null;
  }
}

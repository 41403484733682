import {graphql} from 'react-relay';

import {createMutation} from '@stackworx/relay';

import {
  TradeDownloadMutation_MutationResponse,
  TradeDownloadMutation_MutationVariables,
} from './__generated__/TradeDownloadMutation_Mutation.graphql';

const mutation = graphql`
  mutation TradeDownloadMutation_Mutation($input: TradeDownloadInput!) {
    tradeDownload(input: $input) {
      clientMutationId
      output
      filename
    }
  }
`;

export default createMutation<
  TradeDownloadMutation_MutationVariables,
  TradeDownloadMutation_MutationResponse
>(mutation);

import {withStyles, WithStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Formik} from 'formik';
import moment from 'moment';
import React from 'react';
import {EnvironmentProp} from '../../RelayRenderer';
import styles from './ModalStyles';
import CompanyEditMutation from './mutations/CompanyEditMutation';
import SupplierFields, {schema} from './SupplierFields';

interface Props extends WithStyles<typeof styles>, EnvironmentProp {
  onClose: () => void;
  supplierId?: string;
  supplierName?: string;
  refetch?: () => null;
  data?: {
    name: string;
    regNumber: string;
    vatNumber: string;
    email: string;
    contact: string;
    primaryLicenseType?: string;
    primaryLicenseNumber?: string;
    primaryLicenseExpires?: string;
    otherLicenseType?: string;
    otherLicenseNumber?: string;
    otherLicenseExpires?: string;
    tradeLimit: number;
    sharepointIdentifier: string;
  };
}

const Clicked = (e: React.MouseEvent<HTMLElement>) => {
  e.stopPropagation();
};

function EditSupplierModal(props: Props) {
  const {classes, data, supplierId} = props;
  return (
    <div
      className={classes.root}
      onClick={(e: React.MouseEvent<HTMLElement>) => Clicked(e)}
    >
      <Typography variant="h6" className={classes.title}>
        Edit Supplier
        <span
          className={classes.exit}
          onClick={() => props.onClose && props.onClose()}
        >
          +
        </span>
      </Typography>
      <Formik
        onSubmit={async (values, {setSubmitting, setStatus}) => {
          setSubmitting(true);

          try {
            if (supplierId === undefined) {
              throw {message: 'SupplierId is missing'};
            }

            await CompanyEditMutation(props.environment, {
              input: {
                companyId: supplierId,
                name: values.company,
                registrationNumber: values.regNumber,
                vatNumber: values.vatNumber,
                emailAddress: values.email,
                contactNumber: values.contact,
                licenseType:
                  values.primaryLicenseType === 'None'
                    ? undefined
                    : values.primaryLicenseType,
                licenseNumber:
                  values.primaryLicenseType === 'None'
                    ? undefined
                    : values.primaryLicenseNumber,
                licenseExpiry:
                  // safety to ensure no confusion around day
                  values.primaryLicenseType === 'None'
                    ? undefined
                    : moment(values.primaryLicenseExpires)
                        .add(7, 'hours')
                        .toISOString(),
                secondaryLicenseType:
                  values.otherLicenseType === 'None'
                    ? undefined
                    : values.otherLicenseType,
                secondaryLicenseNumber:
                  values.otherLicenseType === 'None'
                    ? undefined
                    : values.otherLicenseNumber,
                secondaryLicenseExpiry:
                  values.otherLicenseType === 'None'
                    ? undefined
                    : values.otherLicenseType && values.otherLicenseType !== ''
                    ? moment(values.otherLicenseExpires)
                        .add(7, 'hours')
                        .toISOString()
                    : null,
                tradeLimit: values.tradeLimit,
                sharepointIdentifier: values.sharepointIdentifier,
              },
            });

            if (props.refetch) {
              props.refetch();
            }
            setSubmitting(false);

            if (props.onClose) {
              props.onClose();
            }
          } catch (ex) {
            setStatus(ex.message);
            setSubmitting(false);
            if (ex.message === 'Email Address Already Exists') {
              setStatus(
                `A company with the contact email, ${
                  values.email
                } Already exists.`
              );
            }
          }
        }}
        validationSchema={schema}
        initialValues={{
          company: data!.name || '',
          regNumber: data!.regNumber || '',
          vatNumber: data!.vatNumber || '',
          email: data!.email || '',
          contact: data!.contact || '',
          primaryLicenseType: data!.primaryLicenseType
            ? data!.primaryLicenseType
            : 'None',
          primaryLicenseNumber: data!.primaryLicenseNumber || '',
          primaryLicenseExpires: data!.primaryLicenseExpires
            ? moment(data!.primaryLicenseExpires).format('YYYY-MM-DD')
            : 'yyyy/mm/dd',
          otherLicenseType: data!.otherLicenseType || 'None',
          otherLicenseNumber: data!.otherLicenseNumber || '',
          otherLicenseExpires: data!.otherLicenseExpires
            ? moment(data!.otherLicenseExpires).format('YYYY-MM-DD')
            : 'yyyy/mm/dd',
          tradeLimit: data!.tradeLimit || 0,
          sharepointIdentifier: data!.sharepointIdentifier || '',
        }}
        render={(formikProps) => (
          <SupplierFields buttonTitle="Edit Supplier" {...formikProps} />
        )}
      />
    </div>
  );
}

export default withStyles(styles)(EditSupplierModal);

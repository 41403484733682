/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type WeightTransactionAdminCreateInput = {
    readonly companyId: string;
    readonly timestamp?: any | null;
    readonly fineContent: number;
    readonly goldPercentage: number;
    readonly pcCode: string;
    readonly debitOrCredit: string;
    readonly notes?: string | null;
    readonly clientMutationId?: string | null;
};
export type WeightTransactionAdminCreateMutationVariables = {
    readonly input: WeightTransactionAdminCreateInput;
};
export type WeightTransactionAdminCreateMutationResponse = {
    readonly weightTransactionAdminCreate: ({
        readonly clientMutationId: string | null;
    }) | null;
};
export type WeightTransactionAdminCreateMutation = {
    readonly response: WeightTransactionAdminCreateMutationResponse;
    readonly variables: WeightTransactionAdminCreateMutationVariables;
};



/*
mutation WeightTransactionAdminCreateMutation(
  $input: WeightTransactionAdminCreateInput!
) {
  weightTransactionAdminCreate(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "WeightTransactionAdminCreateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "weightTransactionAdminCreate",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "WeightTransactionAdminCreateInput!"
      }
    ],
    "concreteType": "WeightTransactionAdminCreatePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "WeightTransactionAdminCreateMutation",
  "id": null,
  "text": "mutation WeightTransactionAdminCreateMutation(\n  $input: WeightTransactionAdminCreateInput!\n) {\n  weightTransactionAdminCreate(input: $input) {\n    clientMutationId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "WeightTransactionAdminCreateMutation",
    "type": "Mutations",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "WeightTransactionAdminCreateMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
(node as any).hash = '0b00d1e557d63da32d141ed95d66a3f0';
export default node;

import {createMuiTheme} from '@material-ui/core';

import createPalette from '@material-ui/core/styles/createPalette';

const theme = createMuiTheme({
  palette: createPalette({
    primary: {
      // backgrounds
      main: '#111111',
      light: '#F2F2F2',
      dark: '#8D8D8D',
      contrastText: '#BA7234',
    },
    secondary: {
      // fonts
      main: '#5c5c5c',
      light: '#fff',
      dark: '#FAFAFA',
      contrastText: '#995113',
    },
  }),
  typography: {
    useNextVariants: true,
  },
});

export default theme;

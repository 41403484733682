/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type UserBlockInput = {
    readonly effectiveDateTime?: any | null;
    readonly userId: string;
    readonly clientMutationId?: string | null;
};
export type UserBlockMutation_MutationVariables = {
    readonly input: UserBlockInput;
};
export type UserBlockMutation_MutationResponse = {
    readonly userBlock: ({
        readonly clientMutationId: string | null;
    }) | null;
};
export type UserBlockMutation_Mutation = {
    readonly response: UserBlockMutation_MutationResponse;
    readonly variables: UserBlockMutation_MutationVariables;
};



/*
mutation UserBlockMutation_Mutation(
  $input: UserBlockInput!
) {
  userBlock(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UserBlockInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "userBlock",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UserBlockInput!"
      }
    ],
    "concreteType": "UserBlockPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UserBlockMutation_Mutation",
  "id": null,
  "text": "mutation UserBlockMutation_Mutation(\n  $input: UserBlockInput!\n) {\n  userBlock(input: $input) {\n    clientMutationId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UserBlockMutation_Mutation",
    "type": "Mutations",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "UserBlockMutation_Mutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
(node as any).hash = '290b36bde4c4a6f685c0aa1808845f20';
export default node;

import {graphql} from 'react-relay';

import {createMutation} from '@stackworx/relay';

import {
  UserCreateMutation_MutationResponse,
  UserCreateMutation_MutationVariables,
} from './__generated__/UserCreateMutation_Mutation.graphql';

const mutation = graphql`
  mutation UserCreateMutation_Mutation($input: UserCreateInput!) {
    userCreate(input: $input) {
      clientMutationId
    }
  }
`;

export default createMutation<
  UserCreateMutation_MutationVariables,
  UserCreateMutation_MutationResponse
>(mutation);

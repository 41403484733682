import {graphql} from 'react-relay';

import {createMutation} from '@stackworx/relay';

import {
  UserProfileUpdateMutation_MutationResponse,
  UserProfileUpdateMutation_MutationVariables,
} from './__generated__/UserProfileUpdateMutation_Mutation.graphql';

const mutation = graphql`
  mutation UserProfileUpdateMutation_Mutation($input: UserProfileUpdateInput!) {
    userProfileUpdate(input: $input) {
      clientMutationId
    }
  }
`;

export default createMutation<
  UserProfileUpdateMutation_MutationVariables,
  UserProfileUpdateMutation_MutationResponse
>(mutation);

/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
type TransactionsTable_viewer$ref = any;
export type TransactionsTableQueryVariables = {
    readonly count: number;
    readonly cursor?: string | null;
    readonly supplierIds?: ReadonlyArray<string> | null;
    readonly traderIds?: ReadonlyArray<string> | null;
    readonly startDate?: any | null;
    readonly endDate?: any | null;
};
export type TransactionsTableQueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": TransactionsTable_viewer$ref;
    };
};
export type TransactionsTableQuery = {
    readonly response: TransactionsTableQueryResponse;
    readonly variables: TransactionsTableQueryVariables;
};



/*
query TransactionsTableQuery(
  $count: Int!
  $cursor: String
  $supplierIds: [ID!]
  $traderIds: [ID!]
  $startDate: DateTime
  $endDate: DateTime
) {
  viewer {
    ...TransactionsTable_viewer
  }
}

fragment TransactionsTable_viewer on Viewer {
  weightTransactions(first: $count, after: $cursor, supplierIds: $supplierIds, traderIds: $traderIds, from: $startDate, to: $endDate) {
    total
    edges {
      node {
        id
        ...TransactionsTradeRow_weightTransaction
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment TransactionsTradeRow_weightTransaction on WeightTransaction {
  id
  weight
  goldPercentage
  type
  rollingBalance
  notes
  pcCode
  reversed
  supplier {
    id
    info {
      name
    }
  }
  createdAt
  createdBy {
    id
    displayName
  }
  transactionTimestamp
  trade {
    id
    refNumber
    tradeTimestamp
    status
    weight
    requestedZarPerGram
    notes
    createdBy {
      id
      displayName
    }
    confirmation {
      eventTimestamp
      usdPerTroyOunce
      zarToUsd
      zarPerGram
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "supplierIds",
    "type": "[ID!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "traderIds",
    "type": "[ID!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "startDate",
    "type": "DateTime",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "endDate",
    "type": "DateTime",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "from",
    "variableName": "startDate",
    "type": "DateTime"
  },
  {
    "kind": "Variable",
    "name": "supplierIds",
    "variableName": "supplierIds",
    "type": "[ID!]"
  },
  {
    "kind": "Variable",
    "name": "to",
    "variableName": "endDate",
    "type": "DateTime"
  },
  {
    "kind": "Variable",
    "name": "traderIds",
    "variableName": "traderIds",
    "type": "[ID!]"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "weight",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "notes",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "createdBy",
  "storageKey": null,
  "args": null,
  "concreteType": "AuditUser",
  "plural": false,
  "selections": [
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "displayName",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "TransactionsTableQuery",
  "id": null,
  "text": "query TransactionsTableQuery(\n  $count: Int!\n  $cursor: String\n  $supplierIds: [ID!]\n  $traderIds: [ID!]\n  $startDate: DateTime\n  $endDate: DateTime\n) {\n  viewer {\n    ...TransactionsTable_viewer\n  }\n}\n\nfragment TransactionsTable_viewer on Viewer {\n  weightTransactions(first: $count, after: $cursor, supplierIds: $supplierIds, traderIds: $traderIds, from: $startDate, to: $endDate) {\n    total\n    edges {\n      node {\n        id\n        ...TransactionsTradeRow_weightTransaction\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment TransactionsTradeRow_weightTransaction on WeightTransaction {\n  id\n  weight\n  goldPercentage\n  type\n  rollingBalance\n  notes\n  pcCode\n  reversed\n  supplier {\n    id\n    info {\n      name\n    }\n  }\n  createdAt\n  createdBy {\n    id\n    displayName\n  }\n  transactionTimestamp\n  trade {\n    id\n    refNumber\n    tradeTimestamp\n    status\n    weight\n    requestedZarPerGram\n    notes\n    createdBy {\n      id\n      displayName\n    }\n    confirmation {\n      eventTimestamp\n      usdPerTroyOunce\n      zarToUsd\n      zarPerGram\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TransactionsTableQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "viewer",
        "name": "__viewer_viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "TransactionsTable_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TransactionsTableQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "weightTransactions",
            "storageKey": null,
            "args": v1,
            "concreteType": "ViewerWeightTransactionConnection",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "total",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ViewerWeightTransactionEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "WeightTransaction",
                    "plural": false,
                    "selections": [
                      v2,
                      v3,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "goldPercentage",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "type",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "rollingBalance",
                        "args": null,
                        "storageKey": null
                      },
                      v4,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "pcCode",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "reversed",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "supplier",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Company",
                        "plural": false,
                        "selections": [
                          v2,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "info",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CompanyInfo",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "name",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      v5,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "transactionTimestamp",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "trade",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Trade",
                        "plural": false,
                        "selections": [
                          v2,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "refNumber",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "tradeTimestamp",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "status",
                            "args": null,
                            "storageKey": null
                          },
                          v3,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "requestedZarPerGram",
                            "args": null,
                            "storageKey": null
                          },
                          v4,
                          v5,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "confirmation",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "LatestTradeConfirmation",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "eventTimestamp",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "usdPerTroyOunce",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "zarToUsd",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "zarPerGram",
                                "args": null,
                                "storageKey": null
                              },
                              v2
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "__typename",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "weightTransactions",
            "args": v1,
            "handle": "connection",
            "key": "ViewerWeightTransactions_weightTransactions",
            "filters": [
              "supplierIds"
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "viewer",
        "args": null,
        "handle": "viewer",
        "key": "",
        "filters": null
      }
    ]
  }
};
})();
(node as any).hash = '105669dddea9966d7a31d27d2194e10a';
export default node;

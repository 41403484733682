/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type TraderFilterQueryVariables = {};
export type TraderFilterQueryResponse = {
    readonly viewer: {
        readonly companies: ({
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly info: {
                        readonly name: string;
                    };
                    readonly users: ({
                        readonly edges: ReadonlyArray<{
                            readonly node: {
                                readonly id: string;
                                readonly username: string;
                            };
                        }>;
                    }) | null;
                };
            }>;
        }) | null;
    };
};
export type TraderFilterQuery = {
    readonly response: TraderFilterQueryResponse;
    readonly variables: TraderFilterQueryVariables;
};



/*
query TraderFilterQuery {
  viewer {
    companies {
      edges {
        node {
          id
          info {
            name
          }
          users {
            edges {
              node {
                id
                username
              }
            }
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "companies",
    "storageKey": null,
    "args": null,
    "concreteType": "ViewerCompaniesConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "ViewerCompaniesEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Company",
            "plural": false,
            "selections": [
              v0,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "info",
                "storageKey": null,
                "args": null,
                "concreteType": "CompanyInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "users",
                "storageKey": null,
                "args": null,
                "concreteType": "CompanyUsersConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CompanyUsersEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "User",
                        "plural": false,
                        "selections": [
                          v0,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "username",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "TraderFilterQuery",
  "id": null,
  "text": "query TraderFilterQuery {\n  viewer {\n    companies {\n      edges {\n        node {\n          id\n          info {\n            name\n          }\n          users {\n            edges {\n              node {\n                id\n                username\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TraderFilterQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "viewer",
        "name": "__viewer_viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": v1
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TraderFilterQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": v1
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "viewer",
        "args": null,
        "handle": "viewer",
        "key": "",
        "filters": null
      }
    ]
  }
};
})();
(node as any).hash = '424deb0d2a11c6be1830fe5c948b78f7';
export default node;

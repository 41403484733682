import React from 'react';
import {WithStyles, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import * as Yup from 'yup';
import Spinner from 'react-spinkit';

import {Formik, Form, Field} from 'formik';
import Typography from '@material-ui/core/Typography';

import TextArea from './TextArea';
import styles from './ModalStyles';
import {EnvironmentProp} from '../../RelayRenderer';
import WeightTransactionEditNotesMutation from './mutations/WeightTransactionEditNotesMutation';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import NumberCounter from './NumberCounter';

interface Props extends WithStyles<typeof styles>, EnvironmentProp {
  onClose?: () => void;
  transactionOrTradeId: string;
  notes: string;
}

const schema = Yup.object().shape({
  notes: Yup.string().max(200, 'Message is too long'),
});

const Clicked = (e: React.MouseEvent<HTMLElement>) => {
  e.stopPropagation();
};

interface Values {
  notes?: string;
}

export function EditNoteModal({
  notes,
  classes,
  transactionOrTradeId,
  onClose,
  environment,
}: Props) {
  return (
    <div
      className={classes.root}
      onClick={(e: React.MouseEvent<HTMLElement>) => Clicked(e)}
    >
      <Typography variant="h6" className={classes.title}>
        Edit notes
        <span className={classes.exit} onClick={() => onClose && onClose()}>
          +
        </span>
      </Typography>
      <Formik<Values>
        onSubmit={async (values, {setSubmitting, setStatus}) => {
          setSubmitting(true);
          try {
            await WeightTransactionEditNotesMutation(environment, {
              input: {
                id: transactionOrTradeId,
                notes: values.notes,
              },
            });

            if (onClose) {
              onClose();
            }
          } catch (ex) {
            setStatus(ex.message);
            setSubmitting(false);
          }
        }}
        validationSchema={schema}
        initialValues={{
          notes,
        }}
        render={({handleSubmit, isSubmitting, status, values}) => (
          <Form>
            <Field
              name="notes"
              label="Notes"
              type="text"
              component={TextArea}
            />
            <NumberCounter
              current={values.notes ? values.notes.length : 0}
              max={200}
            />
            <br />
            <Button
              variant="contained"
              color="primary"
              style={{width: '100%', margin: '10px 0 10px'}}
              className={classes.button}
              onClick={() => handleSubmit()}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Spinner
                  name="circle"
                  color="white"
                  // @ts-ignore
                  style={{margin: '0 auto'}}
                />
              ) : (
                'Save'
              )}
              <FontAwesomeIcon
                icon={faChevronRight}
                style={{fontSize: '0.875rem', paddingLeft: '10px'}}
              />
            </Button>
            {status && (
              <Typography className={classes.error}>{status}</Typography>
            )}
          </Form>
        )}
      />
    </div>
  );
}

export default withStyles(styles)(EditNoteModal);

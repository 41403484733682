/* tslint:disable */

import { ConcreteFragment } from "relay-runtime";
export type NewTransactionModal_viewer$ref = any;
export type NewTransactionModal_viewer = {
    readonly currentTradePrice: ({
        readonly zarPerTroyOunce: number;
        readonly usdPerTroyOunce: number;
        readonly zarToUsd: number;
    }) | null;
    readonly companies: ({
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly info: {
                    readonly name: string;
                };
            };
        }>;
    }) | null;
    readonly " $refType": NewTransactionModal_viewer$ref;
};



const node: ConcreteFragment = {
  "kind": "Fragment",
  "name": "NewTransactionModal_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "currentTradePrice",
      "storageKey": null,
      "args": null,
      "concreteType": "TradePrice",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "zarPerTroyOunce",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "usdPerTroyOunce",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "zarToUsd",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "companies",
      "storageKey": null,
      "args": null,
      "concreteType": "ViewerCompaniesConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ViewerCompaniesEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Company",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "info",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CompanyInfo",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "name",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
(node as any).hash = 'ded853efb6637ea03bbdff53c5e42c9d';
export default node;

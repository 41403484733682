import React from 'react';
import {withStyles, WithStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {Formik, Form, Field} from 'formik';
import Typography from '@material-ui/core/Typography';
import styles from './ModalStyles';
import * as Yup from 'yup';
import Spinner from 'react-spinkit';
import {TextField} from 'formik-material-ui';
import {EnvironmentProp} from '../../RelayRenderer';
import WeightTransactionReversalMutation from './mutations/WeightTransactionReversalMutation';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import moment from 'moment';
import classNames from 'classnames';

interface Props extends WithStyles<typeof styles>, EnvironmentProp {
  transactionId?: string;
  transactionTimestamp: string;
  onClose: () => void;
  refetch?: () => void;
}

const Clicked = (e: React.MouseEvent<HTMLElement>) => {
  e.stopPropagation();
};

interface Values {
  reverseTransaction: {
    reason: string;
    date: string;
    time: string;
    transactionId: string;
  };
}

const schema = Yup.object().shape({
  reverseTransaction: Yup.object().shape({
    transactionId: Yup.string().required('Required'),
    date: Yup.string().required('Required'),
    reason: Yup.string().max(200, 'Value is too long'),
  }),
});

function ReverseTransactionModal(props: Props) {
  const {classes} = props;
  return (
    <div
      className={classes.root}
      onClick={(e: React.MouseEvent<HTMLElement>) => Clicked(e)}
    >
      <Typography variant="h6" className={classes.title}>
        Reverse Transaction
        <span
          className={classes.exit}
          onClick={() => props.onClose && props.onClose()}
        >
          +
        </span>
      </Typography>
      <Formik<Values>
        onSubmit={async (values, {setSubmitting, setStatus}) => {
          setSubmitting(true);

          if (!props.transactionId) {
            throw {message: 'Trader must be selected, got'};
          }

          try {
            const timestamp = moment(
              `${values.reverseTransaction.date} ${
                values.reverseTransaction.time
              }`
            );

            if (timestamp.isAfter(props.transactionTimestamp)) {
              await WeightTransactionReversalMutation(props.environment, {
                input: {
                  transactionId: values.reverseTransaction.transactionId,
                  timestamp: moment(
                    `${values.reverseTransaction.date} ${
                      values.reverseTransaction.time
                    }`
                  ),
                  reason: values.reverseTransaction.reason,
                },
              });
              if (props.refetch) {
                props.refetch();
              }
              setSubmitting(false);

              if (props.onClose) {
                props.onClose();
              }
            } else {
              setStatus(
                'Provided date and time may not precede original transaction creation date.'
              );
              setSubmitting(false);
            }
          } catch (ex) {
            setStatus(ex.message || 'Submit Failed');
            setSubmitting(false);
          }
        }}
        validationSchema={schema}
        initialValues={{
          reverseTransaction: {
            reason: '',
            date: moment().format('YYYY-MM-DD'),
            time: moment().format('HH:mm:ss'),
            transactionId: props.transactionId!,
          },
        }}
        render={({handleSubmit, status, isSubmitting}) => (
          <Form>
            <Field
              name="reverseTransaction.date"
              label="Date"
              type="date"
              component={TextField}
              className={classNames(classes.textHalf, classes.first)}
              InputLabelProps={{shrink: true}}
            />
            <Field
              name="reverseTransaction.time"
              label="Time"
              type="time"
              component={TextField}
              className={classNames(classes.textHalf)}
              InputLabelProps={{shrink: true}}
            />
            <Field
              InputLabelProps={{shrink: true}}
              name="reverseTransaction.reason"
              label="Reason"
              type="text"
              component={TextField}
              multiline
              rows={6}
              style={{width: '100%'}}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{width: '100%', margin: '60px 0 10px'}}
              className={classes.button}
              onClick={() => handleSubmit()}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Spinner
                  name="circle"
                  color="white"
                  // @ts-ignore
                  style={{margin: '0 auto'}}
                />
              ) : (
                'Reverse Transaction'
              )}
              <FontAwesomeIcon
                icon={faChevronRight}
                style={{fontSize: '0.875rem', paddingLeft: '10px'}}
              />
            </Button>
            {status && (
              <Typography className={classes.error}>{status}</Typography>
            )}
          </Form>
        )}
      />
    </div>
  );
}

export default withStyles(styles)(ReverseTransactionModal);

import {graphql} from 'react-relay';

import {createMutation} from '@stackworx/relay';

import {
  TradeAdminConfirmedUpdateMutationVariables,
  TradeAdminConfirmedUpdateMutationResponse,
} from './__generated__/TradeAdminConfirmedUpdateMutation.graphql';

const mutation = graphql`
  mutation TradeAdminConfirmedUpdateMutation(
    $input: TradeAdminConfirmedUpdateInput!
  ) {
    tradeAdminConfirmedUpdate(input: $input) {
      clientMutationId
      trade {
        id
        refNumber
        tradeTimestamp
        status
        weight
        notes
        requestedZarPerGram
        createdBy {
          id
          displayName
        }
        confirmation {
          eventTimestamp
          usdPerTroyOunce
          zarToUsd
          zarPerGram
        }
      }
    }
  }
`;

export default createMutation<
  TradeAdminConfirmedUpdateMutationVariables,
  TradeAdminConfirmedUpdateMutationResponse
>(mutation);
